import React from "react";
import {
  makeStyles,
  Paper,
  Grid,
  GridList,
  GridListTile,
  AppBar,
  Button,
  useTheme,
  useMediaQuery,
  
} from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
 import first from "./images/glimpseAtAGC/Image1.jpeg" ;
 import second from "./images/glimpseAtAGC/Image2.jpeg";
 import third from "./images/glimpseAtAGC/Image3.jpeg" ;
 import fourth from "./images/glimpseAtAGC/Image4.jpeg";
import mobileBG from "./images/mobileBG.svg";
import agcLogo from "./images/AGCLogo.svg";
import sipAbacusLogo from "./images/SIPAcademyLogo.svg";
import plusLeft from "./images/plusSignLeft.svg";
import plusRight from "./images/plusSignRight.svg";




const useStyle = makeStyles((theme) => ({
  root: {
    alignContent:"stretch",
    wrap:"nowrap",
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden'
  },
  gridList: {
    width: "100%",
    height:'75vh',
    overflow:"hidden"
  },
 
  icon:{
    color:"white",
    display :"inline-block",
    marginTop:theme.spacing(11)
 },
 mobileGlimpsePaper:{
  width: "100%",
  maxHeight:'162vh',
  backgroundImage: `url(${mobileBG})`,
  backgroundRepeat:"no-repeat",
  backgroundSize: "cover"
 },
 registrationPageLink: {
  textDecoration: "none",
},
button: {
  color: "white",
  backgroundColor: "#E62127",
  fontWeight: "bold",
  borderRadius: theme.spacing(1),
  display: "inline-block",
  textAlign: "center",
},
title: {
  textAlign: "center",
  color: "white",
  backgroundColor: "#E62127",
  fontWeight: "bold",
  borderRadius: "15px",
  whiteSpace: "pre-line",
  display:"inline-block",
  fontSize:theme.spacing(3),
  padding:theme.spacing(3)
},
 
}));
const GlimpseAtAGCMobile = () => {
  const classes = useStyle();
  const register ="REGISTER NOW";
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const title="A GLIMPSE AT AGC 2020"

  const images = [
       {
         img: first,
         title: 'Image1',
         author: 'author',
         cols: 8,
         featured: true,
       },
       {
        img: second,
         title: 'Image2',
         author: 'author',
         cols: 8,
         featured: true,
       },
       {
        img: third,
        title: 'Image3',
        author: 'author',
        cols: 8,
        featured: true,
      },
      {
       img: fourth,
        title: 'Image4',
        author: 'author',
        cols: 8,
        featured: true,
      }
          
    ];


let [displayedImages,setDisplayedImages]= React.useState(images.slice(0,1));

function getIndexOfElement(searchElement){
      let positionOfElement=0;
      images.map((value, index) => {
        if (searchElement === value.title) {
          positionOfElement = index;
        }
      });
      return (positionOfElement);
    }

    
    function handleClick(forwardClick) {  
      let index;
       let arraySize = displayedImages.length - 1;
   
       if (forwardClick) {
         index = getIndexOfElement(displayedImages[arraySize].title);
         if (index +1 >= images.length) {
           setDisplayedImages(images.slice(2,3));
         }else{
           index++;
           setDisplayedImages(images.slice(index,index+1)) 
         }
       } else {
         index = getIndexOfElement(displayedImages[0].title);
         if(index-1 <=0){
           setDisplayedImages(images.slice(0,1))
         }else{
           setDisplayedImages(images.slice(index-1,index))
         }
       }
   
     }

    
  
    return (
    <div className={classes.root}>
      <Grid container  spacing={2}>
        <Paper className={classes.mobileGlimpsePaper} >

          <Grid item container md={12} xs={12} lg={12} sm={12} >
               <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
                <Grid item md={3} xs={3} lg={3} sm={3}></Grid>
                 <Grid item md={4} xs={9} sm={9} lg={4} >
                  <img src={sipAbacusLogo} alt="logo" height= {smallScreen ?"60%":"50%"}  width={smallScreen ?"60%":"50%"} />
                 </Grid>
                 <Grid item md={5} xs={0} sm={0} lg={5}></Grid>
               </AppBar>
               </Grid>

               <Grid item container>
               <Grid item md={1} xs={1} sm={1} lg={1}></Grid>
             <Grid item md={2} xs={4} sm={4} lg={2} className={classes.container} justifyContent="flex-start">
             <img
                   src={agcLogo}
                   alt="Logo"
                   style={{ height: smallScreen?"80%":"60%", width:smallScreen?"60": "80%" }}
                 />
             </Grid>
             <Grid item md={6} xs={1} sm={1} lg={6}></Grid>
             <Grid item md={3} xs={6} sm={6} lg={3}>

            <Grid item container  style={{marginTop: theme.spacing(3)}}>
            
              
              </Grid>
              </Grid>
              </Grid>

              <Grid item container md={12} xs={12} lg={12} sm={12}  >
                <Grid item md={3} xs={1} lg={3} sm={1}> </Grid>
                <Grid item md={6} xs={10} lg={6} sm={10} className={classes.title} style={{marginTop :smallScreen ? theme.spacing(1):0}}>{title}</Grid>
                <Grid item md={3} xs={1} lg={3} sm={1}></Grid>  
               </Grid>


             <br />
             <br />
          <Grid tem container style={{marginTop:theme.spacing(5)}}>
          <Grid item container sm={12} ms={12} xs={12}>
            <Grid item sm={3} xs={3}></Grid>
            <Grid item sm={6} xs={6}>
            <GridList cellHeight={20}  cols={6} className={classes.gridList} spacing={1}>
              {displayedImages.map((tile) => (
                <GridListTile key={tile.img} cols={6} style={{ height:"55%"}}>
                  <img src={tile.img} alt={tile.title}  style={{height:"55%" , width:"100%"}}/>
                </GridListTile>
              ))}
            </GridList>
            </Grid>
            <Grid item sm={3} xs={3}></Grid>
          </Grid>
         
          

         <Grid item container style={{marginTop:-theme.spacing(40)}}>
           <Grid item md={2} xs={2} sm={2} style={{textAlign:"center"}}>
           <ArrowBackIosIcon   className={classes.icon}  fontSize="large"  onClick={()=>handleClick(0)} />
           </Grid>
           <Grid item md={8} xs={8}  sm={8} >
            <GridList cellHeight={250}  cols={8} className={classes.gridList} spacing={1}>
              {displayedImages.map((tile) => (
                <GridListTile key={tile.img} cols={8} style={{ height:"75%"}}>
                  <img src={tile.img} alt={tile.title}  style={{height:"75%" , width:"100%"}}/>
                </GridListTile>
              ))}
            </GridList>
           </Grid>
           <Grid item md={2} xs={2} sm={2} style={{textAlign:"center"}}>
           <ArrowForwardIosIcon   className={classes.icon}  fontSize="large"  onClick={()=>handleClick(1)} />
           </Grid>
         </Grid>
          
         <Grid item container style={{marginTop:-theme.spacing(23)}}>
            <Grid item sm={3} xs={3}></Grid>
            <Grid item sm={6} xs={6}>
            <GridList cellHeight={20}  cols={6} className={classes.gridList} spacing={1}>
              {displayedImages.map((tile) => (
                <GridListTile key={tile.img} cols={6} style={{ height:"55%"}}>
                  <img src={tile.img} alt={tile.title}  style={{height:"55%" , width:"100%"}}/>
                </GridListTile>
              ))}
            </GridList>
            </Grid>
            <Grid item sm={3} xs={3}></Grid>
          </Grid>

</Grid>
        </Paper>
      </Grid>

    </div>
  );
};

export default GlimpseAtAGCMobile;
