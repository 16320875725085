import React from "react";

import {
  makeStyles,
  useTheme,
  Grid,
  Paper,
  useMediaQuery,
  Button,
  AppBar,
  CssBaseline,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import sipAbacusLogo from "./images/SIPAcademyLogo.svg";
import plusLeft from "./images/plusSignLeft.svg";
import plusRight from "./images/plusSignRight.svg";
import agcLogo from "./images/AGCLogo.svg";
//import backgroudImage from "./images/BG_Page_04.svg";
import mobileBG from "./images/mobileBG.svg"
import nieLogo from "./images/Nie_logo.jpg";
import TTISLogo from "./images/TTIS_logo.jpg";
import abpLogo from "./images/Abp_logo.png"




const useStyles = makeStyles((theme) => ({
  aboutAGCRoot: {
    backgroundColor: "white",
    alignContent: "stretch",
    flexWrap:"nowrap"

  },
  mediaPartnerPaper: {
    width: "180%",
    maxHeight:'100vh',
    minHeight:'100vh',
  //  backgroundImage: `url(${backgroudImage})`,
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover",
  },
 
  mediaPartnerMobilePaper: {
    width: "100%",
    height: "'100vh'",
    backgroundImage: `url(${mobileBG})`,
    backgroundColor:"white",
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover"
  },
  registrationPageLink: {
    textDecoration: "none",
  },

button: {
  color: "white",
  backgroundColor: "#E62127",
  fontWeight: "bold",
  borderRadius: theme.spacing(1),
  display: "inline-block",
  textAlign: "center",
},
mediaPartnerTitle: {
  textAlign: "center",
  color: "white",
  backgroundColor: "#E62127",
  fontWeight: "bold",
  borderRadius: "15px",
  height:"85%",
  whiteSpace: "pre-line",
  display:"inline-block",
  fontSize:theme.spacing(2),
  padding: theme.spacing(3)
},
text:{
    fontSize:"14px",
    fontWeight:"bold",
    color:"#43413e"
},
prizeContent: {
    textAlign: "left",
    fontSize: theme.spacing(3),
    // color: "#404040",
    whiteSpace: "pre-line",
},

}));

const MediaPartner = () => {
    const theme = useTheme();
    const classes = useStyles();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const title = "Media Partners";
    const register ="Register Now";

    const images=[{name:'nieLogo'},{name:'TTISLogo'},{name:'abpLogo'}]

    function ContentToBeDisplayed(){
        if(smallScreen){
            return(
                <React.Fragment>
                    <Grid container justifyContent="center" alignItems="center" direction="row">
                      <Grid item xs={1} sm={1} md={1}></Grid>
                      <Grid Item  xs={10} sm={10} md={10}>
                        <List>
                          <ListItem><img src={nieLogo} style={{height:"60%",width:"90%"}} /></ListItem>
                          <ListItem><img src={TTISLogo} style={{height:"90%",width:"90%"}} /></ListItem>
                          <ListItemText style={{textAlign:"center"}}><Typography className={classes.text}>Associate Partner in West Bengal</Typography></ListItemText>
                          <ListItem><img src={abpLogo} style={{height:"90%",width:"90%"}} /></ListItem>
                          <ListItemText style={{textAlign:"center"}}><Typography className={classes.text}>News Channel Partner in West Bengal</Typography></ListItemText>

                        </List>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1}></Grid>

                                                   
                    </Grid>
                </React.Fragment>
            )
        }else{
            return (
                <React.Fragment>
                     <Grid container justifyContent="center" alignContent="center" direction="row" spacing={1}> 
                <Grid item md={3} lg={3} xl={3} xs={1} sm={1} ></Grid>
                <Grid item md={2} lg={2} xl={2} xs={10} sm={10} >
                    <img src={nieLogo} style={{height:"80%",width:"80%"}} />
                </Grid> 
                <Grid item md={2} lg={2} xl={2} xs={10} sm={10} >
                    <img src={TTISLogo} style={{height:"80%",width:"80%"}} /> 
                    <Typography className={classes.text}>Associate Partner in West Bengal</Typography>
                </Grid>
                <Grid item md={2} lg={2} xl={2} xs={10} sm={10} >
                <img src={abpLogo} style={{height:"80%",width:"80%"}} /> 
                <Typography className={classes.text}>News Channel Partner in West Bengal</Typography>

                </Grid>
                <Grid item md={3} lg={3} xl={3} xs={1} sm={1} ></Grid>
            </Grid>
                </React.Fragment>
            )
        }
    }

  return (
    <React.Fragment>
      <CssBaseline />
        <Grid container justifyContent="center" alignItems="center">
          <Paper
            className={
              smallScreen ? classes.mediaPartnerMobilePaper : classes.mediaPartnerPaper
            }
          >
            <Grid item container md={12} xs={12} lg={12} sm={12} xl={12}  >
              <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
                <Grid item md={3} xs={3} lg={3} sm={3} xl={3}></Grid>
                <Grid item md={4} xs={9} sm={9} lg={4} xl={4}>
                  <img
                    src={sipAbacusLogo}
                    alt="logo"
                    height={smallScreen ? "60%" : "50%"}
                    width={smallScreen ? "60%" : "50%"}
                  />
                </Grid>
                <Grid item md={5} xs={0} sm={0} lg={5} xl={5}></Grid>
              </AppBar>
            </Grid>

            <Grid item container>
              <Grid item md={1} xs={1} sm={1} lg={1} xl={1}></Grid>
              <Grid
                item
                md={2}
                xs={4}
                sm={4}
                lg={2}
                className={classes.container}
                justifyContent="flex-start"
              >
                <img
                  src={agcLogo}
                  alt="Logo"
                  style={{ height:smallScreen?"80%":"60%", width:"60%" }}
                />
              </Grid>
              <Grid item md={6} xs={1} sm={1} lg={6} xl={6}></Grid>
              <Grid item md={3} xs={6} sm={6} lg={3} xl={3}>
                <Grid item container style={{ marginTop: theme.spacing(3) }}>
                 
             
              
                </Grid>
              </Grid>
            </Grid>

            <Grid item container md={12} xs={12} lg={12} sm={12} xl={12} style={{marginTop:smallScreen?theme.spacing(0):-theme.spacing(7)}}>
              <Grid item md={4} xs={1} lg={4} sm={1} xl={4}>
                
              </Grid>
              <Grid
                item
                md={4}
                xs={10}
                lg={4}
                sm={10}
                xl={4}
                className={classes.mediaPartnerTitle}
                style={{ marginTop: smallScreen ? theme.spacing(3) : 0 }}
              >
                {title}
              </Grid>
              <Grid item md={4} xs={1} lg={4} sm={1} xl={4}></Grid>
            </Grid>

            <br />
            <br />

           <ContentToBeDisplayed></ContentToBeDisplayed>
           
          </Paper>
        </Grid>
    </React.Fragment>
  );
};

export default MediaPartner;
