import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFA500",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, students) {
  return { name, students  };
}


const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  const {isDashboardLoading ,  dashBoardList } = props;

  const rows = [
    createData('No Of Students Pending for Round 1 (Non SIP)',isDashboardLoading ? dashBoardList.noOfNonSipKidsPendingForRound1 :0),
    createData('No Of Students Pending for Round 1 (SIP)', isDashboardLoading ? dashBoardList.noOfSipKidsPendingForRound1 :0),
  ];

  return (
    <div style={{marginTop:'15%',padding:'6px'}}>
        <hr/>
    <h2 style={{ textAlign:'center' }}>Students Pending For Test</h2>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table" style={{border:'2px solid orange'}} >
        <TableHead>
          <TableRow>
            <StyledTableCell style={{fontSize:'17px'}}>Students Pending For Test</StyledTableCell>
            <StyledTableCell align="left" style={{fontSize:'17px'}}>No Of Students</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="left">{row.students}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}
