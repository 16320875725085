import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { TextField, Grid, makeStyles, FormControl, Checkbox, CssBaseline, FormHelperText, InputAdornment, IconButton, Paper, Snackbar, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Controller, useForm } from 'react-hook-form';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { Alert, Autocomplete } from '@material-ui/lab';
import ResetOutlinedIcon from '@material-ui/icons/LoopOutlined';
import _ from "lodash/fp";
import GoalBarChart from './GoalBarChart';
import TotalKidsPieChart from './TotalKidsPieChart';
import DashboardDataTable from './DashboardDataTable';
import PendingTable from './PendingTable';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    paper_padding: {
        padding: theme.spacing(2)
    },

}));



function Dashboard() {
    const classes = useStyles();
    const { register, handleSubmit, errors, reset, control } = useForm({
        mode: "all",
    });
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    const [isLoading, setIsLoading] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [isSubmitButton, disableSubmitButton] = useState(false);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [stateID, setStateID] = useState('');
    const [cityID, setCityID] = useState('');
    const [stateValue, setStateValue] = React.useState('');
    const [cityValue, setCityValue] = React.useState('');
    const [branchValue, setBranchValue] = React.useState('');
    const [serverDateString, setServerDateString] = useState('');
    const [dashBoardList, setDashboardList] = useState([]);
    const [isDashboardLoading, setIsDashBoardLoading] = useState(false);
    const [dateVisibility, setDateVisibility] = useState(false);
    const [filterVisibility, setFilterVisibility] = useState(false);
    const [isDashLoading, setIsDashLoading] = React.useState(true);
    const roleName = JSON.parse(localStorage.getItem("sip-user")).roles[0].name;
    const roleAndDesignationLevel = {
        roles: {
            roleNameForRM: 'REGIONAL MANAGER',
            roleNameForSH: 'STATE HEAD',
            roleNameForAH: 'AREA HEAD',
            roleNameForHO: 'ADMIN',
            roleNameForLCL: 'LCL ADMIN'
        }
    }

    const onSubmit = data => {
        data["userId"] = userId;
        data["state"] = data.state === null ? { id: 0, stateName: "" } : data.state;
        data["city"] = data.city === null ? { cityName: "", cityId: 0 } : data.city;
        data["branch"] = data.branch === null ? { branchId: 0, name: '' } : data.branch;
        data["user"] = { userId: userId };
        data["startDate"] = data.fromDate;
        data["toDate"] = data.toDate;
        setIsLoading(true);
        axios.post(BASE_URL + "/dashboard", data,
            {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                if (response.data) {
                    setState({ ...state, openAlert: true, message: 'Request Successful!', severity: "success" });

                    setDashboardList(response.data[0]);
                    setIsLoading(false);
                } else {
                    setState({ ...state, openAlert: true, message: 'Request failed!...', severity: "error" });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setState({ ...state, openAlert: true, message: 'Request failed!...', severity: "error" });
                setIsLoading(false);
            });

        resetReportPage();
    }

    const loadStateData = () => {

        setStateList([{ stateName: 'Loading...' }]);
        axios.get(BASE_URL + "/states",
            {
                params: {
                    body: JSON.stringify({ stateName: "" ,active:false}),
                },
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {

                setStateList(response.data.content);

            });

    }

    const loadBranchData = () => {

        setBranchList([{ name: 'Loading...' }]);
        axios.get(BASE_URL + "/branches",
            {
                params: {
                    body: JSON.stringify({
                        name: "",
                        branchId: 0,
                        address: {
                            countryId: 1,
                            stateId: stateID,
                            cityId: cityID,
                        }
                    }),
                },

                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }

            }).then((response) => {

                setBranchList(response.data.content);

            });

    }

    const loadCityData = () => {

        setCityList([{ cityName: 'Loading...' }]);
        axios.get(BASE_URL + '/cities',
            {
                params: {
                    body: JSON.stringify({
                        cityName: "",
                        countryCombo: {
                            countryId: 1,
                        },
                        stateCombo: {
                            stateId: stateID,
                        }
                    }),
                },
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                setCityList(response.data.content);

            });

    }
    const cityData = cityList.map((city) => {
        return { cityName: city.cityName, cityId: city.cityId }
    })

    const stateData = stateList.map((state) => {
        return { stateName: state.stateName, stateId: state.stateId }
    })

    const branchData = branchList.map((branch) => {
        return { name: branch.name, branchId: branch.branchId }
    })

    const userId = JSON.parse(localStorage.getItem("sip-user")).userId;
    const loadDashboardData = () => {

        axios.post(BASE_URL + "/dashboard", {
            user: {
                userId: userId,
            },
            examinationId:1

        },
            {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {

                setDashboardList(response.data[0]);
                setIsDashLoading(false);
                if ( response.data.length >0 && (response.data[0].noOfLeads+response.data[0].notUpdatedStudents) >0) {
                    setIsDashBoardLoading(true)
                }
            });

    }

    useEffect(() => {
        if (roleName === roleAndDesignationLevel.roles.roleNameForHO) {
            setDateVisibility(true)
        }
        if (roleName !== roleAndDesignationLevel.roles.roleNameForLCL) {
            setFilterVisibility(true)
        }

        loadDashboardData();
    }, [serverDate])

    const fieldLabelsAndName = {
        labelsAndHelperText: {
            reportType: 'Report Type',
            state: 'State',
            city: 'City',
            branch: 'Branch',
            fromDate: 'From Date',
            toDate: 'To Date',
            helperTextForSelection: `Please select the`,
            helperTextForEntering: `Please enter the`,
            resetButtonLabel: `Reset`,
            submitButtonLabel: `Submit`
        },
        name: {
            reportType: 'reportType',
            state: 'state',
            city: 'city',
            branch: 'branch',
            fromDate: 'fromDate',
            toDate: 'toDate'
        }

    }

    const [serverDate] = useState(() => {
        const BASE_URL = `${process.env.REACT_APP_API_URL}`;
        let currentDateAndTimeFromServer;
        let currentDateMinus1;
        axios.get(BASE_URL + "/SIPCustomApi/serverCurrentDateAndTime",
            {
                headers: {
                    authorization: 'Bearer ' + localStorage.getItem("sip-authorization")
                }
            }).then((response) => {
                currentDateAndTimeFromServer = new Date(response.data);
                currentDateMinus1 = currentDateAndTimeFromServer;
                currentDateMinus1.setDate(currentDateAndTimeFromServer.getDate() - 1);
                setServerDateString(response.data);
                //setFromDate(currentDateMinus1);
            })
            .catch(function (error) {
            });
        return currentDateAndTimeFromServer;
    });
    const resetReportPage = () => {
        let currentDateAndTimeFromServer = new Date(serverDateString);
        let formattedFromDate = `${currentDateAndTimeFromServer.getDate()}-${currentDateAndTimeFromServer.getMonth()}-${currentDateAndTimeFromServer.getYear()}`;
        let currentDateMinus1 = currentDateAndTimeFromServer;
        currentDateMinus1.setDate(currentDateAndTimeFromServer.getDate() - 1);
        // let formattedToDate = `${currentDateMinus1.getDate()}-${currentDateMinus1.getMonth()}-${currentDateMinus1.getYear()}`;
        setToDate(new Date(serverDateString));
        // setFromDate(currentDateMinus1);
        reset({
            fromDate: null,
            toDate: formattedFromDate,
            state: null,
            city: null,
            branch: null,
        });
        setStateValue([]);
        setCityValue([]);
        setBranchValue([]);
        setStateID(0);
        setCityID(0)
    }

    const [toDate, setToDate] = useState(serverDate);
    const [state, setState] = React.useState({
        openAlert: false,
        vertical: 'top',
        horizontal: 'center',
        message: '',
        severity: ''
    });
    const { vertical, horizontal, openAlert, message, severity } = state;
    const handleAlertClose = () => {
        setState({ ...state, openAlert: false });
    };


    return (
        <div>
            {filterVisibility ?
                <Paper className={classes.paper}>
                    <Snackbar
                        anchorOrigin={{ vertical, horizontal }}
                        open={openAlert}
                        onClose={handleAlertClose}
                        key={vertical + horizontal}
                        autoHideDuration={6000}
                    >
                        <Alert onClose={handleAlertClose} severity={severity}>
                            {message}
                        </Alert>
                    </Snackbar>
                    <form onSubmit={handleSubmit(onSubmit)} className={classes.paper_padding}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormControl fullWidth>
                                    <Controller
                                        render={props => (
                                            <Autocomplete
                                                onChange={(event, newValue) => {
                                                    props.onChange(newValue)

                                                    if (typeof newValue === 'string') {
                                                        setStateValue({
                                                            newValue,
                                                        });

                                                        if (newValue != null) {
                                                            setStateID(newValue.stateId)
                                                        }
                                                    } else if (newValue && newValue.inputValue) {
                                                        // Create a new value from the user input
                                                        setStateValue({
                                                            title: newValue.inputValue,
                                                        });
                                                        if (newValue != null) {
                                                            setStateID(newValue.stateId)
                                                        }

                                                    }
                                                    else if (newValue != null) {
                                                        setStateValue(newValue)
                                                        if (newValue) {
                                                            setStateID(newValue.stateId)
                                                        }
                                                    } else if (newValue === null) {
                                                        setStateValue('')
                                                        setStateID('')
                                                        setCityID('')
                                                    } else {
                                                        setStateValue(newValue)
                                                        setStateID(1)
                                                    }
                                                    setCityList([]);
                                                    setBranchList([]);

                                                    setBranchValue([]);
                                                    setCityValue([]);
                                                }}
                                                selectOnFocus
                                                handleHomeEndKeys
                                                value={stateValue.stateName || null}
                                                autoHighlight
                                                onOpen={loadStateData}
                                                options={stateData}
                                                getOptionLabel={(option) => {

                                                    if (typeof option === 'string') {
                                                        return option;
                                                    }

                                                    return option.stateName;
                                                }}
                                                getOptionSelected={(option, value) => option.stateName === value.stateName}
                                                renderOption={(option) => option.stateName}


                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        name={fieldLabelsAndName.name.state}
                                                        size='small'
                                                        label={fieldLabelsAndName.labelsAndHelperText.state}
                                                        variant="outlined"
                                                    />
                                                )}

                                            />
                                        )}

                                        rules={{ required: false }}
                                        name={fieldLabelsAndName.name.state}
                                        control={control}
                                        defaultValue={{ id: 0, stateName: '' }}
                                    />

                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormControl fullWidth>
                                    <Controller
                                        render={props => (
                                            <Autocomplete
                                                onChange={(event, newValue) => {
                                                    props.onChange(newValue)

                                                    if (typeof newValue === 'string') {

                                                        if (newValue != null) {
                                                            setCityValue()({
                                                                newValue,
                                                            });
                                                            setCityID(newValue.cityId)
                                                        }
                                                    } else if (newValue && newValue.inputValue) {
                                                        // Create a new value from the user input
                                                        setCityValue({
                                                            title: newValue.inputValue,
                                                        });
                                                        if (newValue != null) {
                                                            setCityID(newValue.cityId)
                                                        }

                                                    }
                                                    else if (newValue != null) {
                                                        setCityValue(newValue);
                                                        if (newValue) {
                                                            setCityID(newValue.cityId)
                                                        }
                                                    } else if (newValue === null) {
                                                        setCityValue('')
                                                        setCityID('')
                                                    } else {
                                                        setCityValue(newValue);
                                                        setCityID(1)
                                                    }
                                                    setBranchValue([]);
                                                }}
                                                selectOnFocus
                                                handleHomeEndKeys
                                                value={cityValue.cityName || null}
                                                onOpen={loadCityData}
                                                options={cityData}
                                                autoHighlight
                                                getOptionLabel={(option) => {

                                                    if (typeof option === 'string') {
                                                        return option;
                                                    }

                                                    return option.cityName;
                                                }}
                                                renderOption={(option) => option.cityName}
                                                getOptionSelected={(option, value) => option.cityName === value.cityName}

                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        className={(errors.reportType && classes.invalid) || classes.formControl}
                                                        name={fieldLabelsAndName.name.city}
                                                        size='small'
                                                        label={fieldLabelsAndName.labelsAndHelperText.city}
                                                        variant="outlined"
                                                    />
                                                )}

                                            />
                                        )}

                                        rules={{ required: false }}
                                        name={fieldLabelsAndName.name.city}
                                        control={control}
                                        defaultValue={{ cityName: '', cityId: 0 }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormControl fullWidth>
                                    <Controller
                                        render={props => (
                                            <Autocomplete

                                                onChange={(event, newValue) => {
                                                    props.onChange(newValue)

                                                    if (typeof newValue === 'string') {

                                                        if (newValue != null) {
                                                            setBranchValue({
                                                                newValue,
                                                            });
                                                        }
                                                    } else if (newValue && newValue.inputValue) {
                                                        // Create a new value from the user input
                                                        setBranchValue({
                                                            title: newValue.inputValue,
                                                        });

                                                    }
                                                    else if (newValue !== null) {

                                                        setBranchValue(newValue);
                                                    } else if (newValue === null) {
                                                        setBranchValue('')
                                                    } else {
                                                        setBranchValue(newValue);
                                                    }
                                                }}
                                                selectOnFocus
                                                handleHomeEndKeys
                                                value={branchValue.name || null}
                                                autoHighlight
                                                onOpen={loadBranchData}
                                                options={branchData}
                                                getOptionLabel={(option) => {

                                                    if (typeof option === 'string') {
                                                        return option;
                                                    }
                                                    if (option.inputValue) {
                                                        return option.inputValue;
                                                    }
                                                    return option.name;
                                                }}
                                                getOptionSelected={(option, value) => option.name === value.name}

                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        name={fieldLabelsAndName.name.branch}

                                                        size='small'
                                                        label={fieldLabelsAndName.labelsAndHelperText.branch}
                                                        variant="outlined"
                                                    />
                                                )}

                                            />
                                        )}

                                        rules={{ required: false }}
                                        name={fieldLabelsAndName.name.branch}
                                        control={control}
                                        defaultValue={{ branchId: 0, name: '' }}
                                    />
                                </FormControl>
                            </Grid>
                            {dateVisibility ?
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormControl fullWidth>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <FormControl fullWidth>
                                                <KeyboardDatePicker
                                                    size="small"
                                                    id="start-date"
                                                    label={fieldLabelsAndName.labelsAndHelperText.fromDate}
                                                    format="dd-MM-yyyy"
                                                    inputVariant="outlined"
                                                    inputRef={register({
                                                        required: false,
                                                    })}
                                                    value={fromDate}
                                                    maxDate={new Date(serverDateString)}
                                                    invalidDateMessage={`Please select valid date.`}
                                                    minDateMessage={`Date cannot be less than today.`}
                                                    onChange={((date) => {
                                                        if (date === null || date === undefined) {
                                                            setFromDate();
                                                        } else {
                                                            setFromDate(date);
                                                        }
                                                    })}
                                                    InputLabelProps={{ shrink: true }}
                                                    name={fieldLabelsAndName.name.fromDate}
                                                />
                                            </FormControl>
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                                : null}

                            {dateVisibility ?
                                <Grid item xs={12} sm={12} md={4} lg={4}>
                                    <FormControl fullWidth>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <FormControl fullWidth>
                                                <KeyboardDatePicker
                                                    size="small"
                                                    id="to-date"
                                                    label={fieldLabelsAndName.labelsAndHelperText.toDate}
                                                    format="dd-MM-yyyy"
                                                    inputVariant="outlined"
                                                    inputRef={register({
                                                        required: false,
                                                    })}
                                                    value={toDate}
                                                    minDate={fromDate}
                                                    maxDate={new Date(serverDateString)}
                                                    invalidDateMessage={`Please select valid date.`}
                                                    minDateMessage={`${fieldLabelsAndName.labelsAndHelperText.toDate} is less than ${fieldLabelsAndName.labelsAndHelperText.fromDate}.`}
                                                    onChange={((date) => {
                                                        if (date === null || date === undefined) {
                                                            setToDate();
                                                        } else {
                                                            setToDate(date);
                                                        }
                                                    })}
                                                    InputLabelProps={{ shrink: true }}
                                                    name={fieldLabelsAndName.name.toDate}
                                                />
                                            </FormControl>
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                                : null}

                        </Grid>
                        <Grid container spacing={2}>
                            <Grid container justify="flex-end" alignItems="flex-end" item xs={12} sm={12} md={12} lg={12}>
                                {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                                <Grid container justify="flex-end" alignItems="flex-end" item xs={6} sm={6} md={1} lg={1}>
                                    <Button variant="outlined" size={"small"} spacing={2} color="secondary"
                                        endIcon={<ResetOutlinedIcon />}
                                        onClick={resetReportPage}
                                    >{fieldLabelsAndName.labelsAndHelperText.resetButtonLabel}</Button>
                                </Grid>
                                <Grid container justify="flex-end" alignItems="flex-end" item xs={6} sm={6} md={1} lg={1}>
                                    <Button variant="outlined" size={"small"} color="primary" disabled={isLoading}
                                        onClick={handleSubmit(onSubmit)}

                                        endIcon={<SaveOutlinedIcon />}>{fieldLabelsAndName.labelsAndHelperText.submitButtonLabel}</Button>

                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
                : null}
            <Paper>
                <div style={{ marginTop: '10px' }}>
                    {isDashLoading && <Grid container justify="flex-end" alignItems="flex-end" item xs={6} lg={6}>
                        <CircularProgress size={60} />
                    </Grid>}
                    {isDashLoading ? null :
                        <Grid container style={{ height: '100%', width: '100%' }}>

                            <Grid item md={5}>
                                <GoalBarChart isDashboardLoading={isDashboardLoading} dashBoardList={dashBoardList} />

                            </Grid>
                            <Grid item md={3}>

                            </Grid>
                            <Grid item md={4}>

                                <TotalKidsPieChart isDashboardLoading={isDashboardLoading} dashBoardList={dashBoardList} />

                            </Grid>

                            <Grid item md={6} >
                                <DashboardDataTable isDashboardLoading={isDashboardLoading} dashBoardList={dashBoardList} />

                            </Grid>

                            <Grid item md={6} >
                                <PendingTable isDashboardLoading={isDashboardLoading} dashBoardList={dashBoardList} />

                            </Grid>

                        </Grid>
                    }
                </div>


            </Paper>

        </div>
    );
}

export default Dashboard;
