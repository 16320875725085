import React from 'react';
import { Grid, Paper, makeStyles,  useMediaQuery, useTheme, Hidden, Typography, List, ListItem, ListItemText, ListItemIcon, Button, AppBar } from '@material-ui/core';

import agcLogo from "./images/AGCLogo.svg";
import sipAbacusLogo from "./images/SIPAcademyLogo.svg";
import plusLeft from "./images/plusSignLeft.svg";
import plusRight from "./images/plusSignRight.svg";
//import backgroudImage from "./images/background2.svg";
import mobileBG from "./images/mobileBG.svg"
import backgroudImage2022 from "./images/BG_Page_2022.png";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        alignContent: "stretch",
        wrap: "nowrap"
    },
    paper: {
        width: "100%",
        maxHeight: "auto",
        backgroundImage: `url(${backgroudImage2022})`,
        backgroundRepeat:"no-repeat",
        backgroundSize: "cover"

    },
    container: {
        textAlign: "center"
    },
    content: {
        textAlign: "center",
        fontSize: theme.spacing(2),
        marginTop: theme.spacing(1),
        fontWeight: "bold"
    },
    mobileAboutSIPPaper: {
        width: "100%",
        height: "auto",
        backgroundImage: `url(${backgroudImage2022})`,
        backgroundRepeat:"no-repeat",
        backgroundSize: "cover"
    },
    registrationPageLink: {
        textDecoration: "none",
      },
      button: {
        color: "white",
        backgroundColor: "#E62127",
        fontWeight: "bold",
        borderRadius: theme.spacing(1),
        display: "inline-block",
        textAlign: "center",
      },
      aboutSIPTitle: {
        textAlign: "center",
        color: "white",
        backgroundColor: "#E62127",
        fontWeight: "bold",
        borderRadius: "15px",
        height:"85%",
        whiteSpace: "pre-line",
        display:"inline-block",
        padding:theme.spacing(3),
        fontSize:"20px"
      },
      text:{
          fontSize:theme.spacing(2),
          fontWeight:"bold",
          textAlign:"center",
          color:"#43413e"
      },
      horizontalLine1: {
        borderBottom: "1px solid white",
        textAlign: "center",
        height:"16px"
      },
      horizontalLine2: {
        backgroundColor: "#f8a41e",
        padding: "15px 15px",
        fontWeight: "bold",
        color: "#43413e",
        fontSize:"20px"
      },
      rectangle3: {
        height: "100px",
        width: "100px",
        borderRadius:"0px 0px 30px 0px",
        border:"2px solid white",
        textAlign:"center",
        backgroundColor: "#f8a41e",

      },
      rectangle2: {
        height: "100px",
        width: "100px",
        borderRadius:"0px 0px 0px 0px",
        border:"2px solid white",
        textAlign:"center",
        backgroundColor: "#f8a41e",
      },
      rectangle1: {
        height: "100px",
        width: "100px",
        borderRadius:"30px 0px 0px 0px",
        border:"2px solid white",
        textAlign:"center",
        display:"inline-block",
        alignItems:"center",
        backgroundColor: "#f8a41e",

      },
      boxContent:{
          fontSize:theme.spacing(3),
          color:"#43413e",
          textAlign:"center",
          fontWeight:"bold",
          padding:"10px 10px",
          whiteSpace: "pre-line",
          display:"inline-block"
      }
}));


const AboutSIP = () => {

    const classes = useStyles();
    let theme = useTheme();
    const title = "ABOUT SIP ACADEMY";
    const secondTitle = "SIP ACADEMY'S PRESENCE"
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const box1Content = "900 \n CENTRES";
    const box2Content = "23 \n STATES";
    const box3Content = "14 \n COUNTRIES";
    const register ="REGISTER NOW"

    function SIPAcademyPresence(){
        if(!smallScreen){
            return (
                <React.Fragment>
                          <Grid item md={3} lg={3}></Grid>
                            <Grid item md={2} lg={2} className={classes.rectangle1} style={{ marginLeft: theme.spacing(1),textAlign:"center" }}>
                                <span className={classes.boxContent} >{box1Content}</span>
                            </Grid>

                            <Grid item md={2} lg={2} className={classes.rectangle2} style={{ marginLeft: theme.spacing(1) }}>
                                <span className={classes.boxContent}>{box2Content}</span>
                            </Grid>

                            <Grid item md={2} lg={2} className={classes.rectangle3} style={{ marginLeft: theme.spacing(1) }}>
                                <span className={classes.boxContent}>{box3Content}</span>
                            </Grid>
                            <Grid item md={3} lg={3}></Grid>

                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                    <Grid  container xs={12} sm={12}  justify="space-evenly" style={{textAlign:"center"}}>
                      <Grid item sm={2} xs={2}></Grid>
                      <Grid item sm={8} xs={8} className={classes.rectangle1} style={{marginBottom:theme.spacing(1)}} >
                      <span className={classes.boxContent}>{box1Content}</span>
                      </Grid>
                      <Grid item sm={2} xs={2}></Grid>
                     </Grid>

                     <Grid  container xs={12} sm={12}  justify="space-evenly" style={{textAlign:"center"}}>
                      <Grid item sm={2} xs={2}></Grid>
                      <Grid item sm={8} xs={8} className={classes.rectangle2} style={{marginBottom:theme.spacing(1)}} >
                      <span className={classes.boxContent}>{box2Content}</span>
                      </Grid>
                      <Grid item sm={2} xs={2}></Grid>
                     </Grid>

                     <Grid  container xs={12} sm={12}  justify="space-evenly" style={{textAlign:"center"}}>
                      <Grid item sm={2} xs={2}></Grid>
                      <Grid item sm={8} xs={8} className={classes.rectangle3} style={{marginBottom:theme.spacing(1)}}>
                      <span className={classes.boxContent}>{box3Content}</span>
                      </Grid>
                      <Grid item sm={2} xs={2}></Grid>
                     </Grid>

                     

                </React.Fragment>
            )
        }
    }


    return (
        <div className={classes.root}>
            <Grid container spacing={2}>

                <Paper className={smallScreen ? classes.mobileAboutSIPPaper : classes.paper}  >
  
             <Grid item container md={12} xs={12} lg={12} sm={12} >
               <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
                <Grid item md={3} xs={3} lg={3} sm={3}></Grid>
                 <Grid item md={4} xs={9} sm={9} lg={4} >
                  <img src={sipAbacusLogo} alt="logo" height= {smallScreen ?"60%":"50%"}  width={smallScreen ?"60%":"50%"} />
                 </Grid>
                 <Grid item md={5} xs={0} sm={0} lg={5}></Grid>
               </AppBar>
               </Grid>

               <Grid  container>
               <Grid item md={1} xs={1} sm={1} lg={1}></Grid>
             <Grid item md={2} xs={4} sm={4} lg={2} className={classes.container} justifyContent="flex-start">
             <img
                   src={agcLogo}
                   alt="Logo"
                   style={{ height: smallScreen?"80%":"60%", width: "60%" }}
                 />
             </Grid>
             <Grid item md={6} xs={1} sm={1} lg={6}></Grid>
             <Grid item md={3} xs={6} sm={6} lg={3}>

            <Grid  container  style={{marginTop: theme.spacing(3)}}>
              </Grid>
              </Grid>
              </Grid>

                <Grid  container md={12} xs={12} lg={12} sm={12} xl={12}  style={{marginTop: smallScreen ? theme.spacing(0):-theme.spacing(7)}}>
                    <Grid item md={4} xs={2} lg={4} sm={2} xl={4}> </Grid>
                    <Grid item md={4} xs={8} lg={4} sm={8} xl={4} className={classes.aboutSIPTitle} style={{marginTop :smallScreen ? theme.spacing(1):0}}>{title}</Grid>
                    <Grid item md={4} xs={2} lg={4} sm={2} xl={4}></Grid>  
                </Grid>

                <br />
                <br />

               <Grid  container md={12} sm={12} lg={12} xs={12}>
                   <Grid item md={3} sm={1} lg={3} xs={1}></Grid>
                   <Grid item md={6} sm={10} lg={6} xs={10}>

                       <Typography className={classes.text}>SIP Academy India Pvt Ltd, headquartered in Chennai, runs skill development programmes for children between
                       the ages of 5- 15 years since 2003.  All our programmes have been developed after years of research and testing
                       and are of international standards. These programmes focus on developing various skills in children, thereby
                       developing their learning ability.</Typography>
                   </Grid>
                   <Grid item md={3} sm={1} lg={3} xs={1}></Grid> 
               </Grid>

               <br />
               <br />
               <br />

                    <Grid  container md={12} xs={12} lg={12} sm={12} >
                        <Grid item md={3} sm={1} lg={3} xs={1}></Grid>
                        <Grid item md={3} sm={8} lg={3} xs={8}>
                         <div className={classes.horizontalLine1} style={{width:smallScreen?"130%":"200%"}}>
                             <span className={classes.horizontalLine2}>{secondTitle}</span>
                         </div>

                        </Grid>
                        <Grid item md={3} sm={1} lg={3} xs={1}></Grid>
                    </Grid>

                    <Grid  container style={{ marginTop: smallScreen ? "20px" : "50px" }}>
                        <SIPAcademyPresence></SIPAcademyPresence>
                        <br />
                     <br />
                     <br />
                     <br />
                     
                    </Grid>
                </Paper>
            </Grid>
        </div>
    );

};

export default AboutSIP;