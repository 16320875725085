import React, { useEffect, useState,useContext } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  CssBaseline,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import QuestionPage from "./QuestionPage";
import ConfirmationDialog from "../../Common/Confirmation";
import { useHistory } from "react-router-dom";
import DurationComponent from "./durationComponent";
import axios from "axios";
import { Beforeunload } from "react-beforeunload";
import { Offline } from "react-detect-offline";
import Alert from "@material-ui/lab/Alert";
import { LoggedInUserDetails } from "../LoggedInUserDetails/LoggedInUserDetails";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "-1.2%",
    position: "relative",
    height: "80vh",
  },
  paperContainer: {
    padding: theme.spacing(1.5),
  },
  cardHeader: {
    backgroundColor: "tomato",
    textAlign: "center",
  },
  buttonOption: {
    width: "100%",
    padding: theme.spacing(1),
    border: 0,
  },
  title: {
    fontSize: 30,
    backgroundColor: "#ff4154",
    borderCollapse: "collapse",
    width: "100%",
    borderSpacing: 10,
    borderTop: 0,
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
  },
  question: {
    fontWeight: "bold",
    color: "#000099",
    fontSize: "40px",
  },
  cardContainer: {
    borderRadius: "30px",
  },
  questionContainer: {
    whiteSpace: "pre-line",
    fontSize: "30px",
    fontWeight: "500",
    alignItems: "space-between",
    textAlign: "right",
  },
  icon: {
    backgroundColor: "#ff4154",
  },
  paper: {
    padding: theme.spacing(2),
    color: "white",
    backgroundColor: "#ff4154",
    borderCollapse: "collapse",
    fontSize: 12,
    fontWeight: "bold",
    borderRadius: 15,
    justifyContent: "center",
    width: "95%",
    display: "inline-block",
    height: "auto",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function Exam2(props) {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const BASE_URL = `${process.env.REACT_APP_API_URL}`;
  const [answers, setAnswers] = React.useState([]);
  const [answeredQuestion, setAnsweredQuestion] = React.useState(0);
  const [examObject] = React.useState(props.location.state.examObject);
  const [examinationType] = React.useState(props.location.state.examinationType);
  const { loggedInUserInfo ,setLoggedInUserInfo} = useContext(LoggedInUserDetails);
  const [allotedTime,setAllotedTime] = React.useState();
  
  const [stopTimer, setStopTimer] = React.useState(false);
  const [syncTimer,setSyncTimer] = React.useState(false);
  const timeToWaitForExamAbortionInSec = 300;
  const [totalNoOfQueInPreSec, setTotalNoOfQueInPreSec] = useState(0);
  const [networkDownCountDown, setNetworkDownCountDown] = React.useState(
    timeToWaitForExamAbortionInSec
  );
  const [isOffline, setIsOffline] = React.useState(true);
  const [totalNoOfQuestions, setTotalNoOfQuestions] = React.useState(0);
  const polling = {
    enabled: true,
    interval: 20000,
    timeout: 20000,
    
  };
  const [agcTimeLoad,setAGCTimeLoad] = React.useState(false)
  const [questionPaper, setQuestionPaper] = React.useState([]);
  const [tabDisability,setTabDisability] = React.useState(false);
  const [totalTimeSync,setTotalTimeSync] = React.useState(false);
  let retryCount=0; 
  let marks=0;
  let totalQuestions=0;
  let totalAttendedCount=0;
  let totalCorrectAnsCount=0;
  let attemptCount=0;
  useEffect(() => {
    if(syncTimer){
    const duration = props.location.state.examObject.exam.duration
    .toString()
    .split(":");
  const durationInTime = new Date();
  const totalHrs = durationInTime.getHours() + parseInt(duration[0]);
  const totalMins = durationInTime.getMinutes() + parseInt(duration[1]);
  durationInTime.setHours(totalHrs, totalMins);
  setAllotedTime(durationInTime);
  
}
},[syncTimer])

  function replaceBrowserHistory() {
    history.replace("/student/portal/dashboard",{examination:examObject.examination});
  }

  useEffect(() => {
    if (stopTimer) {
      handleSubmit();
    } else {

      axios
        .get(BASE_URL + `/question/questionPaper/info/${examObject.exam.examId}/${examObject.exam.classId}/${examObject.exam.subjectId}`, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("authorization"),
          },
        })
        .then((response) => {       
          // setTimeout(() => {
            
          
                setQuestionPaper(response.data.responseData[0]);
                if (!examObject.isMokeTest || examObject.exam.recordPracticeScores) {
                  updateExamParticipation("in-progress");
                }
                countQuestions(response.data.responseData[0].sectionList);
             //   disableBatch(); 
                if (examinationType ==='agc'){
                  setAGCTimeLoad(true);
                } 
              // }, 6000);  
                    
        });
    }
    if(examinationType ==='prodigy' || examinationType ==='practice' ){
      setTabDisability(true);
    }
  }, [examObject.exam.examId, stopTimer]);

  React.useEffect(() => {
    
    if (!isOffline) {
      const timer = setTimeout(() => {
        reduceTime(networkDownCountDown);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isOffline, networkDownCountDown]);

  const countQuestions = (event) => {
    let questions = 0;
    for (var i = 0; i < event.length; i++) {
      questions = questions + Number([event[i].questionBank.length]);
      setTotalNoOfQuestions(questions);
    }
  };

   const  handleSubmit = async() => {
    if (!examObject.isMokeTest || examObject.exam.recordPracticeScores) {
    if(retryCount<10){
     await axios
        .post(BASE_URL + `/examinationParticipation/examAnswers/save/${loggedInUserInfo.studentId}`, answers, {
          headers: {
            authorization: "Bearer " + localStorage.getItem("authorization"),
          },
        })
        .then((response) => {  
          marks=response.data.responseData.totalMarks;
          totalQuestions=response.data.responseData.totalQuestion;
          totalAttendedCount=response.data.responseData.totalAnswered;
          totalCorrectAnsCount=response.data.responseData.totalCorrect;

          updateExamParticipation("completed");
         // disableBatch();
          // to navigate to dashboard instead of exam page when clicking on browser back button
          replaceBrowserHistory();
         
          history.push({
            pathname: '/practice/thankYou',
            state: { message: 'Thank You for taking the test...', status: true,examination:examObject.examination,attemptCount:response.data.responseData.attemptDone}
        });
        }).catch(function (error){ 
            retryCount++;
            //wait for 10 sec
            setTimeout(function(){ 
                handleSubmit();
            }, 10000);
        });
}else{
    const errorMessage ="Unable to save results due to internet connection interruption. Please take the test again or contact your SIP Abacus Center for scheduling a retest. Thank you."
    updateExamParticipation("aborted");
    replaceBrowserHistory();
    history.push({
      pathname: "/practice/thankYou",
      state: { message: errorMessage, status: false, examination:examObject.examination },
    });
}
    }else{
   //  disableBatch();  
        // to navigate to dashboard instead of exam page when clicking on browser back button 
        replaceBrowserHistory();
        history.push(
            {
                pathname: '/practice/thankYou',
                state: { message: 'Thank You for taking the test...', status: true,examination:examObject.examination }
            });

    }
  };

  const handleTabChange = async (event, newValue) => {
    setValue(newValue);
    let totalNoOfQuePresent = 0;
    for (let index = 0; index < newValue; index++) {
      totalNoOfQuePresent =
        totalNoOfQuePresent + questionPaper.sectionList[index].questionBank.length;
    }
    await setTotalNoOfQueInPreSec(totalNoOfQuePresent);
  };

  const handleClose = (confirmed) => {
    setOpen(false);
    if (confirmed) {
      handleSubmit();
    }
  };

  const updateExamParticipation = (status) => {
    let examParticipationObject = [];
    if (status === "in-progress") {
      examParticipationObject = {
        //studentBatchSchedule: {
          studentBatchScheduleId: examObject.studentBatchScheduleId,
       // },
        examStatus: status,
        examParticipationId: examObject.examParticipationId,
       // questionPaper: {
         // questionPaperId: questionPaper && questionPaper.questionPaperId,
         questionPaperId:0,
      //  },
        marksScored:"0",
        active:true,
        startTime:examObject.startTime,
        startDtDisp:examObject.startTime

      };
    } else {
      examParticipationObject = {
        //studentBatchSchedule: {
          studentBatchScheduleId: examObject.studentBatchScheduleId,
       // },
        examStatus: status,
        examParticipationId: examObject.examParticipationId,
       // questionPaper: {
         // questionPaperId: questionPaper && questionPaper.questionPaperId,
         questionPaperId:questionPaper.questionPaperId,
          marksScored:marks,
          active:true,
          totalQuestions:totalNoOfQuestions,
          answeredQuestions:totalAttendedCount,
          correctAnswered:totalCorrectAnsCount,
          startTime:examObject.startTime,
          startDtDisp:examObject.startTime

       // },
      };
    }

    saveOrUpdateExamStatus(examParticipationObject);
  };

  function saveOrUpdateExamStatus(examParticipationObject) {
    axios
      .post(BASE_URL + `/examinationParticipation/examParticipation/save`, examParticipationObject, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("authorization"),
        },
      })
      .then((response) => {
        axios
        .get(
          BASE_URL +
            `/examinationParticipation/exam/validate/student/attempts/${loggedInUserInfo.studentId}`,
          {
            headers: {
              authorization: "Bearer " + localStorage.getItem("authorization"),
            },
          }
        )
        .then((response) => {
          setLoggedInUserInfo({...loggedInUserInfo,noOfAttempts:response.data.responseData.attemptDone})
        })
      });
  }

  const disableBatch = () => {
    let studentBatchScheduleObject = {
      studentBatchScheduleId: examObject.studentBatchScheduleId,
      batch: {
        batchId: examObject.batchId,
      },
      exam: {
        examId: examObject.exam.examId,
      },
      student: {
        studentId: examObject.studentId,
      },
      active: false,
    };

    axios
      .post(BASE_URL + `/student/batch`, studentBatchScheduleObject, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("authorization"),
        },
      })
      .then((response) => {});
  };

  function reduceTime(timeLeft) {
    if (timeLeft > 0) {
      timeLeft = timeLeft - 1;
      setNetworkDownCountDown(timeLeft);
    } else {
      const errorMessage = `Sorry! Test has been aborted due to Internet Connectivity Issues, 
             please contact your SIP Abacus Center for scheduling a retest. Thank you.`;
     
     // disableBatch();
      let examParticipationObject = {
        //studentBatchSchedule: {
          studentBatchScheduleId: examObject.studentBatchScheduleId,
       // },
        examStatus: "Interrupted",
        examParticipationId: examObject.examParticipationId,
       // questionPaper: {
         // questionPaperId: questionPaper && questionPaper.questionPaperId,
         'questionPaperId':questionPaper.questionPaperId,
       // },
       marksScored:"0",
       active:true,
      };
      saveOrUpdateExamStatus(examParticipationObject);
      // to prevent re-directing to exam page
      replaceBrowserHistory();
      history.push({
        pathname: "/practice/thankYou",
        state: { message: errorMessage, status: false ,examination: examObject.examination },
      });
    }
  }
  return (
    <Box>
      <Offline
        polling={polling}
        onChange={(offline) => {
          if (offline) {
            setNetworkDownCountDown(timeToWaitForExamAbortionInSec);
          }
          setIsOffline(offline);
        }}
      >
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open="true"
          key="top center"
        >
          <Alert severity="error">
            Internet Connection Lost! Reconnecting in 1 min...
          </Alert>
        </Snackbar>
      </Offline>
      <Beforeunload
        onBeforeunload={(event) => {
          event.returnValue = "are sure, you want to close this page?";
          event.preventDefault();
        }}
      />
      <div className={classes.root}>
        <CssBaseline />
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="row"
          className={classes.paperContainer}
        >
          <DurationComponent
            examObject={examObject}
            answeredQuestion={answeredQuestion}
            totalNoOfQuestions={totalNoOfQuestions}
            setStopTimer={setStopTimer}
            allotedTime={allotedTime}
            examinationType ={examinationType}
            setSyncTimer={setSyncTimer}
            syncTimer={syncTimer}
            agcTimeLoad={agcTimeLoad}
            totalTimeSync ={totalTimeSync}
            setTotalTimeSync={setTotalTimeSync}
          />
          <Grid item xs={12} md={12}>
            <Paper elevation={4} style={{ marginTop: "4px" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                variant="fullWidth"
                indicatorColor="primary"
                aria-label="module tabs"
              >
                {questionPaper.sectionList &&
                  questionPaper.sectionList.map((module, index) => (
                    <Tab label={module.name} {...a11yProps(index)} disabled ={tabDisability} style={{fontWeight:'bold',color:'black'}} />
                  ))}
              </Tabs>
              {questionPaper.sectionList &&
                questionPaper.sectionList.map((module, index) => (
                  <TabPanel
                    value={value}
                    index={index}
                    style={{ marginTop: "-0.8%" }}
                  >
                  {/* {syncTimer &&( */}
                      <QuestionPage
                      questions={module.questionBank}
                      moduleDuration = {module.sectionDuration}
                      moduleName = {module.name}
                      answers={answers}
                      setAnswers={setAnswers}
                      examObject={examObject}
                      setAnsweredQuestion={setAnsweredQuestion}
                      totalNoOfQueInPreSec={
                        index === 0 ? 0 : totalNoOfQueInPreSec
                      }
                      setTabValue={setValue}
                      tabValue={value}
                      moduleLength={questionPaper.sectionList.length}
                      examinationType={examinationType}
                      setSyncTimer={setSyncTimer}
                      syncTimer={syncTimer}
                      totalTimeSync ={totalTimeSync}
                      setTotalTimeSync={setTotalTimeSync}
                      setStopTimer={setStopTimer}
                      moduleId ={module.sectionId}
                    />
                     {/* )}  */}

                  </TabPanel>
                ))}
                  {!syncTimer ? <CircularProgress size={60} style={{ marginLeft: '50%', position: 'relative', top: 4 }} /> :null }
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper
              style={{ padding: "10px", marginTop: "-1.3%", justify: "center" }}
            >
              <Grid
                item
                container
                alignItems="center"
                justify="center"
                xs={12}
                md={12}
              >
                <ConfirmationDialog
                  onClose={handleClose}
                  message={"Are You sure?, You want to submit?"}
                  open={open}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

export default Exam2;
