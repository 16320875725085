import flashScreenBlurb from "../Dashboard/Blurb.jpeg";
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  image: {
    height: "95vh",
    backgroundImage: `url(${flashScreenBlurb})`,
    backgroundRepeat: "no-repeat",
    //backgroundSize: "cover",
    backgroundPosition: "center",
    width:"80%"
  },
  text: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#43413e",
    textAlign: "center",
    margin: theme.spacing(-5, 15, 2),
  },
}));

function FlashScreen() {
  const classes = useStyles();
  const [timeLeft, setTimeLeft] = useState(5);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  function calculateTimeLeft() {
    return timeLeft - 1;
  }

  return (
    <React.Fragment>
      <Grid container component="main" className={classes.root}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={classes.image}
          alignItems="flex-end"
        ></Grid>
      </Grid>
      <Typography className={classes.text}>
        Your Test will start in {timeLeft} seconds
      </Typography>
    </React.Fragment>
  );
}
export default FlashScreen;
