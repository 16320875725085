import React, { useState, useEffect } from "react";
import { Grid, Fab, Tooltip } from "@material-ui/core";
import Question2 from "./Question";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Pagination from "@material-ui/lab/Pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import TimerComponent from "./TimerComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "-1.2%",
    position: "relative",
  },
  icon: {
    backgroundColor: "#f38020",
  },mobileIcon: {
    backgroundColor: "#f38020",
  },leftIcon1:{
    backgroundColor: "#f38020",
    marginLeft:"85%"
  },rightIcon2:{
    backgroundColor: "#f38020",
    marginRight:"85%"
  }
}));

export default function QuestionPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    questions,
    answers,
    setAnsweredQuestion,
    examObject,
    setAnswers,
    setTabValue,
    tabValue,
    moduleLength,
    totalNoOfQueInPreSec,
    examinationType,
    moduleDuration,
    moduleName,
    setSyncTimer,
    syncTimer,
    totalTimeSync,
    setTotalTimeSync,
    setStopTimer,
    moduleId
  } = props;
  const [totalNumberOfPages, setTotalNumberOfPages] = useState();
  const [recordsPerPage, setRecordsPerPage] = useState();
  const [columnSize, setColumnSize] = useState();
  const [navigationButtonSize, setNavigationButtonSize] = useState();
  const [disableBackNavigateButtonStatus, setDisableBackNavigateButtonStatus] =
    useState(true);
  const [
    disableForwardNavigateButtonStatus,
    setDisableForwardNavigateButtonStatus,
  ] = useState(false);
  const [questionsToBeDisplayed, setQuestionsToBeDisplayed] = useState([]);
  const [page, setPage] = useState(0);
  const smallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const largeScreen = useMediaQuery(theme.breakpoints.between("md", "xl"));
  const tabScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [stopModuleTimer, setModuleStopTimer] = React.useState(false);
  const [moduleAllotedTime] = React.useState(() => {
    const duration = moduleDuration.toString().split(":");
    const durationInTime = new Date();
    const totalHrs = durationInTime.getHours() + parseInt(duration[0]);
    const totalMins = durationInTime.getMinutes() + parseInt(duration[1]);
    durationInTime.setHours(totalHrs, totalMins);
    return durationInTime;
  });

  useEffect(() => {
    if (examinationType === "agc" && smallScreen) {
      setTotalNumberOfPages(Math.round(questions.length / 1));
    } else if (examinationType === "agc" && !smallScreen) {
      setTotalNumberOfPages(Math.ceil(questions.length / 4));
    } else if (examinationType === "prodigy") {
      setTotalNumberOfPages(Math.ceil(questions.length / 1));
    }else if (examinationType === "practice" && smallScreen) {
        setTotalNumberOfPages(Math.round(questions.length / 1));
      }

    if (
      examinationType === "prodigy" &&
      stopModuleTimer &&
      tabValue < moduleLength
    ) {
      setTabValue((prevTabValue) => prevTabValue + 1);

      if (tabValue === moduleLength - 1) {
        setStopTimer(true);
      }
    }
    if (
        examinationType === "practice" &&
        stopModuleTimer &&
        tabValue < moduleLength
      ) {
        setTabValue((prevTabValue) => prevTabValue + 1);
  
        if (tabValue === moduleLength - 1) {
          setStopTimer(true);
        }
      }
    setQuestionBasedOnRecordsPerPage();
    window.addEventListener("resize", handleResize);
    return async () => {
      if (examinationType === "agc") {
        await setScreenForAGC();
        window.removeEventListener("resize", handleResize);
      } else if (examinationType === "prodigy") {
        await setScreenForProdigy();
        window.removeEventListener("resize", handleResize);
      } else if (examinationType === "practice") {
        await setScreenForProdigy();
        window.removeEventListener("resize", handleResize);
      }
    };
  }, [
    totalNumberOfPages,
    page,
    navigationButtonSize,
    columnSize,
    recordsPerPage,
    smallScreen,
    largeScreen,
    tabScreen,
    stopModuleTimer,
  ]);

  const handleChange = (event, value) => {
    let pageNo = value - 1;
    setPage(pageNo);
  };

  function handleResize() {}

  function setScreenForAGC() {
    if (smallScreen) {
      setRecordsPerPage(1);
      setTotalNumberOfPages(Math.round(questions.length / 1));
      setNavigationButtonSize("small");
      setColumnSize(11);
    } else {
      setRecordsPerPage(4);
      let noOfPages = Math.ceil(questions.length / 4);
      setTotalNumberOfPages(noOfPages);
      if (page >= noOfPages) {
        setPage(noOfPages - 1);
      }
      setNavigationButtonSize("large");
      setColumnSize(3);
    }
  }

  function setScreenForProdigy() {
    // if (smallScreen) {
    setRecordsPerPage(1);
    setTotalNumberOfPages(Math.round(questions.length / 1));
    setNavigationButtonSize("large");
    setColumnSize(4);

    // }
    // else {
    //     setRecordsPerPage(4);
    //     let noOfPages = Math.ceil(questions.length / 4);
    //     setTotalNumberOfPages(noOfPages);
    //     if (page >= noOfPages) {
    //         setPage(noOfPages - 1);
    //     }
    //     setNavigationButtonSize('large');
    //     setColumnSize(3);
    // }
  }

  async function setQuestionBasedOnRecordsPerPage() {
    //PAGINATION FOR MODULE 1
    if (page === 0 && tabValue === 0) {
      setDisableBackNavigateButtonStatus(true);
    } else if (examinationType === "prodigy" && page === 0) {
      setDisableBackNavigateButtonStatus(true);
    } else {
      setDisableBackNavigateButtonStatus(false);
    }
    if (
      tabValue === moduleLength - 1 &&
      page === totalNumberOfPages - 1 &&
      examinationType === "agc"
    ) {
      setDisableForwardNavigateButtonStatus(true);
    } else if (
      page === totalNumberOfPages - 1 &&
      examinationType === "prodigy"
    ) {
      setDisableForwardNavigateButtonStatus(true);
    } else if (
        page === totalNumberOfPages - 1 &&
        examinationType === "practice"
      ) {
        setDisableForwardNavigateButtonStatus(true);
      } else {
      setDisableForwardNavigateButtonStatus(false);
    }
    await setQuestionsToBeDisplayed(
      questions.slice(page * recordsPerPage, (page + 1) * recordsPerPage)
    );

    questionsToBeDisplayed.map((question, index) => {});
  }

  return (
    <React.Fragment>
      {examinationType === "agc" && (
        <div>
          <Grid container justify="center" className={classes.root}>
            <Grid item container spacing={1} xs={12} md={12} justify="center">
              <Grid
                item
                container
                xs={2}
                md={2}
                justify="center"
                alignItems="center"
              >
                <Tooltip title="Previous" aria-label="previous">
                  <Fab
                    ariaLabel="add"
                    size={navigationButtonSize}
                    className={classes.icon}
                    onClick={() => {
                      if (page === 0 && tabValue !== 0) {
                        setPage(totalNumberOfPages);
                        setTabValue((prevTabValue) => prevTabValue - 1);
                      }

                      setPage((prevPage) => prevPage - 1);
                    }}
                    disabled={disableBackNavigateButtonStatus}
                  >
                    <ChevronLeftIcon fontSize={navigationButtonSize} />
                  </Fab>
                </Tooltip>
              </Grid>

              <Grid
                container
                justify="center"
                spacing={1}
                xs={8}
                md={8}
                style={{ marginTop: "2%" }}
              >
                {questionsToBeDisplayed.map((question, index) => (
                  <Grid
                    item
                    md={columnSize}
                    xs={columnSize}
                    style={{ justifyContent: "center", minWidth: "168px" }}
                  >
                    <Question2
                      question={question}
                      recordsPerPage={recordsPerPage}
                      index={index}
                      page={page}
                      buttonSize={navigationButtonSize}
                      setAnsweredQuestion={setAnsweredQuestion}
                      examObject={examObject}
                      answers={answers}
                      setAnswers={setAnswers}
                      totalNoOfQueInPreSec={totalNoOfQueInPreSec}
                      examinationType={examinationType}
                      setPage={setPage}
                      totalNumberOfPages={totalNumberOfPages}
                      moduleId={moduleId}
                    />
                  </Grid>
                ))}
              </Grid>

              <Grid
                item
                container
                xs={2}
                md={2}
                justify="center"
                alignItems="center"
              >
                <Tooltip title="Next" aria-label="next">
                  <Fab
                    elevation={4}
                    ariaLabel="add"
                    size={navigationButtonSize}
                    className={classes.icon}
                    onClick={() => {
                      if (page === totalNumberOfPages - 1) {
                        setTabValue((prevTabValue) => prevTabValue + 1);
                      }

                      setPage((prevPage) => prevPage + 1);
                    }}
                    disabled={disableForwardNavigateButtonStatus}
                  >
                    <ChevronRightIcon fontSize={navigationButtonSize} />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="center"
            style={{ marginTop: "15px" }}
            xs={12}
            md={12}
          >
            <Pagination
              count={totalNumberOfPages}
              page={page + 1}
              size={navigationButtonSize}
              onChange={handleChange}
              variant="text"
              color="secondary"
            ></Pagination>
          </Grid>
        </div>
      )}
      {examinationType === "prodigy" && (
        <div>
          <div style={{ textAlign: "center" }}>
            {" "}
            <span style={{ fontWeight: "bold", textAlign: "center" }}>
              {" "}
              Time Left For {moduleName} -{" "}
              <TimerComponent
                allotedTime={moduleAllotedTime}
                setStopTimer={setModuleStopTimer}
                setSyncTimer={setSyncTimer}
                examinationType={examinationType}
                timeType={"moduleTime"}
                syncTimer={syncTimer}
                totalTimeSync={totalTimeSync}
                setTotalTimeSync={setTotalTimeSync}
              ></TimerComponent>{" "}
            </span>{" "}
          </div>

          <Grid container justify="center" className={classes.root}>
            <Grid item container spacing={1} xs={12} md={12} justify="center">
              <Grid
                item
                container
                xs={2}
                md={2}
                justify="center"
                alignItems="center"
              >
                <Tooltip title="Previous" aria-label="previous">
                  <Fab
                    ariaLabel="add"
                    size={navigationButtonSize}
                    className={classes.icon}
                    onClick={() => {
                      setPage((prevPage) => prevPage - 1);
                    }}
                    disabled={disableBackNavigateButtonStatus}
                  >
                    <ChevronLeftIcon fontSize={navigationButtonSize} />
                  </Fab>
                </Tooltip>
              </Grid>

              <Grid
                container
                justify="center"
                spacing={1}
                xs={8}
                md={8}
                style={{ marginTop: "4px" }}
              >
                {questionsToBeDisplayed.map((question, index) => (
                  <Grid
                    item
                    md={columnSize}
                    xs={columnSize}
                    style={{ justifyContent: "center", minWidth: "168px" }}
                  >
                    {syncTimer && (
                      <Question2
                        question={question}
                        recordsPerPage={recordsPerPage}
                        index={index}
                        page={page}
                        buttonSize={navigationButtonSize}
                        setAnsweredQuestion={setAnsweredQuestion}
                        examObject={examObject}
                        answers={answers}
                        setAnswers={setAnswers}
                        totalNoOfQueInPreSec={totalNoOfQueInPreSec}
                        examinationType={examinationType}
                        setPage={setPage}
                        totalNumberOfPages={totalNumberOfPages}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>

              <Grid
                item
                container
                xs={2}
                md={2}
                justify="center"
                alignItems="center"
              >
                <Tooltip title="Next" aria-label="next">
                  <Fab
                    elevation={4}
                    ariaLabel="add"
                    size={navigationButtonSize}
                    className={classes.icon}
                    onClick={() => {
                      setPage((prevPage) => prevPage + 1);
                    }}
                    disabled={disableForwardNavigateButtonStatus}
                  >
                    <ChevronRightIcon fontSize={navigationButtonSize} />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="center"
            style={{ marginTop: "15px" }}
            xs={12}
            md={12}
          >
            <Pagination
              count={totalNumberOfPages}
              page={page + 1}
              size={navigationButtonSize}
              onChange={handleChange}
              variant="text"
              color="secondary"
            ></Pagination>
          </Grid>
        </div>
      )}{" "}
      {examinationType === "practice" && (
        <div>
          <div style={{ textAlign: "center" }}>
            {" "}
            <span style={{ fontWeight: "bold", textAlign: "center" }}>
              {" "}
              Time Left For {moduleName} -{" "}
              <TimerComponent
                allotedTime={moduleAllotedTime}
                setStopTimer={setModuleStopTimer}
                setSyncTimer={setSyncTimer}
                examinationType={examinationType}
                timeType={"moduleTime"}
                syncTimer={syncTimer}
                totalTimeSync={totalTimeSync}
                setTotalTimeSync={setTotalTimeSync}
              ></TimerComponent>{" "}
            </span>{" "}
          </div>

          <Grid container justify="center" className={classes.root}>
            <Grid item container spacing={1} xs={12} md={12} lg={12} justify="center">
              <Grid item  container xs={2} md={2} lg={4} justifyContent="center" alignItems="center">
                <Tooltip title="Previous" aria-label="previous">
                  <Fab
                    ariaLabel="add"
                    size={navigationButtonSize}
                    className={smallScreen ? classes.icon :classes.leftIcon1}
                    onClick={() => {
                      setPage((prevPage) => prevPage - 1);
                    }}
                    disabled={disableBackNavigateButtonStatus}
                  >
                    <ChevronLeftIcon fontSize={navigationButtonSize} />
                  </Fab>
                </Tooltip>
              </Grid>

              <Grid container justify="center" spacing={1} xs={8} md={8} lg={4} style={{ marginTop: "4px" }} >
                {questionsToBeDisplayed.map((question, index) => (
                  <Grid
                    item
                    md={columnSize}
                    xs={columnSize}
                    style={{ justifyContent: "center", minWidth: "168px" }}
                  >
                    {syncTimer &&(
                      <Question2
                        question={question}
                        recordsPerPage={recordsPerPage}
                        index={index}
                        page={page}
                        buttonSize={navigationButtonSize}
                        setAnsweredQuestion={setAnsweredQuestion}
                        examObject={examObject}
                        answers={answers}
                        setAnswers={setAnswers}
                        totalNoOfQueInPreSec={totalNoOfQueInPreSec}
                        examinationType={examinationType}
                        setPage={setPage}
                        totalNumberOfPages={totalNumberOfPages}
                        moduleId={moduleId}
                        moduleName={moduleName}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>

              <Grid item container xs={2} md={2} lg={4} justifyContent="center" alignItems="center">
                <Tooltip title="Next" aria-label="next">
                  <Fab
                    elevation={4}
                    ariaLabel="add"
                    size={navigationButtonSize}
                    className={smallScreen ? classes.icon :classes.rightIcon2}
                    onClick={() => {
                      setPage((prevPage) => prevPage + 1);
                    }}
                    disabled={disableForwardNavigateButtonStatus}
                  >
                    <ChevronRightIcon fontSize={navigationButtonSize} />
                  </Fab>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justify="center"
            style={{ marginTop: "12px" }}
            xs={12}
            md={12}
          >
            <Pagination
              count={totalNumberOfPages}
              page={page + 1}
              size={navigationButtonSize}
              onChange={handleChange}
              variant="text"
              color="secondary"
            ></Pagination>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
}
