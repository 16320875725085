import React from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Hidden,
  Button,
  useMediaQuery,
  useTheme,
  Typography,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import EmailTwoToneIcon from "@material-ui/icons/EmailTwoTone";
import AboutAGC from "./AboutAGC";
import AGCWorkFlow from "./AGCWorkFlow";
import PrizeInfo from "./PrizeInfo";
import PurposeOfAGC from "./PurposeOfAGC";
import Testimonials from "./Testimonials";
import AboutSIP from "./AboutSIP";
import GlimpseAtAGC from "./GlimpseAtAGC";
//import logo from "./images/logo20211.svg";
//import homePage from "./images/BG_Page_01.svg";
import SIPFooter from "./SIPFooter";
import Registration from "../Registration";
import SampleQuestionPaper from "./SampleQuestionPapers";

import plusLeft from "./images/plusSignLeft.svg";
import plusRight from "./images/plusSignRight.svg";
import seasonLogo from "./images/seasonLogo.svg";
import SIPAcademyLogo from "./images/SIPAcademyLogo.svg"
import mobileBG from "./images/Mobile_BG_main.svg"
import MediaPartner from "./MediaPartner";
//import backgroundImage2022 from  "./images/BG_Page_2022.png"
import { browserName, browserVersion } from "react-device-detect";
import mobileBG2022 from "./images/Arithmetic_Genius_Contest_Mobile_BG.jpg"
import agc2022logo from "./images/SIP_AGC_Logo_2022.png"
import backgroundImage2022 from  "./images/BG_2022_AGC.jpg";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    wrap: "nowrap",
    height:"100%",
    width:"100%",
    overflowX:"hidden",
    flexWrap:"wrap"
    
  },
  topGrid: {
    color: "white",
  },
 
  paper: {
    textAlign: "center",
    width: "120%",
    maxHeight: "auto",
    backgroundImage: `url(${backgroundImage2022})`,
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover",
  },
 
  content: {
    fontWeight: "bold",
    color: "white",
    fontSize: theme.spacing(3),
    justify: "right",
    textAlign: "center",
    backgroundImage: `url(${backgroundImage2022})`,

  },
 
  mobilePaper: {
    width: "100%",
    height: "auto",
    backgroundImage: `url(${mobileBG2022})`,
    overflow:"hidden",
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover",
    
  },
  registrationPageLink: {
    textDecoration: "none",
  },

  horizontalLine1: {
    width: "70%",
    borderBottom: "2px solid white",
    textAlign: "center",
    marginLeft: "16%",
    height:theme.spacing(3)
  },
  horizontalLine1SmallScreen: {
    width: "100%",
    borderBottom: "2px solid white",
    textAlign: "center",
    height:theme.spacing(2),
  },
  horizontalLine2: {
    backgroundColor: "#F05A28",
    padding: "0 5px",
    fontWeight: "bold",
    color: "white",
    fontSize:theme.spacing(4)
  },
  workShop: {
    backgroundColor: "#F6921E",
    borderRadius: "25px",
    padding: "5px 6px",
    textAlign: "center",
    color: "white",
    width: "10%",
  },
  text: {
    color: "white",
  },
  line:{
    height:"1px",
    borderWidth:"0px",
    color:"white",
    backgroundColor:"white",
    width:"70%",
    alignItems:"center",
    textAlign:"center"
  },
  button: {
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    display: "inline-block",
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    whiteSpace: "pre-line",
    display:"inline-block",
    fontSize:theme.spacing(2),
    padding:theme.spacing(1)
  },
  imageStyle:{
    position:"relative",
    left:"0px",
    bottom:"0px"
  }
}));

const ContestLandingPage = () => {
  const classes = useStyles();
  let theme = useTheme();
  const gmail = "sipinfo@sipacademyindia.com";
  const register = "Register Now";
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const title="Sample Question Paper";
  
  
  const [width, setWidth]   = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
}
React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
}, []);



  function LandingPageContent() {
    if(smallScreen){
    return (
      <React.Fragment>
        <Grid item container xs={12} sm={12} xl={12} md={12} justify="center" alignItems="flex-start">
          <Grid item sm={1} xs={1} md={1}>
            <img src={SIPAcademyLogo} style={{height:"115%",width:"300%"}}  />
          </Grid>
          <Grid item sm={1} xs={1} md={1}>
           
          </Grid>
          <Grid item container sm={10} xs={10} md={10} alignItems="flex-start">
          <div><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
          <img src={agc2022logo} style={{height:"20%",width:"60%"}}  />
          </Grid>
          <Grid item sm={1} xs={1} md={1}>
          </Grid>
        </Grid>

        <br />
        <br />

        <Grid item container sm={12} xs={12}  md={12} justify="center" alignItems="center">

          <Grid item sm={1} xs={1} md={1}></Grid>
          <Grid item sm={10} xs={10} md={10} >
            <Grid item container sm={12} xs={12} style={{textAlign:"center"}} >
            <Grid item sm={12} xs={12} md={12}><Typography variant={smallScreen?"h5":"h4"} style={{color:"white",fontWeight:"bold",textAlign:"center"}}></Typography></Grid>
            <Grid item sm={12} xs={12} md={12}><Typography variant={smallScreen?"h5":"h4"} style={{color:"white",fontWeight:"bold",textAlign:"center"}}></Typography></Grid>
            <br />
            <br />
            <br />

             <Grid item sm={12} xs={12}><Typography  style={{color:"yellow",fontWeight:"bold",fontSize:"15px",alignItems:"center"}}>DISCOVERING THE</Typography></Grid>
            <Grid item sm={12} xs={12}><Typography style={{color:"yellow",fontWeight:"bold",fontSize:"15px"}}>GENIUS IN EVERY CHILD</Typography></Grid>
            <br />
            <br />  

            <Grid item xs={12} sm={12}>
            <div >
                  <span  style={{fontSize:theme.spacing(5)}} ></span>
                </div>
            </Grid>

            <br />
            <br />  
            <Grid item xs={12} sm={12}><Typography  style={{color:"white",fontSize:"14px",textAlign:"center"}}>Total   <span style={{fontWeight:"bold",fontSize:theme.spacing(2)}}> Rs.20 LAKHS </span>worth of Cash prizes for the winners.</Typography></Grid>
            <br />
            <br />
            <br />
            <br />
           
            <Grid item xs={12} sm={12}> </Grid>
            <Grid item xs={12} sm={12} style={{color:"white",fontSize:"14px"}}>  <span style={{fontWeight:"bold",fontSize:theme.spacing(2)}}> PARTICIPATION IS FREE </span></Grid>
            <Grid item xs={12} sm={12} style={{color:"white",fontSize:"14px"}}>  <span style={{fontSize:theme.spacing(2)}}> For Classes 1 to 5
            <br />
            <br />
            <Grid  container  style={{marginTop: theme.spacing(1)}} justify="center" alignItems="flex-start">
            <Grid item md={6} xs={6} sm={6} lg={6}>
                <a
                  href="#sampleQuestionPaper"
                  className={classes.registrationPageLink}
                >
                  <Button
                    style={{
                      width:"100%",
                      fontSize:smallScreen?"10px":"14px"
                    }}
                    variant="contained"
                    className={classes.button}
                 
                  >
                    {title}
                  </Button>
                </a>               
              </Grid>
              </Grid>
            <br />
           
 </span></Grid>
 <Grid  container  style={{marginTop: theme.spacing(1)}} justify="center" alignItems="flex-start">
                <Grid item md={3} xs={4} sm={4} lg={3}></Grid>
             

              <Grid item md={6} xs={6} sm={6} lg={6}>
              
              </Grid>
             
              <Grid item md={3} xs={4} sm={4} lg={3}></Grid>

              </Grid>
            <br />
            <br />
            <Grid item container xs={12} sm={12}>
                <Grid item xs={7} sm={7} ></Grid>
                <Grid item xs={5} sm={5} ></Grid>
              </Grid> 
            <br />
            <br />
              <Grid item container  style={{marginTop: theme.spacing(3)}}>
              <Grid item md={3} xs={2} sm={2} lg={3}></Grid>
       
             
              
            
              
              <Grid item md={3} xs={2} sm={2} lg={3}></Grid>
       
              </Grid>
            <br />
            <br />
            <br />
           
            <br />
            <br />
            <br />
           <div>
        
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
            <br>
            </br>
           
           </div>

            </Grid>
            
          </Grid>
       
        </Grid>
      
        
      </React.Fragment>
    );
    }else{
      return(
        <React.Fragment >
           <Grid  container justifyContent="space-evenly" alignItems="flex-start">
          <Grid item md={1} lg={1} >
          <img src={SIPAcademyLogo} alt="abacusLogo"  style={{height:"130%",width:"130%", paddingLeft:theme.spacing(1),backgroundSize:"cover"}} />
          </Grid>  
     
          <Grid item  md={12} lg={12} sm={12} xs={12}>
         {/* <div>  <img src={agc2022logo} style={{height:"20%",width:"20%"}}  /></div>*/}
          </Grid>
          <Grid item  md={1} lg={1} >
        
          </Grid>
          {/*<Grid  item container
  direction="column"
  justifyContent="center"
  alignItems="center">
      <Grid item item md={4} lg={4} sm={4} xs={4}>
        </Grid>
          <Grid item item md={8} lg={8} sm={8} xs={8}>
    { <div>  <img src={agc2022logo} style={{height:"30%",width:"25%"}}  /></div>}
            </Grid>
          
      </Grid>
      
      */}
        </Grid>
         
         <Grid  container direction="row" justifyContent="center" alignItems="center">
          
           <Grid item  md={12} lg={12} >
             {/* <img src={logo} style={{height:"80vh", width:"35vw"}}  alt="logo" /> */}
             <LandingPageText></LandingPageText>
           </Grid>
         {/* <Grid item md={1} lg={1} ></Grid><Grid item md={3} lg={3}>
             
             </Grid>
             <Grid item md={1} lg={1}></Grid>
             
    */}  </Grid>
         <br />
         <br />
              <br />
            
            {browserName === "Firefox" ?   <br /> : ""}  
              
        </React.Fragment>
      )
    }
  }

  function LandingPageText(){
    return(
      <React.Fragment>
        <Grid  container justifyContent="center" alignItems="center" style={{marginTop:-theme.spacing(6)}} >
               <Grid item md={12} lg={12} xl={12} ><Typography variant={smallScreen?"h5":"h4"} style={{color:"white",fontWeight:"bold",textAlign:"center"}}></Typography></Grid>
               <Grid item md={12} lg={12} xl={12}><Typography variant={smallScreen?"h5":"h4"} style={{color:"white",fontWeight:"bold",textAlign:"center"}}></Typography></Grid>
              <br />
              <br />
              <br />
              <br />
              <br />
              <Grid item md={12} lg={12} xl={12}><Typography variant={smallScreen?"h6":"h5"} style={{color:"yellow"}}></Typography></Grid>
              {
               
                browserName === "Firefox" ? 
           
               <div> 
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                 <br></br>
                
                 
               </div> : ""
              }
              {smallScreen ? "" :<div>  <img src={agc2022logo} style={{height:"10%",width:"15%"}}  /></div>}
              <Grid item md={12} lg={12} xl={12}><Typography variant={smallScreen?"h6":"h5"} style={{color:"yellow",fontWeight:"bold"}}>DISCOVERING THE</Typography></Grid>
              <Grid item md={12} lg={12} xl={12}><Typography variant={smallScreen?"h6":"h5"} style={{color:"yellow",fontWeight:"bold"}}>GENIUS IN EVERY CHILD</Typography></Grid>
              <br />
            
              <Grid item md={12} lg={12} xl={12}>
              
         
              </Grid>
              <br />
              <Grid item md={12} lg={12} xl={12}><Typography  style={{color:"white",fontSize:"20px"}}>Total <span style={{fontWeight:"bold",fontSize:theme.spacing(4)}}>    20 Lakhs</span> worth of Cash prizes for the winners.</Typography></Grid>
               <br />
             {/*<Grid item md={12} lg={12} xl={12}> <hr className={classes.line} /></Grid>*/}
           
              <Grid item md={12} lg={12} xl={12} style={{color:"white",fontSize:"20px"}}><span style={{fontWeight:"bold",fontSize:theme.spacing(4)}}>PARTICIPATION IS FREE </span> </Grid>
              <Grid item md={12} lg={12} xl={12} style={{color:"white",fontSize:"10px"}}><span style={{fontWeight:"bold",fontSize:theme.spacing(2)}}>For Classes 1 to 5
              <Grid  container  style={{marginTop: theme.spacing(1)}} justify="center" alignItems="flex-start">
                <Grid item md={3} xs={4} sm={4} lg={3}></Grid>
              <Grid item md={1} xs={1}  sm={1} lg={1}  >
                {/*<img
                  src={plusLeft}
                  alt="plus"
                  height="60%"
                  width="20%" 
                  style={{marginTop:theme.spacing(1)}}            
            />*/}
              </Grid>

              <Grid item md={4} xs={2} sm={2} lg={4}>
                <a
                  href="#sampleQuestionPaper"
                  className={classes.registrationPageLink}
                >
                  <Button
                    style={{
                      width:"50%",
                      fontSize:smallScreen?"10px":"14px"
                    }}
                    variant="contained"
                    className={classes.button}
                    
                  >
                    {title}
                  </Button>
                </a>               
              </Grid>
              <Grid item md={1} sm={1} lg={1} xs={1} >
                {/*<img
                  src={plusRight}
                  alt="plus"
                  height="60%"
                  width="20%"
                  style={{marginTop:theme.spacing(1)}} 
                  />*/}
              </Grid>
              <Grid item md={3} xs={4} sm={4} lg={3}></Grid>

              </Grid>
              {
                browserName == "Firefox" ? 
           
               <div> 
                  <br></br>   
               </div> : ""
              }
 </span> </Grid>
              <br />
              <Grid item container md={12} lg={12}>
                <Grid item md={2}lg={2}></Grid>
                <Grid item md={5} lg={5} ></Grid>
                <Grid item md={3} lg={3}  style={{marginLeft:theme.spacing(1)}}></Grid>
                <Grid item md={2} lg={2}></Grid>
              </Grid>
              <br />
              <br />
             
              <Grid  container  style={{marginTop: theme.spacing(1)}} justify="center" alignItems="flex-start">
                <Grid item md={3} xs={4} sm={4} lg={3}></Grid>
          

            
            
              <Grid item md={3} xs={4} sm={4} lg={3}></Grid>
            
              </Grid>
     
              </Grid>
            
      </React.Fragment>
    )
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row" fixed
        // spacing={2}
        justify="center"
        alignItems="center"
      >
        <Paper className={smallScreen ? classes.mobilePaper : classes.paper} >
          {/* <Hidden smDown> */}
            <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
              <Toolbar>
                <Grid item md={4} sm={12} xs={12}>
                  <div style={{ display: "inline" }}>
                    <Typography className={classes.topGrid}>
                      <EmailTwoToneIcon
                        fontSize="small"
                        style={{ margin: "10px 0 -5px 10px", color: "white" }}
                      />
                      &nbsp;{gmail}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} md={2} sm={12}></Grid>
                <Grid item xs={12} md={2} sm={12}></Grid>
              </Toolbar>
            </AppBar>
          {/* </Hidden> */}
        
         <LandingPageContent></LandingPageContent>
        </Paper>
      </Grid>
      <AboutAGC style={{ textAlign: "center" }}></AboutAGC>
      <AGCWorkFlow style={{ textAlign: "center" }}></AGCWorkFlow>
      <PrizeInfo style={{ textAlign: "center" }}></PrizeInfo>
      <div id="sampleQuestionPaper">
      <SampleQuestionPaper></SampleQuestionPaper>
      </div>
      <SIPFooter smallScreen={smallScreen} /> 
    </div>
  );
};
export default ContestLandingPage;
