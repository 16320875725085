import card3 from "../Dashboard/card_3.png";
import card3mobile from "../Dashboard/card_3_mobile.jpg"
import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme,useMediaQuery } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },image: {
      height: "100vh",
       backgroundImage: `url(${card3})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },mobileImage: {
        height: "100vh",
         backgroundImage: `url(${card3mobile})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }, text: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#43413e",
      textAlign:"center",
      margin: theme.spacing(-5, 15, 2),
    },
  }));
 

 function ValidityExpiryScreen(){
    const classes = useStyles();
    let theme = useTheme();
    const history = useHistory();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    setTimeout(() =>   history.push("/practice/student/login"), 5000);
  }, []);
 
  return(
    <React.Fragment>
          <Grid container component="main" className={classes.root}>
      <Grid item xs={12} sm={12} md={12}  className={smallScreen ? classes.mobileImage :  classes.image}   alignItems="center">
        
      </Grid>
    </Grid>
  
    </React.Fragment>
  );
}
export default ValidityExpiryScreen;
