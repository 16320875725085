import React, { useRef } from "react";
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  FormControl,
  Snackbar,
  CssBaseline,
  TextField,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Alert from "@material-ui/lab/Alert";
import { Autocomplete } from "@material-ui/lab";
import { saveAs } from "file-saver";
import LCLApprovalList from "./LCLApprovalList";

class CSODashboard extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      roleId: props.roleId,
      openEdit: false,
      openConfirmationDialog: false,
      transactionObj: {},
      pageNo: 0,
      recordsPerPage: 100,
      sortKey: "lastUpdatedDt",
      sortDir: "desc",
      count: 0,
      BASE_URL: `${process.env.REACT_APP_API_URL}`,
      noOfQuestions: [],
      noOfQuestion: 0,
      ApprovedStatusList: [],
      branchList: [],
      loginIdList:[],
      LCLApprovedValues: false,
      customColumnValueForLCLNotApproved: "Not Approved",
      customColumnValueForLCLApproved: "Approved",
      confirmationType: "",
      data: [["loading..."]],
      columns: [
//0
        {
          name: "name",
          label: "LCL - Name",
          options: {
            filter: props.roleId === 1 ? false : true,
            viewColumns: props.roleId === 1 ? false : true,
            display: props.roleId === 1 ? false : true,
            filterType: "custom",
            customFilterListOptions: {
              render: (v) =>
                v.map((l) => {
                  return `${l.name} - ${l.code}`;
                }),
              update: (filterList, filterPos, index) => {
                filterList[index].splice(filterPos, 1);
                return filterList;
              },
            },
            filterOptions: {
              logic: (location, filters, row) => {
                if (filters.length) return !filters.includes(location);

                return false;
              },
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      freeSolo
                      fullWidth
                      multiple
                      id="dropdown-for-branch"
                      disableClearable
                      onOpen={async () => {
                        let options = await this.loadBranchData();
                        this.setState({
                          branchList: options.data.content,
                        });
                      }}
                      options={this.state.branchList}
                      value={filterList[index]}
                      onChange={(event, newValue) => {
                        if (
                          filterList[index].length >= 1 &&
                          newValue.length > 0
                        ) {
                          newValue.splice(0, 1);
                          filterList[index].splice(
                            0,
                            1,
                            newValue.splice(0, 1)[0]
                          );
                        } else {
                          filterList[index] = newValue;
                        }
                        onChange(filterList[index], index, column);
                      }}
                      loadingText="Loading..."
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }

                        return `${option.name} - ${option.code}`;
                      }}
                      getOptionSelected={(option, value) =>
                        option.branchId === value.branchId
                      }
                      renderOption={(option) => {
                        return `${option.name} - ${option.code}`;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Branch"
                          margin="normal"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                  </FormControl>
                );
              },
            },
          },
        },
//1
        {
          name: "userName",
          label: "AH/SH/RM Name",
          options: {
            filter: false,
            viewColumns: props.roleId === 1 ? true : false,
            display: props.roleId === 1 ? true : false,
          },
        },
//2
        {
          name: "loginId",
          label: "Login Id",
          options: {
            filter: props.roleId === 1 ? true : false,
            viewColumns: props.roleId === 1 ? true : false,
            display: props.roleId === 1 ? true : false,
            filterType: "custom",
            customFilterListOptions: {
              render: (v) => v.map((l) => l.loginId),
              update: (filterList, filterPos, index) => {
                filterList[index].splice(filterPos, 1);
                return filterList;
              },
            },
            filterOptions: {
              logic: (location, filters, row) => {
                if (filters.length) return !filters.includes(location);
                return false;
              },
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      freeSolo
                      multiple
                      id="dropdown-for-loginId"
                      disableClearable
                      onOpen={async () => {
                        let optionValues = await this.loadUserData();
                        this.setState({
                          loginIdList: optionValues.data.content,
                        });
                      }}
                      value={filterList[index]}
                      onChange={(event, newValue) => {
                        if (
                          filterList[index].length >= 1 &&
                          newValue.length > 0
                        ) {
                          newValue.splice(0, 1);
                          filterList[index].splice(
                            0,
                            1,
                            newValue.splice(0, 1)[0]
                          );
                        } else {
                          filterList[index] = newValue;
                        }
                        onChange(filterList[index], index, column);
                      }}
                      loadingText="Loading..."
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }

                        return option.loginId;
                      }}
                      getOptionSelected={(option, value) =>
                        option.userId === value.userId
                      }
                      options={this.state.loginIdList}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Login Id or Name"
                          margin="normal"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                  </FormControl>
                );
              },
            },

          },
        },

        // {
        //   name: "roles.roleId",
        //   label: "Roles",
        //   options: {
        //     filter: false,
        //     viewColumns: props.roleId === 1 ? true : false,
        //     display: props.roleId === 1 ? true : false,
        //     customBodyRender: (value, tableMeta) => {
        //       console.log('tableMeta:',tableMeta)
        //       return (

        //         <div>
        //           <span style={{ display: value ? 3 : "none" }}>

        //             {
        //               "Area Head"

        //             }
        //           </span>
        //           <span style={{ display: value ? 4 : "none" }}>

        //             {
        //               "State Head"
        //             }
        //           </span>
        //           <span style={{ display: value ? 5 : "none" }}>

        //             {
        //               "Regional Manager"
        //             }
        //           </span>


        //         </div>
        //       );
        //       // }
        //     },
        //   },
        // },
//3
        {
          name: "code",
          label: "LCL - Code",
          options: {
            filter: false,
            viewColumns: props.roleId === 1 ? false : true,
            display: props.roleId === 1 ? false : true,
          },
        },
//4
        {
          name: "noOfParticipants",
          label: "No Of Participants",
          options: {
            filter: false,
            viewColumns: props.roleId === 1 ? false : true,
            display: props.roleId === 1 ? false : true,
            sort: false,
          },
        },
//5
        {
          name: "approved",
          label: "Approval Status",
          options: {
            filter: props.roleId === 1 ? false : true,
            display: true,
            customBodyRender: (value, tableMeta) => {

              return (
                <div>
                  <span style={{ display: value ? "block" : "none" }}>

                    {
                      this.state.customColumnValueForLCLApproved

                    }
                  </span>
                  <span style={{ display: value ? "none" : "block" }}>

                    {
                      this.state.customColumnValueForLCLNotApproved
                    }
                  </span>
                </div>
              );
              // }
            },
            filterType: "custom",
            customFilterListOptions: {
              render: (v) => v.map((l) => l.name),
              update: (filterList, filterPos, index) => {
                filterList[index].splice(filterPos, 1);
                return filterList;
              },
            },
            filterOptions: {
              logic: (location, filters, row) => {
                if (filters.length) return !filters.includes(location);
                return false;
              },
              display: (filterList, onChange, index, column) => {
                let optionsForApprovalFlag = [
                  {
                    name: this.state.customColumnValueForLCLApproved,
                    value: true,
                  },
                  {
                    name: this.state
                      .customColumnValueForLCLNotApproved,
                    value: false,
                  },
                ];
                return (
                  <FormControl fullWidth>
                    <Autocomplete
                      freeSolo
                      fullWidth
                      multiple
                      id="dropdown-for-approval"
                      disableClearable
                      value={filterList[index]}
                      onChange={(event, newValue) => {
                        if (
                          filterList[index].length >= 1 &&
                          newValue.length > 0
                        ) {
                          newValue.splice(0, 1);
                          filterList[index].splice(
                            0,
                            1,
                            newValue.splice(0, 1)[0]
                          );
                        } else {
                          filterList[index] = newValue;
                        }
                        onChange(filterList[index], index, column);
                      }}
                      loadingText="Loading..."
                      options={optionsForApprovalFlag}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }

                        return option.name;
                      }}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Approval Status"
                          margin="normal"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                  </FormControl>
                );
              },
            },
          },
        },
//6
        {
          name: "lastUpdatedDt",
          label: "Approved Date",
          options: {
            filter: false,
            sort: false,
            display: true,
            customBodyRender: (value, tableMeta) => {

              return (
                <div>
                  <span style={{ display: tableMeta.rowData[5] ? "block" : "none" }}>

                    {
                      value
                    }
                  </span>
                </div>
              )
            }
          },
        },

        {
          name: "lastUpdatedBy",
          label: "Approved By",
          options: {
            filter: false,
            sort: false,
            display: true,
            customBodyRender: (value, tableMeta) => {

              return (
                <div>
                  <span style={{ display: tableMeta.rowData[5] ? "block" : "none" }}>

                    {
                      value
                    }
                  </span>
                </div>
              )
            }
          },
        },

        {
          name: "createdDt",
          label: "Created Date",
          options: {
            filter: false,
            display: false,
          },
        },

        {
          name: "createdBy",
          label: "Created By",
          options: {
            filter: false,
            display: false,
          },
        },

      ],
      isLoading: false,
      body: {
        name: "",
        branchId: 0,
        address: {
          countryId: 1,
          stateId: 0,
          cityId: 0,
        },
        approved: ""
      },
      userBody: {
        userName: "",
        branch: {
          branchId: 1,
          code: "",
          name: ""
        },
        organization: {
          organizationId: 1
        },
        loginId: "",

        // approved:""
      },

      open: false,
      anchorRef: React.createRef(),
      selectedIndex: 0,
      buttonOptions: ["Approved"],
      openActiveTest: false,
      selectedRowsIndex: [],
      selectedLCLFromTable: [],
      openAlert: false,
      openLCLDetails: false,
      branchId: JSON.parse(localStorage.getItem("sip-user")).branch.branchId,
      orgId: JSON.parse(localStorage.getItem("sip-user")).organization
        .organizationId,
      userApproved: JSON.parse(localStorage.getItem("sip-user")).approved,
      alertMessage: "",
      alertSeverity: "",
      currentDateAndTimeFromServer: "",
      errorMessageForCsvFileDownload: "Failed to download the File!",
      studentEditPermissionFlag: false,
      studentDeletePermission: false,
      reActivateTestPermission: false,
    };

    this.getData = this.getData.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.sort = this.sort.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.setSelectedRow = this.setSelectedRow.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.getCurrentDateAndTimeFromServer = this.getCurrentDateAndTimeFromServer.bind(
      this
    );
    // this.getStudentsList = this.getStudentsList.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.showDownloadPopUp = this.showDownloadPopUp.bind(this);
    this.getSelectedLCLList = this.getSelectedLCLList.bind(this);
    this.closeLCLDetails = this.closeLCLDetails.bind(this);

  }

  componentDidMount() {
    this.getData(
      this.state.pageNo,
      this.state.sortKey,
      this.state.sortDir,
      this.state.recordsPerPage
    );
    this.getCurrentDateAndTimeFromServer();
  }

  handleFilterSubmit = (applyFilters) => {
    let filterList = applyFilters();
    let selectedBranchNameFilterValue = "";
    let selectedBranchId = 0;
    let optionsForApprovalFlag = null;
    let selectedLoginId =""

    filterList[0].forEach((element) => {
      selectedBranchNameFilterValue = element.name;
      selectedBranchId = element.branchId;
    });
    filterList[2].forEach((element) => {
      selectedLoginId = element.loginId;
    });
    filterList[5].forEach((element) => {
      optionsForApprovalFlag = element.value;
    });

  this.state.roleId!==1 ?
    this.setState(
      {
        body: {
          name: selectedBranchNameFilterValue,
          branchId: selectedBranchId,
          address: {
            countryId: 1,
            stateId: 0,
            cityId: 0,
          },
          approved: optionsForApprovalFlag
        }
      },
      () =>
        this.getData(
          this.state.page,
          this.state.sortKey,
          this.state.sortDir,
          this.state.recordsPerPage
        )
    )
    :
    this.setState(
      {
        userBody: {
          userName: "",
          branch: {
            branchId: 1,
            code: "",
            name: ""
          },
          organization: {
            organizationId: 1
          },
          loginId: selectedLoginId
  
          // approved:""
        },
      },
      () =>
        this.getData(
          this.state.page,
          this.state.sortKey,
          this.state.sortDir,
          this.state.recordsPerPage
        )
    );
  };


  async getData(pageNo, sortKey, sortDir, recordsPerPage) {
    this.setState({ isLoading: true, });

    if (this.state.roleId === 1) {
      await axios.get(this.state.BASE_URL + "/organizations/1/branches/1/users", {

        params: {
          body: JSON.stringify(this.state.userBody),
          pageNo: pageNo !== undefined ? pageNo : "",
          sortKey: sortKey !== undefined ? sortKey : "",
          sortDir: sortDir !== undefined ? sortDir : "",
          recordsPerPage: recordsPerPage !== undefined ? recordsPerPage : "",
        },
        headers: {
          authorization:
            "Bearer " + localStorage.getItem("sip-authorization"),
        },
      }
      )
        .then((response) => {
          this.setState({
            data: response.data.content,
            count: response.data.totalElements,
          });
        });

    } else {
      await axios.get(this.state.BASE_URL + "/branches", {

        params: {
          body: JSON.stringify(this.state.body),
          pageNo: pageNo !== undefined ? pageNo : "",
          sortKey: sortKey !== undefined ? sortKey : "",
          sortDir: sortDir !== undefined ? sortDir : "",
          recordsPerPage: recordsPerPage !== undefined ? recordsPerPage : "",
        },
        headers: {
          authorization:
            "Bearer " + localStorage.getItem("sip-authorization"),
        },
      }
      )
        .then((response) => {
          this.setState({
            data: response.data.content,
            count: response.data.totalElements,
          });
        });
    }
    this.setState({ isLoading: false });

  }
  // async getStudentsList(searchedChildName, searchedSchoolName) {

  //   this.setState({
  //     childNameList: [],
  //     schoolNameList: [],
  //   });
  //   if (searchedChildName !== null && searchedChildName !== "") {
  //     this.setState({
  //       childNameLoading: true,
  //     });
  //   } else if (searchedSchoolName !== null && searchedSchoolName !== "") {
  //     this.setState({
  //       schoolNameLoading: true,
  //     });
  //   }
  //   await axios
  //     .get(
  //       this.state.BASE_URL +
  //       "/branches/" +
  //       this.state.branchId +
  //       "/SIPstudents",
  //       {
  //         params: {
  //           body: {
  //             childName: searchedChildName,
  //             branch: {
  //               name: "",
  //               branchId: 0,
  //             },
  //             schoolName: searchedSchoolName,
  //             classNumber: {
  //               classId: 0,
  //             },
  //             registrationCompleted: true,
  //             examStatus: "",
  //             sipAbacusStudent: "",
  //             examCompletedStatus: this.state.tabHeading === "practice" ? this.state.tabHeading : this.state.isExamCompleted,
  //             examination: {
  //               examinationId: this.state.examinationId,
  //           },
  //           examGroupId: this.state.examGroupId,
  //           },
  //           pageNo: 0,
  //           sortKey: "childName",
  //           sortDir: "asc",
  //           recordsPerPage: 100,
  //         },
  //         headers: {
  //           authorization:
  //             "Bearer " + localStorage.getItem("sip-authorization"),
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       this.setState({
  //         childNameList: response.data.content,
  //         schoolNameList: response.data.content,
  //       });
  //       if (searchedChildName !== null && searchedChildName !== "") {
  //         this.setState({
  //           childNameLoading: false,
  //         });
  //       } else if (searchedSchoolName !== null && searchedSchoolName !== "") {
  //         this.setState({
  //           schoolNameLoading: false,
  //         });
  //       }
  //     });
  // }


  changePage = (page, sortOrder, rowsPerPage) => {
    this.setState({
      isLoading: true,
      recordsPerPage: rowsPerPage,
      pageNo: page,
      sortKey:
        sortOrder.name === undefined ? this.state.sortKey : sortOrder.name,
      sortDir:
        sortOrder.direction === undefined
          ? this.state.sortDir
          : sortOrder.direction,
    });
    this.setSelectedRow([]);
    this.getData(
      page,
      sortOrder.name === undefined ? this.state.sortKey : sortOrder.name,
      sortOrder.direction === undefined
        ? this.state.sortDir
        : sortOrder.direction,
      rowsPerPage
    );
  };

  changeRecordPerPage = (page, sortOrder, rowsPerPage) => {
    this.setState({ recordsPerPage: rowsPerPage });
    this.getData(page, "", "", rowsPerPage);
  };

  sort = (page, sortOrder, rowsPerPage) => {
    this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage);
  };

  handleSearch = (searchText, page, sortOrder, rowsPerPage) => {
    this.state.roleId ===1 ? this.setState(
      {
        userBody: {
          userName: "",
          branch: {
            branchId: 1,
            code: "",
            name: ""
          },
          organization: {
            organizationId: 1
          },
          loginId: searchText != null ? searchText : "",
  
          // approved:""
        },
      },
      () => this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage)
      ) :
    this.setState(
      {
        body: {
          name: searchText != null ? searchText : "",
          branchId: 0,
          address: {
            countryId: 1,
            stateId: 0,
            cityId: 0,
          },
          approved: ""
        },
      },
      () => this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage)
    );
  };

  handleClick() {

    if (this.state.selectedRowsIndex.length > 0) {
      let selectedLCLFromTable = [];

      for (
        let index = 0;
        index < this.state.selectedRowsIndex.length;
        index++
      ) {
        selectedLCLFromTable.push(
          this.state.data[this.state.selectedRowsIndex[index]]
        );
      }
    } else {
      this.setState({
        openAlert: true,
        alertMessage: "Please select at least one LCL",
        alertSeverity: "error",
      });
    }
  }

  getSelectedLCLList() {

    let selectedLCLFromTable = [];
    for (
      let index = 0;
      index < this.state.selectedRowsIndex.length;
      index++
    ) {
      selectedLCLFromTable.push(
        this.state.data[this.state.selectedRowsIndex[index]]
      );
    }

    return selectedLCLFromTable;
  }


  handleMenuItemClick(event, index) {
    this.setSelectedRow([]);
    this.componentDidMount();
    this.setState({
      selectedIndex: index,
      open: false,
      selectedRowsIndex: [],
      openAlert: false,
    });
  }

  handleToggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  handleClose(event) {
    if (
      this.state.anchorRef.current &&
      this.state.anchorRef.current.contains(event.target)
    ) {
      return;
    }

    this.setState({
      open: false,
    });
  }

  closeLCLDetails(isClose) {
    if (isClose) {
      this.setState({
        openLCLDetails: false,
        selectedLCLFromTable: [],
        selectedRowsIndex: [],
        openAlert: true,
        alertMessage: `Approved Successfully`,
        alertSeverity: "success",
      });
      this.componentDidMount();
    } else {
      this.setState({
        openLCLDetails: false,
        selectedLCLFromTable: [],
        selectedRowsIndex: [],
      });
      this.componentDidMount();
    }
  }

  closeDialog(isClosed) {
    if (isClosed) {
      this.setState({
        openEdit: false,
        openAlert: true,
        alertMessage: "Updated Successfully",
        alertSeverity: "success",
      });
      this.setSelectedRow([]);
    } else {
      this.setState({ openEdit: false });
    }

    this.componentDidMount();
  }


  setSelectedRow(rowsSelected) {
    this.setState({
      selectedRowsIndex: rowsSelected,
    });
  }

  handleAlertClose() {
    this.setState({ openAlert: false });
  }

  getCurrentDateAndTimeFromServer() {
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    axios
      .get(BASE_URL + "/SIPCustomApi/serverCurrentDateAndTime", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("sip-authorization"),
        },
      })
      .then((response) => {
        this.setState({
          currentDateAndTimeFromServer: response.data,
        });
      })
      .catch(function (error) { });
  }

  handleDownload = (filename) => {
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    this.setState({
      openAlert: true,
      alertMessage: "Requested for CSV file download",
      alertSeverity: "success",
    });
    axios
      .post(
        BASE_URL + "/students/report",
        {
          childName: "",
          branch: {
            branchId: 0,
          },
          schoolName: "",
          schoolArea: "",
          classNumber: {
            classId: 0,
          },
          registrationCompleted: true,
          examCompletedStatus: this.state.tabHeading === "practice" ? this.state.tabHeading : this.state.isExamCompleted,
          examStatus: "",
          examination: {
            examinationId: this.state.examinationId,
          },
          examGroupId: this.state.examGroupId,
        },
        {
          headers: {
            authorization:
              "Bearer " + localStorage.getItem("sip-authorization"),
          },
        }
      )
      .then((response) => {
        if (response.data.sucess) {
          this.showDownloadPopUp(response.data.fileUrl);
        } else {
          this.setState({
            openAlert: true,
            alertMessage: this.state.errorMessageForCsvFileDownload,
            alertSeverity: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openAlert: true,
          alertMessage: this.state.errorMessageForCsvFileDownload,
          alertSeverity: "error",
        });
      });
  };

  showDownloadPopUp = (filePath) => {

    const fileName = filePath.split("/")[filePath.split("/").length - 1].trim();
    fetch(
      `${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePath}`,
      {
        method: "GET",
        headers: {
          authorization: "Bearer " + localStorage.getItem("sip-authorization"),
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        this.setState({
          openAlert: true,
          alertMessage: "File downloaded successfully",
          alertSeverity: "success",
        });
        saveAs(blob, fileName);
      })
      .catch((error) => {
        this.setState({
          openAlert: true,
          alertMessage: this.state.errorMessageForCsvFileDownload,
          alertSeverity: "error",
        });
      });
  };

  async loadBranchData() {

    let branchListToBeReturned = axios
      .get(this.state.BASE_URL + "/branches", {
        params: {
          body: JSON.stringify(this.state.body),
        },

        headers: {
          authorization: "Bearer " + localStorage.getItem("sip-authorization"),
        },
      })
      .then((response) => {

        return response;
      });

    return branchListToBeReturned;
  }

  async loadUserData() {

    let userListToBeReturned = axios
      .get(this.state.BASE_URL + "/organizations/1/branches/1/users", {
        params: {
          body: JSON.stringify(this.state.userBody),
          
        },

        headers: {
          authorization: "Bearer " + localStorage.getItem("sip-authorization"),
        },
      })
      .then((response) => {
        return response;
      });

    return userListToBeReturned;
  }


  render() {
    const { count, rowsPerPage, sortOrder } = this.state;

    const options = {
      filter: true,
      filterType: "dropdown",
      // responsive: "standard",
      vertical: true,
      fixedHeader: true,
      fixedSelectColumn: true,
      tableBodyHeight: (window.screen.height - 130),
      denseTable: true,
      serverSide: true,
      setTableProps: (index) => {
        return {
          padding: '1px',
          horizontal: true,
          scrollEnabled: false,
          textAlign: 'left'
        };
      },
      download: false,
      downloadOptions: {
        filename: "StudentTable.csv",
        //   new API change added here
        customCSVdata: this.state.data,
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        this.handleDownload(); //this makes a REST call to the server and downloads the data
        return false;
      },
      count: count,
      rowsPerPage: this.state.recordsPerPage,
      rowsPerPageOptions: [100, 200, 500],
      sortOrder: sortOrder,
      confirmFilters: true,
      rowHover: true,
      caseSensitive: false,
      enableNestedDataAccess: ".",
      expandableRowsOnClick: true,
      selectableRowsHeader: true,
      selectToolbarPlacement: "none",
      selectableRows: true,
      searchOpen: true,
      print: false,
      responsive: 'response',      
      
      //   setRowProps: (row) => {
      //           return {
      //               style:{ visibility: row[11] !== 'completed' && this.state.buttonOptions[this.state.selectedIndex] === 'Schedule Workshop' ? 'hidden' : 'visible'},
      //           }
      //   },
      onFilterChipClose: (column, filterList, type) => {
        var newFilters = () => type;
        this.handleFilterSubmit(newFilters);
      },
      onFilterDialogClose: () => {
        this.componentDidMount();
      },
      isRowSelectable: (dataIndex) => {
        if (this.state.data[dataIndex]) {
          return (!this.state.data[dataIndex].approved)
            ? true
            : false;
        }
      },

      onRowSelectionChange: (
        currentRowsSelected,
        allRowsSelected,
        rowsSelected
      ) => {
        this.setSelectedRow(rowsSelected);
      },
      onRowClick: (rowData, rowState) => { },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: "40px" }}>
            <Grid container>
              <Grid item xs={5}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.handleFilterSubmit(applyNewFilters)}
                >
                  Filter
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      },

      //   callback that gets executed when filters are confirmed
      onFilterConfirm: (filterList) => { },

      onFilterDialogOpen: () => { },
      searchPlaceholder: "Search by Name...",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "changePage":
            this.changePage(
              tableState.page,
              tableState.sortOrder,
              tableState.rowsPerPage
            );
            break;
          case "sort":
            this.sort(
              tableState.page,
              tableState.sortOrder,
              tableState.rowsPerPage
            );
            break;
          case "changeRowsPerPage":
            this.changeRecordPerPage(
              tableState.page,
              tableState.sortOrder,
              tableState.rowsPerPage
            );
            break;
          case "search":
            this.handleSearch(
              tableState.searchText,
              tableState.page,
              tableState.sortOrder,
              tableState.rowsPerPage
            );
            break;
          default:
        }
      },
    };
    return (
      <div className="container">
        <CssBaseline />
        <Grid container spacing={1} style={{ overflow: 'hidden' }}>
          <Grid
            container
            xs={12}
            md={12}
            lg={12}
            sm={12}
            xl={12}
            justify="flex-end"
          >
            <Button variant='contained' disabled={!this.state.userApproved} color='primary' onClick={(() => {
              if (this.state.selectedRowsIndex.length > 0) {

                this.setState({
                  openLCLDetails: true,
                  selectedLCLFromTable: this.getSelectedLCLList(),
                  openAlert: false,
                })
              } else {
                this.setState({
                  openAlert: true,
                  alertMessage: "Please select at least one LCL",
                  alertSeverity: "error",
                });
              }
            })}>
              Approve
                            </Button>
            <Popper
              style={{ zIndex: 1000 }}
              open={this.state.open}
              anchorEl={this.state.anchorRef.current}
              role={undefined}
              transition="false"
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper elevation={10}>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList id="split-button-menu" variant="menu">
                        {this.state.buttonOptions.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === this.state.selectedIndex}
                            onClick={(event) =>
                              this.handleMenuItemClick(event, index)
                            }
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            <LCLApprovalList
              open={this.state.openLCLDetails}
              onClose={this.closeLCLDetails}
              selectedLCLFromTable={this.state.selectedLCLFromTable}
              roleId = {this.state.roleId}
            // currentDateAndTimeFromServer={
            //   this.state.currentDateAndTimeFromServer
            // }
            />

            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.openAlert}
              autoHideDuration={5000}
              onClose={this.handleAlertClose}
              key="top center"
            >
              <Alert
                onClose={this.handleAlertClose}
                severity={this.state.alertSeverity}
              >
                {this.state.alertMessage}
              </Alert>
            </Snackbar>
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12} xl={12} >
            <MUIDataTable showsHorizontalScrollIndicator={false} style={{ overflowX: 'hidden' }}
              title={
                <Typography variant="h6">
                {this.state.roleId===1 ? "Users":"LCL"}  
                  {this.state.isLoading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              }
              data={this.state.data}
              columns={this.state.columns}
              options={options}
            />
          </Grid>
        </Grid>
      </div>
    );

  }
}

export default CSODashboard;
