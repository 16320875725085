import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TimerComponent from "./TimerComponent";
import TimerIcon from "@material-ui/icons/Timer";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import ProgressBar from "./ProgressBar";
import { useMediaQuery, CardMedia } from "@material-ui/core";
import IconTimer from "./icons/Icon-02.png";
import IconAnswered from "./icons/Icon-01.png";
import IconAssessment from "./icons/Icon-03.png";
import RuleIcon from "@mui/icons-material/Rule";
import GradingIcon from "@mui/icons-material/Grading";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: "10px",
    padding: theme.spacing(1),
    textAlign: "center",
    color: "white",
    backgroundColor: "#f38020",
    borderCollapse: "collapse",
    fontSize: 15,
    fontWeight: "bold",
    borderRadius: 15,
    justifyContent: "screenRight",
    width: "100%",
  },
  commonBackgroundColor: {
    backgroundColor: "#fcebb9",
    textAlign: "center",
  },
  IconTimer: {
    backgroundImage: `url(${IconTimer})`,
  },
  media: {
    height: 40,
    width: 40,
  },
}));

const DurationComponent = (props) => {
  const {
    examObject,
    answeredQuestion,
    setStopTimer,
    totalNoOfQuestions,
    allotedTime,
    examinationType,
    setSyncTimer,
    syncTimer,
    agcTimeLoad,
    totalTimeSync,
    setTotalTimeSync,
  } = props;
  const classes = useStyles();
  let theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const progressBarColor = "#2962ff";
  let totalNumberOfQue = totalNoOfQuestions;
  var examName = examObject.exam.name;

  return (
    <div className={classes.root}>
      <Grid container fullWidth>
        <Paper elevation={8} className={classes.paper}>
          {examinationType === "practice" ? (
            <Grid item container spacing={1}>
              <Grid item xs={4} sm={4} md={4}>
                <Paper
                  elevation={8}
                  className={classes.commonBackgroundColor}
                  spacing={1}
                >
                  <Grid container>
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      sm={12}
                      justify="center"
                      alignContent="center"
                    >
                      <HourglassTopIcon
                        fontSize="large"
                        sx={{
                          color: "#e67e22",
                          borderRadius: "50%",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      sm={12}
                      direction="column"
                      justify="center"
                      alignContent="center"
                    >
                      <div
                        style={{
                          fontSize: smallScreen ? "12px" : "16px",
                          marginTop: smallScreen ? "1%" : "4%",
                        }}
                      >
                        {examinationType === "practice"
                          ? "TIME LEFT"
                          : "Total Time Alloted"}{" "}
                        :{" "}
                        <TimerComponent
                          allotedTime={allotedTime}
                          setStopTimer={setStopTimer}
                          setSyncTimer={setSyncTimer}
                          examinationType={examinationType}
                          timeType={"totalTime"}
                          syncTimer={syncTimer}
                          agcTimeLoad={agcTimeLoad}
                          totalTimeSync={totalTimeSync}
                          setTotalTimeSync={setTotalTimeSync}
                        ></TimerComponent>
                         { smallScreen ? <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>  : " "}  
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <Paper elevation={8} className={classes.commonBackgroundColor}>
                  <Grid container justify="flex-end">
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      sm={12}
                      justify="center"
                      alignContent="center"
                    >
                      <GradingIcon fontSize="large"    sx={{
                          color: "#e67e22",
                          borderRadius: "50%",
                        }}/>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      sm={12}
                      direction="column"
                      justify="center"
                      alignContent="center"
                    >
                      <div
                        style={{
                          fontSize: smallScreen ? "12px" : "16px",
                          marginTop: smallScreen ? "1%" : "4%",
                        }}
                      >
                        {" "}
                        {examName.toUpperCase()}{" "}
                        {smallScreen ? <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>:""}
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <Paper elevation={8} className={classes.commonBackgroundColor}>
                  <Grid container>
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      sm={12}
                      justify="center"
                      alignContent="center"
                    >
                      <RuleIcon fontSize="large"    sx={{
                          color: "#e67e22",
                          borderRadius: "50%",
                        }}/>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      sm={12}
                      direction="column"
                      justify="center"
                      alignContent="center"
                    >
                      <div
                        style={{
                          fontSize: smallScreen ? "12px" : "16px",
                          marginTop: smallScreen ? "1%" : "4%",
                        }}
                      >
                   
                            
                        ANSWERED: {answeredQuestion}/{totalNoOfQuestions}  { smallScreen ? <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>  : " "}                   
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          ) : (
            <Grid item container spacing={1}>
              <Grid item xs={6} sm={6} md={6}>
                <Paper elevation={8} className={classes.commonBackgroundColor}>
                  <Grid container justify="flex-end">
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      sm={12}
                      justify="center"
                      alignContent="center"
                    >
                      <GradingIcon fontSize="large"    sx={{
                          color: "#e67e22",
                          borderRadius: "50%",
                        }}/>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      sm={12}
                      direction="column"
                      justify="center"
                      alignContent="center"
                    >
                      <div
                        style={{
                          fontSize: smallScreen ? "14px" : "16px",
                          marginTop: smallScreen ? "1%" : "2%",
                        }}
                      >
                        {" "}
                        {examObject.exam.name}{" "}
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Paper elevation={8} className={classes.commonBackgroundColor}>
                  <Grid container>
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      sm={12}
                      justify="center"
                      alignContent="center"
                    >
                      <RuleIcon fontSize="large"    sx={{
                          color: "#e67e22",
                          borderRadius: "50%",
                        }}/>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={12}
                      sm={12}
                      direction="column"
                      justify="center"
                      alignContent="center"
                    >
                      <div>
                        Answered : <br />
                        {answeredQuestion}/{totalNoOfQuestions}
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
          <ProgressBar
            completed={(answeredQuestion / totalNumberOfQue) * 100}
            bgcolor={progressBarColor}
          />
        </Paper>
      </Grid>
    </div>
  );
};
export default DurationComponent;
