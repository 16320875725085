import React, { useRef } from "react";
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Popper,
  Grow,
  Paper,
  MenuList,
  MenuItem,
  FormControl,
  Snackbar,
  CssBaseline,
  TextField,
  Tooltip,
  IconButton,
  ButtonGroup,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Alert from "@material-ui/lab/Alert";
import dayjs from "dayjs";
import { Autocomplete } from "@material-ui/lab";
import { saveAs } from "file-saver";
import EditOutLinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutLinedIcon from "@material-ui/icons/DeleteOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import RepeatIcon from '@material-ui/icons/Repeat';
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import ConfirmationDialog from "../../../Common/Confirmation";
import ActivateTestDialog from "../ActivateTestDialog";
import PromotionList from "../Promotion/Promotion";
import PasswordRest from "../PasswordRest";
import PracticeExamHistory from "../../../Students/PracticeExamHistory/PracticeExamHistory";
import MenuBookIcon from '@material-ui/icons/MenuBook';




class StudentListProdigy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEdit: false,
      openConfirmationDialog: false,
      openViewHistory: false,
      transactionObj: {},
      pageNo: 0,
      recordsPerPage: 
      props.propertiesNeededForStudentComponent.screenForStudentsSelectedToNextRound? 500:
       100,
      sortKey: "lastUpdatedDt",
      sortDir: "desc",
      winnerStatus: props.examStatus, 
      count: 0,
      BASE_URL: `${process.env.REACT_APP_API_URL}`,
      noOfQuestions: [],
      endDateColumnIndex: 13,
      noOfQuestion: 0,
      childNameList: [],
      classNameList: [],
      schoolNameList: [],
      roleName: JSON.parse(localStorage.getItem("sip-user")).roles[0].name,
      roleNameForLclAdmin: 'LCL ADMIN',
      registrationStatusList: [],
      examStatusList: [],
      examStatusForStartingTest: "started",
      examStatusForInProgressTest: "in-progress",
      examStatusForCompletedTest: "completed",
      examStatusForAbortingTest: "aborted",
      examStatusForStatePromotion: "Promoted to SR",
      displayPromotionApproveButton: props.propertiesNeededForStudentComponent.displayApproveButton,
      displayActiveTestButtonGroup: props.propertiesNeededForStudentComponent.displayActiveTestButtonGroup,
      dataLoadingMessage: "Loading Data...",
      branchList: [],
      tabHeading: props.examStatus,
      isExamCompleted:
        props.examStatus === "completed"|| props.examStatus === 'State Winners' ||
        props.examStatus === "National Winners" ? "completed" : "notCompleted",
      sendEmailButtonHeading: "Send LoginId & Password",
      confirmationType: "",
      data: [["loading..."]],
      columns: [
        //0            
        {
          name: "action",
          label: "Actions",
          options: {
            filter: false,
            display: (props.propertiesNeededForStudentComponent.currentLevel === 0)
              //   ||  (props.propertiesNeededForStudentComponent.currentLevel===1 && props.propertiesNeededForStudentComponent.examStatus==="completed" &&
              // JSON.parse(localStorage.getItem("sip-user")).roles[0].name ==='LCL ADMIN'))
              ? false : true, setCellHeaderProps: value => ({ style: { paddingRight: '65px' } }),
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
              return (
                <Grid container justify="flex-start">
                  {/* <Tooltip title="Edit" aria-label="edit">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                this.setState({
                                  openEdit: true,
                                  studentObj: {
                                    mode: "edit",
                                    student: this.state.data[dataIndex],
                                  },
                                });
                              }}
                            >
                          <EditOutLinedIcon />
                            </IconButton>
                          </Tooltip> */}
                  <Tooltip
                    title="Delete"
                    style={{
                      display: this.state.studentDeletePermission
                        ? "block"
                        : "none",
                    }}
                    aria-label="delete"
                  >
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        let itemName = this.state.data[dataIndex].childName;
                        this.setState({
                          openConfirmationDialog: true,
                          transactionObj: this.state.data[dataIndex],
                          confirmationType: "delete",
                          message: `Are You sure?, You want to Delete "${itemName}" ?`,
                        });
                      }}
                    >
                      <DeleteOutLinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Reset Password" aria-label="reset-password"
                    style={{
                      display:
                        ((!this.state.isLoading && this.state.data.length > 0) &&
                         this.state.data[dataIndex] !== undefined && this.state.isExamCompleted !== "notCompleted")
                          ? "none"
                          : "block",
                    }}>
                    <IconButton
                      onClick={async () => {
                        let student = await this.state.data[dataIndex];
                        this.setState({
                          openPasswordResetDialog: true,
                          transactionObj: await student,
                          studentAccountId: await student.studentAccountId,
                          studentName: await student.childName,
                        });
                      }}
                    >
                      <LockOpenIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="View Score" aria-label="view-score"
                    style={{
                      display:
                        (!this.state.isLoading && this.state.data.length > 0)
                          && (this.state.data[dataIndex] !== undefined && this.state.isExamCompleted !== 'notCompleted')
                          ? "block"
                          : "none",
                    }}>
                    <IconButton
                      color="secondary"
                      onClick={async () => {
                        let student = await this.state.data[dataIndex];
                        this.setState({
                          openViewHistory: true,
                          transactionObj: await student,
                          studentId: await student.studentId,
                          groupId: await props.examGroupId,

                        });
                      }}
                    >
                      <MenuBookIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title={this.state.sendEmailButtonHeading}
                    style={{
                      display:
                        ((!this.state.isLoading && this.state.data.length > 0) && this.state.data[dataIndex] !== undefined && this.state.isExamCompleted !== "notCompleted")
                          ? "none"
                          : "block",
                    }}
                    aria-label="resend email"
                  >
                    <IconButton
                      color="secondary"
                      onClick={async () => {
                        let studentName = await this.state.data[dataIndex]
                          .childName;
                        this.setState({
                          openConfirmationDialog: true,
                          transactionObj: await this.state.data[dataIndex],
                          confirmationType: "resend",
                          message: `Are You sure?, You want to re-send Login Id and Password to "${studentName}" ?`,
                        });
                      }}
                    >
                      <EmailOutlinedIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title="Re-schedule Exam"
                    style={{
                      display:
                        (!this.state.isLoading && this.state.data.length > 0)
                          && (this.state.roleName !== this.state.roleNameForLclAdmin) && ((this.state.data[dataIndex] !== undefined && this.state.data[dataIndex].examStatus === "completed") &&
                            this.state.isExamCompleted === "completed")
                          ? "block"
                          : "none",
                    }}
                    aria-label="reschedule exam"
                  >
                    <IconButton
                      color="secondary"
                      onClick={async () => {
                        let studentName = await this.state.data[dataIndex].childName;
                        this.setState({
                          openConfirmationDialog: true,
                          transactionObj: await this.state.data[dataIndex],
                          confirmationType: "reSchedule",
                          message: `Are You sure?, You want to re-schedule exam to "${studentName}" ?`,
                        });
                      }}
                    >
                      <RepeatIcon />
                    </IconButton>
                  </Tooltip>

                </Grid>
              );
            },
          },
        },


        //1        
        {
          name: "childName",
          label: "Child Name",
          options: {
            filter: true,
            filterType: "custom",
            setCellHeaderProps: value => ({ style: { paddingRight: '20px' } }),

            customFilterListOptions: {
              render: (v) => v.map((l) => l.childName),
              update: (filterList, filterPos, index) => {
                filterList[index].splice(filterPos, 1);
                return filterList;
              },
            },
            filterOptions: {
              logic: (location, filters, row) => {
                if (filters.length) return !filters.includes(location);
                return false;
              },
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      freeSolo
                      multiple
                      id="dropdown-for-child-name"
                      disableClearable
                      onOpen={() => {
                        this.getStudentsList("", "");
                      }}
                      options={this.state.childNameList}
                      value={filterList[index]}
                      onChange={(event, newValue) => {
                        if (
                          filterList[index].length >= 1 &&
                          newValue.length > 0
                        ) {
                          newValue.splice(0, 1);
                          filterList[index].splice(
                            0,
                            1,
                            newValue.splice(0, 1)[0]
                          );
                        } else {
                          filterList[index] = newValue;
                        }
                        onChange(filterList[index], index, column);
                      }}
                      loadingText="Loading..."
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }

                        return option.childName;
                      }}
                      getOptionSelected={(option, value) =>
                        option.studentId === value.studentId
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Child Name"
                          margin="normal"
                          variant="outlined"
                          onChange={async (ev) => {
                            if (
                              ev.target.value !== "" ||
                              ev.target.value !== null
                            ) {

                              this.getStudentsList(ev.target.value, "");
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            endAdornment: (
                              <React.Fragment>
                                {this.state.childNameLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                );
              },
            },
          },
        },
        //2
        {
          name: "className",
          label: "Participation Level",
          options: {
            filter: true,
            filterType: "custom",

            customFilterListOptions: {
              render: (v) => v.map((l) => l.name),
              update: (filterList, filterPos, index) => {
                filterList[index].splice(filterPos, 1);
                return filterList;
              },
            },
            filterOptions: {
              logic: (location, filters, row) => {
                if (filters.length) return !filters.includes(location);
                return false;
              },
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      freeSolo
                      multiple
                      id="dropdown-for-class-name"
                      disableClearable
                      onOpen={async () => {
                        let optionValues = await this.getClassNumberList();
                        this.setState({
                          classNameList: optionValues.data.content,
                        });
                      }}
                      value={filterList[index]}
                      onChange={(event, newValue) => {
                        if (
                          filterList[index].length >= 1 &&
                          newValue.length > 0
                        ) {
                          newValue.splice(0, 1);
                          filterList[index].splice(
                            0,
                            1,
                            newValue.splice(0, 1)[0]
                          );
                        } else {
                          filterList[index] = newValue;
                        }
                        onChange(filterList[index], index, column);
                      }}
                      loadingText="Loading..."
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }

                        return option.name;
                      }}
                      getOptionSelected={(option, value) =>
                        option.classId === value.classId
                      }
                      options={this.state.classNameList}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Participation Level"
                          margin="normal"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                  </FormControl>
                );
              },
            },
          },

        },
        //3
        {
          name: "currentLevel",
          label: "Current Level",
          options: {
            filter: false,
            display: true,

          },
        },
        //4
        {
          name: "schoolName",
          label: "School",
          display: false,
          options: {
            filter: true,
            display: false,
            // setCellHeaderProps: value => ({ style: { width:'60px',textDecoration: 'underline' } }),
            filterType: "custom",
            customFilterListOptions: {
              render: (v) => v.map((l) => l.schoolName),
              update: (filterList, filterPos, index) => {
                filterList[index].splice(filterPos, 1);
                return filterList;
              },
            },
            filterOptions: {
              logic: (location, filters, row) => {
                if (filters.length) return !filters.includes(location);
                return false;
              },
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl fullWidth>
                    <Autocomplete
                      freeSolo
                      multiple
                      id="dropdown-for-school-name"
                      disableClearable
                      onOpen={async () => {
                        this.getStudentsList("", "");
                      }}
                      onClose={() => {
                        this.setState({
                          schoolNameList: [],
                        });
                      }}
                      value={filterList[index]}
                      onChange={(event, newValue) => {
                        if (
                          filterList[index].length >= 1 &&
                          newValue.length > 0
                        ) {
                          newValue.splice(0, 1);
                          filterList[index].splice(
                            0,
                            1,
                            newValue.splice(0, 1)[0]
                          );
                        } else {
                          filterList[index] = newValue;
                        }
                        onChange(filterList[index], index, column);
                      }}
                      loadingText="Loading..."
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }

                        return option.schoolName;
                      }}
                      getOptionSelected={(option, value) =>
                        option.studentId === value.studentId
                      }
                      options={this.state.schoolNameList}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="School"
                          margin="normal"
                          variant="outlined"
                          onChange={(ev) => {
                            if (
                              ev.target.value !== "" ||
                              ev.target.value !== null
                            ) {
                              this.getStudentsList("", ev.target.value);
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            endAdornment: (
                              <React.Fragment>
                                {this.state.schoolNameLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                );
              },
            },
          },
        },

        //5
        {
          name: "schoolArea",
          label: "School Area",
          options: {
            filter: false,
            display: false,
            // setCellHeaderProps: value => ({ style: { width:'50px',textDecoration: 'underline' } }),

          },

        },
        //6
        {
          name: "branchName",
          label: "LCL - Name",
          options: {
            filter: true,
            display: false,
            filterType: "custom",
            customFilterListOptions: {
              render: (v) =>
                v.map((l) => {
                  return `${l.name} - ${l.code}`;
                }),
              update: (filterList, filterPos, index) => {
                filterList[index].splice(filterPos, 1);
                return filterList;
              },
            },
            filterOptions: {
              logic: (location, filters, row) => {
                if (filters.length) return !filters.includes(location);
                return false;
              },
              display: (filterList, onChange, index, column) => {
                return (
                  <FormControl>
                    <Autocomplete
                      freeSolo
                      multiple
                      id="dropdown-for-branch"
                      disableClearable
                      onOpen={async () => {
                        let options = await this.loadBranchData();
                        this.setState({
                          branchList: options.data.content,
                        });
                      }}
                      options={this.state.branchList}
                      value={filterList[index]}
                      onChange={(event, newValue) => {
                        if (
                          filterList[index].length >= 1 &&
                          newValue.length > 0
                        ) {
                          newValue.splice(0, 1);
                          filterList[index].splice(
                            0,
                            1,
                            newValue.splice(0, 1)[0]
                          );
                        } else {
                          filterList[index] = newValue;
                        }
                        onChange(filterList[index], index, column);
                      }}
                      loadingText="Loading..."
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }

                        return `${option.name} - ${option.code}`;
                      }}
                      getOptionSelected={(option, value) =>
                        option.branchId === value.branchId
                      }
                      renderOption={(option) => {
                        return `${option.name} - ${option.code}`;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Branch"
                          margin="normal"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                  </FormControl>
                );
              },
            },
          },
        },
        //7
        {
          name: "branchCode",
          label: "LCL - Code",
          options: {
            filter: false,
            display: false,
          },
        },

        //8
        {
          name: "parentName",
          label: "Parent Name",
          options: {
            filter: false,
            display: false,
          },
        },
        //9
        {
          name: "loginId",
          label: "Login Id",
          options: {
            filter: false,
            display: true,
          },
        },

        //10
        {
          name: "password",
          label: "Password",
          options: {
            filter: false,
            display: true,
          },
        },
        //11 
        {
          name: "startDate",
          label: "Batch Date(Start & End Time)",
          options: {
            display: props.propertiesNeededForStudentComponent.currentLevel === 0 ? false : true,
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta) => {
              let endTimeFromEndDate =
                (tableMeta.rowData[this.state.endDateColumnIndex] !== null && tableMeta.rowData[this.state.endDateColumnIndex]);
              let slicedEndTimeFromEndDate =
                (tableMeta.rowData[this.state.endDateColumnIndex] !== null && endTimeFromEndDate !== undefined && endTimeFromEndDate !== 0 && endTimeFromEndDate !== null)
                  ? endTimeFromEndDate.slice(11, 16)
                  : "";
              if (value !== undefined && tableMeta.rowData[this.state.endDateColumnIndex] !== null) {
                var customParseFormat = require("dayjs/plugin/customParseFormat");
                dayjs.extend(customParseFormat);

                return (
                  props.examStatus === "practice" ? dayjs(value, "DD-MM-YYYY HH:mm").format(  //For practice removing batch end time
                    "DD-MM-YYYY hh:mm a"
                  ) :

                    dayjs(value, "DD-MM-YYYY HH:mm").format(
                      "DD-MM-YYYY hh:mm a"
                    ) +
                    " - " +
                    dayjs(slicedEndTimeFromEndDate, "HH:mm").format("hh:mm a")
                );
              }
            },
          },
        },
        //12
        {
          name: "examCompletedDt",
          label: props.examStatus === "practice" ? "Last Test Attended Date" : "Test Completed Date",
          display: true,
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta) => {
              if (value) {
                var customParseFormat = require('dayjs/plugin/customParseFormat')
                dayjs.extend(customParseFormat)
                return dayjs(value, "DD-MM-YYYY HH:mm").format('DD-MM-YYYY hh:mm a')
              }
            }

          },
        },
        //13           
        {
          name: "endDate",
          label: "Batch End Date and time",
          options: {
            filter: false,
            sort: false,
            display: "excluded",
          },
        },
        //14
        {
          name: "winnerStatus",
          label: "Winner Status",
          options: {
            filter: false,
            sort: false,
            display: props.examStatus==='State Winners' || props.examStatus==='National Winners' ? true :"excluded",
          },
        },
        {
          name: "stateName",
          label: "States",
          options: {
            filter: false,
            sort: false,
            display: props.examStatus==='State Winners' || props.examStatus==='National Winners' ? true :"excluded",
          },
        },

        {
          name: "examStatus",
          label: "Exam Status",
          options: {
            display: props.propertiesNeededForStudentComponent.currentLevel === 0 ||
            props.examStatus==='State Winners' || props.examStatus==='National Winners' ? false : true,
            filter: true,
            sort: false,
            filterType: "custom",
            customFilterListOptions: {
              render: (v) => v.map((l) => l),
              update: (filterList, filterPos, index) => {
                filterList[index].splice(filterPos, 1);
                return filterList;
              },
            },
            filterOptions: {
              logic: (location, filters, row) => {
                if (filters.length) return !filters.includes(location);
                return false;
              },
              display: (filterList, onChange, index, column) => {
                let optionsForExamStatusFlag = [
                  this.state.examStatusForStartingTest,
                  this.state.examStatusForInProgressTest,
                  this.state.examStatusForCompletedTest,
                  this.state.examStatusForAbortingTest,
                  this.state.examStatusForStatePromotion
                ];
                return (
                  <FormControl fullWidth>
                    <Autocomplete
                      freeSolo
                      multiple
                      id="dropdown-for-exam-status-flag"
                      disableClearable
                      value={filterList[index]}
                      onChange={(event, newValue) => {
                        if (
                          filterList[index].length >= 1 &&
                          newValue.length > 0
                        ) {
                          newValue.splice(0, 1);
                          filterList[index].splice(
                            0,
                            1,
                            newValue.splice(0, 1)[0]
                          );
                        } else {
                          filterList[index] = newValue;
                        }
                        onChange(filterList[index], index, column);
                      }}
                      loadingText="Loading..."
                      options={optionsForExamStatusFlag}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                      }}
                      getOptionSelected={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Exam Status"
                          margin="normal"
                          variant="outlined"
                          InputProps={{ ...params.InputProps, type: "search" }}
                        />
                      )}
                    />
                  </FormControl>
                );
              },
            },
          },

        },
        //15
        {
          name: "totalMarks",
          label: props.propertiesNeededForStudentComponent.currentLevel === 0 ? "Last Attempt Marks" : "Total Marks",
          options: {
            filter: false,
            sort: true,
            display: true
          },
        },
        //16
        {
          name: "noOfAttempts",
          label: "No Of Attempt",
          options: {
            filter: false,
            display: props.propertiesNeededForStudentComponent.currentLevel === 0 ? true : false,
          },
        },
        //17
        {
          name: "mobileNo1",
          label: "Mobile Number",
          options: {
            filter: false,
            display: false,
          },
        },
        //19
        {
          name: "whatsAppNumber",
          label: "WhatsApp Number",
          options: {
            filter: false,
            display: false,
          },
        },
        //20
        {
          name: "emailId",
          label: "Email Id",
          options: {
            filter: false,
            display: false,
          },
        },

        //22
        {
          name: "lastUpdatedDt",
          label: "Last Updated Date",
          options: {
            filter: false,
            display: false,
          },
        },
        //23
        {
          name: "lastUpdatedBy",
          label: "Last Updated By",
          options: {
            filter: false,
            display: false,
          },
        },
        //24
        {
          name: "createdDt",
          label: "Registration Date",
          options: {
            filter: false,
            display: false,
          },
        },
        //25       
        {
          name: "createdBy",
          label: "Created By",
          options: {
            filter: false,
            display: false,
          },
        },

      ],
      isLoading: false,
      screenForStudentsSelectedToNextRound: props.propertiesNeededForStudentComponent.screenForStudentsSelectedToNextRound,
      body: {
        childName: "",
        branchId: 0,
        schoolName: "",
        classNumber: { classId: 0 },
        examStatus: "",
        examCompletedStatus: props.examStatus,
        examinationId: props.examinationId,
        groupId: props.examGroupId,
      },
      studentObj: {
        mode: "Edit",
        student: {},
      },
      open: false,
      openPromotion: false,
      anchorRef: React.createRef(),
      selectedIndex: 0,
      nextRound:props.nextRound,
      buttonOptions: props.examStatus === 'completed' ? `Promote To ${props.nextRound}` : "Activate Test",
      openActiveTest: false,
      selectedRowsIndex: [],
      selectedStudentFromTable: [],
      openAlert: false,
      branchId: JSON.parse(localStorage.getItem("sip-user")).branch.branchId,
      orgId: JSON.parse(localStorage.getItem("sip-user")).organization.organizationId,
      isBranchApproved: JSON.parse(localStorage.getItem("sip-user")).branch.approved,
      // isUserAccessDisabled:
      //  JSON.parse(localStorage.getItem("sip-user")).userId===858 || 
      //  JSON.parse(localStorage.getItem("sip-user")).userId===860 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===861 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===862 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===864 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===865 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===866 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===868 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===870 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===872 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===873 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===875 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===876 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===877 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===879 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===883 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===885 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===886 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===887 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===888 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===889 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===890 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===891 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===892 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===893 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===894 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===895 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===897 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===898 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===899 ||
      //  JSON.parse(localStorage.getItem("sip-user")).userId===1074  ? false :true,
      alertMessage: "",
      alertSeverity: "",
      currentDateAndTimeFromServer: "",
      errorMessageForCsvFileDownload: "Failed to download the File!",
      studentEditPermissionFlag: false,
      studentDeletePermission: false,
      reActivateTestPermission: false,
      setTotalStudentCount: props.setTotalStudentCount,
      roleModuleAccessList: JSON.parse(localStorage.getItem("sip-user"))
        .roles[0].roleModuleAccess,
      studentEditPermission: () => {
        let editPermissionFlag = false;
        {
          JSON.parse(
            localStorage.getItem("sip-user")
          ).roles[0].roleModuleAccess.map((item) => {
            if (item.module.name === "Student Registration" && item.editFlag) {
              editPermissionFlag = true;
              this.setState({
                studentEditPermissionFlag: true,
              });
            }
            if (
              item.module.name === "Student Registration" &&
              item.deleteFlag
            ) {
              this.setState({
                studentDeletePermission: true,
              });
            }
            if (item.module.name === "Re Activate Test" && item.deleteFlag) {
              this.setState({
                reActivateTestPermission: true
              })
            }

          });
        }
        return editPermissionFlag;
      },
      childNameLoading: false,
      schoolNameLoading: false,
      openPasswordResetDialog: false,
      studentAccountId: 0,
      studentName: "",
      examinationId: props.examinationId,
      examGroupId: props.examGroupId,
      currentLevel: props.propertiesNeededForStudentComponent.currentLevel,
      isAdminAllowedToScheduleExam: JSON.parse(localStorage.getItem("sip-user")).approved
    };
    this.getData = this.getData.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.sort = this.sort.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    this.closeActiveTest = this.closeActiveTest.bind(this);
    this.setSelectedRow = this.setSelectedRow.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.closePromotion = this.closePromotion.bind(this);
    this.getCurrentDateAndTimeFromServer = this.getCurrentDateAndTimeFromServer.bind(
      this
    );
    this.getStudentsList = this.getStudentsList.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.closeConfirmationDialog = this.closeConfirmationDialog.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.showDownloadPopUp = this.showDownloadPopUp.bind(this);
    this.closePasswordRestDialog = this.closePasswordRestDialog.bind(this);
    this.closeViewHistory = this.closeViewHistory.bind(this);
    this.getSelectedStudentList = this.getSelectedStudentList.bind(this);

  }

  componentDidMount() {
    this.getData(
      this.state.pageNo,
      this.state.sortKey,
      this.state.sortDir,
      this.state.recordsPerPage
    );
    this.getCurrentDateAndTimeFromServer();
    let perm = this.state.studentEditPermission();
  }

  handleFilterSubmit = (applyFilters) =>{
    let filterList = applyFilters();
    let selectedNameFilterValue = "";
    let selectedBranchNameFilterValue = "";
    let selectedBranchId = 0;
    let selectedClassId = 0;
    let selectedSchoolName = "";
    let examStatus = "";

    filterList[1].forEach((element) => {
      selectedNameFilterValue = element.childName;
    });
    filterList[6].forEach((element) => {
      selectedBranchNameFilterValue = element.name;
      selectedBranchId = element.branchId;
    });
    filterList[2].forEach((element) => {
      selectedClassId = element.classId;
    });
    filterList[4].forEach((element) => {
      selectedSchoolName = element.schoolName;
    });

    filterList[16].forEach((element) => {
      examStatus = element;
    });


    this.setState(
      {
        body: {
          childName: selectedNameFilterValue,
          branchName: selectedBranchNameFilterValue,
          branchId: selectedBranchId,

          classNumber: {
            classId: selectedClassId
          },

          schoolName: selectedSchoolName,
          examStatus: examStatus,
          examCompletedStatus: this.state.tabHeading === "practice" || this.state.tabHeading === "State Winners"  || this.state.tabHeading === "National Winners" ? this.state.tabHeading : this.state.isExamCompleted,
          examinationId: this.state.examinationId,
          groupId: this.state.examGroupId,

        }
      },
      () =>
        this.getData(
          this.state.page,
          this.state.sortKey,
          this.state.sortDir,
          this.state.recordsPerPage
        )
    );
  };


  async getData(pageNo, sortKey, sortDir, recordsPerPage) {
    this.setState({ isLoading: true, });
    await axios.get(this.state.screenForStudentsSelectedToNextRound && this.state.BASE_URL + "/studentInfo/promoted" || 
    (this.state.winnerStatus==='State Winners' || this.state.winnerStatus==='National Winners') && this.state.BASE_URL + "/studentInfo/winners" ||
    !this.state.screenForStudentsSelectedToNextRound && this.state.BASE_URL + "/studentInfo" 
     ,
      {
        params: {
          body: JSON.stringify(this.state.body),
          pageNo: pageNo !== undefined ? pageNo : "",
          sortKey: sortKey !== undefined ? sortKey : "",
          sortDir: sortDir !== undefined ? sortDir : "",
          recordsPerPage: recordsPerPage !== undefined ? recordsPerPage : "",
        },
        headers: {
          authorization:
            "Bearer " + localStorage.getItem("sip-authorization"),
        },
      }
    )
      .then((response) => {
        if(!response.data.empty){
        for(let studentIndex=0;studentIndex<response.data.content.length;studentIndex++){
          if(response.data.content[studentIndex].winnerStatus!=undefined && (response.data.content[studentIndex].winnerStatus===2 || response.data.content[studentIndex].winnerStatus===5)) {
            response.data.content[studentIndex].winnerStatus ='Champion'
          }else if(response.data.content[studentIndex].winnerStatus!=undefined && response.data.content[studentIndex].winnerStatus===3 || response.data.content[studentIndex].winnerStatus===6){
            response.data.content[studentIndex].winnerStatus ='1st Runner Up'
          }else if(response.data.content[studentIndex].winnerStatus!=undefined && response.data.content[studentIndex].winnerStatus===4 || response.data.content[studentIndex].winnerStatus===7){
            response.data.content[studentIndex].winnerStatus ='2nd Runner Up'
          }
        }
      }
        this.setState({
          data: response.data.content,
          count: response.data.totalElements,
        });
        this.state.setTotalStudentCount(response.data.totalElements);
        
      });

    this.setState({ isLoading: false });
  }

  async getStudentsList(searchedChildName, searchedSchoolName) {
    this.setState({
      childNameList: [],
      schoolNameList: [],
    });
    if (searchedChildName !== null && searchedChildName !== "") {
      this.setState({
        childNameLoading: true,
      });
    } else if (searchedSchoolName !== null && searchedSchoolName !== "") {
      this.setState({
        schoolNameLoading: true,
      });
    }
    await axios
      .get(this.state.BASE_URL + "/studentInfo", {
        params: {
          body: {
            childName: searchedChildName,
            branchName: "",
            branchId: 0,

            schoolName: searchedSchoolName,
            classNumber: { classId: 0 },
            examStatus: "",
            examCompletedStatus:
              this.state.tabHeading === "practice" || this.state.tabHeading === "State Winners" || this.state.tabHeading === "National Winners"
                ? this.state.tabHeading
                : this.state.isExamCompleted,
            examinationId: this.state.examinationId,
            groupId: this.state.examGroupId,
          },
          pageNo: 0,
          sortKey: "childName",
          sortDir: "asc",
          recordsPerPage: 100,
        },
        headers: {
          authorization: "Bearer " + localStorage.getItem("sip-authorization"),
        },
      })
      .then((response) => {
        this.setState({
          childNameList: response.data.content,
          schoolNameList: response.data.content,
        });
        if (searchedChildName !== null && searchedChildName !== "") {
          this.setState({
            childNameLoading: false,
          });
        } else if (searchedSchoolName !== null && searchedSchoolName !== "") {
          this.setState({
            schoolNameLoading: false,
          });
        }
      });
  }

  async getSchoolNameList() {
    let responseToBeReturned = await axios
      .get(this.state.BASE_URL + `/schoolName`, {
        params: {
          body: {
            schoolName: "",
            approved :true,
                        active:true,
                        state:{
                            stateId:0
                        }
          },
          sortKey: "schoolName",
          sortDir: "asc",
          recordsPerPage: 500,
        },
        headers: {
          authorization: "Bearer " + localStorage.getItem("sip-authorization"),
        },
      })
      .then((response) => {
        return response;
      });
    return responseToBeReturned;
  }

  async getClassNumberList() {
    let responseToBeReturned = await axios
      .get(
        this.state.BASE_URL +
        `/organizations/${this.state.orgId}/branches/${this.state.branchId}/classes`,
        {
          params: {
            body: {
              name: "",
              examination: {
                examinationId: this.state.examinationId,
              },
            },
            sortKey: "name",
            sortDir: "asc",
          },
          headers: {
            authorization:
              "Bearer " + localStorage.getItem("sip-authorization"),
          },
        }
      )
      .then((response) => {
        return response;
      });
    return responseToBeReturned;
  }
  changePage = (page, sortOrder, rowsPerPage) => {
    this.setState({
      isLoading: true,
      recordsPerPage: rowsPerPage,
      pageNo: page,
      sortKey:
        sortOrder.name === undefined ? this.state.sortKey : sortOrder.name,
      sortDir:
        sortOrder.direction === undefined
          ? this.state.sortDir
          : sortOrder.direction,
    });
    this.setSelectedRow([]);
    this.getData(
      page,
      sortOrder.name === undefined ? this.state.sortKey : sortOrder.name,
      sortOrder.direction === undefined
        ? this.state.sortDir
        : sortOrder.direction,
      rowsPerPage
    );
  };

  changeRecordPerPage = (page, sortOrder, rowsPerPage) => {
    this.setState({ recordsPerPage: rowsPerPage });
    this.getData(page, "", "", rowsPerPage);
  };

  sort = (page, sortOrder, rowsPerPage) => {
    this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage);
  };

  handleSearch = (searchText, page, sortOrder, rowsPerPage) => {
    this.setState(
      {
        pageNo: page,
        sortKey: sortOrder.name,
        sortDir: sortOrder.direction,
        body: {
          childName: searchText != null ? searchText : "",
          branchName: "",
          branchId: 0,
          schoolName: "",
          classNumber: {
            classId: 0,
          },
          examCompletedStatus: this.state.tabHeading === "practice" || this.state.tabHeading === "State Winners"
          || this.state.tabHeading === "National Winners" ? this.state.tabHeading : this.state.isExamCompleted,
          examinationId: this.state.examinationId,
          groupId: this.state.examGroupId,

        },
      },
      () => this.getData(page, sortOrder.name, sortOrder.direction, rowsPerPage)
    );
  };

  closePromotion(isClose) {
    if (isClose) {
      this.setState({
        openPromotion: false,
        selectedStudentFromTable: [],
        selectedRowsIndex: [],
        openAlert: true,
        alertMessage: `Promoted For ${this.state.nextRound} Successfully`,
        alertSeverity: "success",
      });
      this.componentDidMount();
    } else {
      this.setState({
        openPromotion: false,
        selectedStudentFromTable: [],
        selectedRowsIndex: [],
      });
      this.componentDidMount();
    }
  }

  getSelectedStudentList() {
    let selectedStudentFromTable = [];
    for (
        let index = 0;
        index < this.state.selectedRowsIndex.length;
        index++
    ) {
        selectedStudentFromTable.push(
            this.state.data[this.state.selectedRowsIndex[index]]
        );
    }
    return selectedStudentFromTable;
}

  handleClick() {
    if (this.state.selectedRowsIndex.length > 0) {
      let selectedStudentFromTable = [];

      for (
        let index = 0;
        index < this.state.selectedRowsIndex.length;
        index++
      ) {
        selectedStudentFromTable.push(
          this.state.data[this.state.selectedRowsIndex[index]]
        );
      }

      if (this.state.isExamCompleted === "completed") {
        this.setState({
          openActiveTest: false,
          openPromotion: true,
          selectedStudentFromTable: selectedStudentFromTable,
          openAlert: false,
        });
      } else {
        this.setState({
          openActiveTest: true,
          selectedStudentFromTable: selectedStudentFromTable,
          openAlert: false,
        });
      }
    } else {
      this.setState({
        openAlert: true,
        alertMessage: "Please select at least one student",
        alertSeverity: "error",
      });
    }
  }


  handleMenuItemClick(event, index) {
    this.setSelectedRow([]);
    this.componentDidMount();
    this.setState({
      selectedIndex: index,
      open: false,
      selectedRowsIndex: [],
      openAlert: false,
    });
  }

  handleToggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  handleClose(event) {
    if (
      this.state.anchorRef.current &&
      this.state.anchorRef.current.contains(event.target)
    ) {
      return;
    }

    this.setState({
      open: false,
    });
  }

  closeDialog(isClosed) {
    if (isClosed) {
      this.setState({
        openEdit: false,
        openAlert: true,
        alertMessage: "Updated Successfully",
        alertSeverity: "success",
      });
      this.setSelectedRow([]);
    } else {
      this.setState({ openEdit: false });
    }

    this.componentDidMount();
  }

  closeViewHistory(value) {
    if (value === false) {
      this.setState({ openViewHistory: false });
    }
  }

  closeConfirmationDialog(confirmed) {
    if (confirmed) {
      if (this.state.confirmationType === "delete") {
        const BASE_URL = `${process.env.REACT_APP_API_URL}`;
        axios
          .post(
            BASE_URL +
            "/disableStudents/" +
            this.state.transactionObj.studentId,
            {},
            {
              headers: {
                authorization:
                  "Bearer " + localStorage.getItem("sip-authorization"),
              },
            }
          )
          .then((Response) => {
            this.setState({
              openConfirmationDialog: false,
              openAlert: true,
              alertMessage: "Deleted successfully",
              alertSeverity: "success",
            });
            this.componentDidMount();
          });
      } else if (this.state.confirmationType === "resend") {
        var self = this;
        const BASE_URL = `${process.env.REACT_APP_API_URL}`;
        axios
          .get(
            BASE_URL +
            "/student/" +
            this.state.transactionObj.studentId +
            "/email",
            {
              params: {
                emailType:
                  this.state.isExamCompleted === "completed"
                    ? "result"
                    : "registration",
              },
              headers: {
                authorization:
                  "Bearer " + localStorage.getItem("sip-authorization"),
              },
            }
          )
          .then((response) => {
            this.setState({
              openConfirmationDialog: false,
              openAlert: true,
              alertMessage: "Resent Email Successfully",
              alertSeverity: "success",
            });
            this.componentDidMount();
          })
          .catch(function (error) {
            self.setState({
              openConfirmationDialog: false,
              openAlert: true,
              alertMessage: "Failed to send mail",
              alertSeverity: "error",
            });
            self.componentDidMount();
          });
      } else if (this.state.confirmationType === "reSchedule") {
        var selfObj = this;
        const BASE_URL = `${process.env.REACT_APP_API_URL}`;
        axios
          .get(
            BASE_URL +
            "/student/" +
            this.state.transactionObj.studentId +
            "/exam",
            {
              headers: {
                authorization:
                  "Bearer " + localStorage.getItem("sip-authorization"),
              },
            }
          )
          .then((response) => {
            this.setState({
              openConfirmationDialog: false,
              openAlert: true,
              alertMessage: "Re-scheduled Exam Successfully",
              alertSeverity: "success",
            });
            this.componentDidMount();
          })
          .catch(function (error) {
            selfObj.setState({
              openConfirmationDialog: false,
              openAlert: true,
              alertMessage: "Failed re-schedule exam",
              alertSeverity: "error",
            });
            selfObj.componentDidMount();
          });
      }
    } else {
      this.setState({
        openConfirmationDialog: false,
      });
    }
  }

  closeActiveTest(isClose) {
    if (isClose) {
      this.setState({
        openActiveTest: false,
        selectedStudentFromTable: [],
        selectedRowsIndex: [],
        openAlert: true,
        alertMessage: "Batch scheduled successfully",
        alertSeverity: "success",
      });
    } else {
      this.setState({
        openActiveTest: false,
        selectedStudentFromTable: [],
        selectedRowsIndex: [],
      });
    }
    this.componentDidMount();
  }

  closeEditStudent(isClose) {
    if (isClose) {
      this.setState({
        openEdit: false,
        openAlert: true,
        alertMessage: "Records successfully",
        alertSeverity: "success",
      });
    } else {
      this.setState({
        openEdit: false,
      });
    }
    this.componentDidMount();
  }

  setSelectedRow(rowsSelected) {
    this.setState({
      selectedRowsIndex: rowsSelected,
    });
  }

  handleAlertClose() {
    this.setState({ openAlert: false });
  }

  getCurrentDateAndTimeFromServer() {
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    axios
      .get(BASE_URL + "/SIPCustomApi/serverCurrentDateAndTime", {
        headers: {
          authorization: "Bearer " + localStorage.getItem("sip-authorization"),
        },
      })
      .then((response) => {
        this.setState({
          currentDateAndTimeFromServer: response.data,
        });
      })
      .catch(function (error) { });
  }

  handleDownload = (filename) => {
    const BASE_URL = `${process.env.REACT_APP_API_URL}`;
    this.setState({
      openAlert: true,
      alertMessage: "Requested for CSV file download",
      alertSeverity: "success",
    });
    axios
      .post(
        BASE_URL + "/students/report",
        {
          childName: "",
          branch: {
            branchId: 0,
          },
          schoolName: {
            schoolId :0,
            schoolName:""
          },
          schoolArea: "",
          classNumber: {
            classId: 0,
          },
          registrationCompleted: true,
          examCompletedStatus: this.state.tabHeading === "practice" || this.state.tabHeading === "State Winners"
          || this.state.tabHeading === "National Winners" ? this.state.tabHeading : this.state.isExamCompleted,
          examStatus: "",
          examination: {
            examinationId: this.state.examinationId,
          },
          examGroupId: this.state.examGroupId,
          isPromotionTab: this.state.screenForStudentsSelectedToNextRound ? true : false

        },
        {
          headers: {
            authorization:
              "Bearer " + localStorage.getItem("sip-authorization"),
          },
        }
      )
      .then((response) => {
        if (response.data.sucess) {
         // this.showDownloadPopUp(response.data.fileUrl);
        	  this.setState({
                  openAlert: true,
                  alertMessage : response.data.messageKey,
                  alertSeverity: "success",
                });
        } else {
          this.setState({
            openAlert: true,
            alertMessage: this.state.errorMessageForCsvFileDownload,
            alertSeverity: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({
          openAlert: true,
          alertMessage: this.state.errorMessageForCsvFileDownload,
          alertSeverity: "error",
        });
      });
  };

  showDownloadPopUp = (filePath) => {

    const fileName = filePath.split("/")[filePath.split("/").length - 1].trim();
    fetch(
      `${process.env.REACT_APP_API_URL}/downloadFileFromServer?filePath=${filePath}`,
      {
        method: "GET",
        headers: {
          authorization: "Bearer " + localStorage.getItem("sip-authorization"),
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        this.setState({
          openAlert: true,
          alertMessage: "File downloaded successfully",
          alertSeverity: "success",
        });
        saveAs(blob, fileName);
      })
      .catch((error) => {
        this.setState({
          openAlert: true,
          alertMessage: this.state.errorMessageForCsvFileDownload,
          alertSeverity: "error",
        });
      });
  };

  async loadBranchData() {
    let branchListToBeReturned = axios
      .get(this.state.BASE_URL + "/branches", {
        params: {
          body: JSON.stringify({
            name: "",
            branchId: 0,
            address: {
              countryId: 1,
              stateId: 0,
              cityId: 0,
            },
          }),
        },

        headers: {
          authorization: "Bearer " + localStorage.getItem("sip-authorization"),
        },
      })
      .then((response) => {
        return response;
      });
    return branchListToBeReturned;
  }

  closePasswordRestDialog(isClose) {
    if (isClose) {
      this.setState({
        selectedStudentFromTable: [],
        selectedRowsIndex: [],
        openAlert: true,
        alertMessage: "Password updated successfully",
        alertSeverity: "success",
        openPasswordResetDialog: false,
      });
      this.componentDidMount();
    } else {
      this.setState({
        openPasswordResetDialog: false,
        selectedStudentFromTable: [],
        selectedRowsIndex: [],
      });
      this.componentDidMount();
    }

  }


  render() {
    const { count, rowsPerPage, sortOrder } = this.state;

    const options = {
      filter: true,
      filterType: "dropdown",
      // responsive: "standard",
      vertical: true,
      fixedHeader: true,
      fixedSelectColumn: true,
      tableBodyHeight: (window.screen.height - 130),
      denseTable: true,
      serverSide: true,
      setTableProps: (index) => {
        return {
          padding: '1px',
          horizontal: true,
          scrollEnabled: false,
          textAlign: 'left'
        };
      },
      downloadOptions: {
        filename: "StudentTable.csv",
        //   new API change added here
        customCSVdata: this.state.data,
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        this.handleDownload(); //this makes a REST call to the server and downloads the data
        return false;
      },
      count: count,
      rowsPerPage: this.state.recordsPerPage,
      rowsPerPageOptions: [100, 200, 500],
      sortOrder: sortOrder,
      confirmFilters: true,
      rowHover: true,
      caseSensitive: false,
      enableNestedDataAccess: ".",
      expandableRowsOnClick: true,
      selectableRowsHeader: true,
      selectToolbarPlacement: "none",
      selectableRows: this.state.currentLevel > 0 ? true : false,
      searchOpen: true,
      print: false,
      responsive: 'response',
      //   setRowProps: (row) => {
      //           return {
      //               style:{ visibility: row[11] !== 'completed' && this.state.buttonOptions[this.state.selectedIndex] === 'Schedule Workshop' ? 'hidden' : 'visible'},
      //           }
      //   },
      onFilterChipClose: (column, filterList, type) => {
        var newFilters = () => type;
        this.handleFilterSubmit(newFilters);
      },
      onFilterDialogClose: () => {
        this.componentDidMount();
      },
      isRowSelectable: (dataIndex) => {
        if (this.state.data[dataIndex]) {
          return (((this.state.data[dataIndex].examStatus === 'Promoted to SR' || this.state.data[dataIndex].examStatus === 'Promoted to NR')) ? false : true);
        }
      },

      onRowSelectionChange: (
        currentRowsSelected,
        allRowsSelected,
        rowsSelected
      ) => {
        this.setSelectedRow(rowsSelected);
      },
      onRowClick: (rowData, rowState) => { },
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: "40px" }}>
            <Grid container>
              <Grid item xs={5}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.handleFilterSubmit(applyNewFilters)}
                >
                  Filter
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      },

      //   callback that gets executed when filters are confirmed
      onFilterConfirm: (filterList) => { },

      onFilterDialogOpen: () => { },
      searchPlaceholder: "Search by Child Name...",
      onTableChange: (action, tableState) => {
        switch (action) {
          case "changePage":
            this.changePage(
              tableState.page,
              tableState.sortOrder,
              tableState.rowsPerPage
            );
            break;
          case "sort":
            this.sort(
              tableState.page,
              tableState.sortOrder,
              tableState.rowsPerPage
            );
            break;
          case "changeRowsPerPage":
            this.changeRecordPerPage(
              tableState.page,
              tableState.sortOrder,
              tableState.rowsPerPage
            );
            break;
          case "search":
            this.handleSearch(
              tableState.searchText,
              tableState.page,
              tableState.sortOrder,
              tableState.rowsPerPage
            );
            break;
          default:
        }
      },
    };
    return (
      <div className="container">
        <CssBaseline />
        <Grid container spacing={1} style={{ overflow: "hidden" }}>
          <Grid
            container
            xs={12}
            md={12}
            lg={12}
            sm={12}
            xl={12}
            justify="flex-end"
          >
            {/* <ButtonGroup
              variant="contained"
              color="primary"
              ref={this.state.anchorRef}
              aria-label="split button"
            > */}

            {this.state.displayPromotionApproveButton && this.state.roleName === 'ADMIN' && (
              <Button variant='contained' color='primary' onClick={(() => {
                if (this.state.selectedRowsIndex.length > 0) {
                  this.setState({
                    openPromotion: true,
                    selectedStudentFromTable: this.getSelectedStudentList(),
                    openAlert: false,
                  })
                } else {
                  this.setState({
                    openAlert: true,
                    alertMessage: "Please select at least one student",
                    alertSeverity: "error",
                  });
                }
              })}>
                Approve
              </Button>
            )}


            {(this.state.currentLevel> 0 && this.state.currentLevel !== 0 && this.state.currentLevel !== 3)  && this.state.displayActiveTestButtonGroup &&(
              <Button
                onClick={this.handleClick}
                color="primary"
                variant="contained"
                disabled={
                  ((this.state.buttonOptions === "Promote To State Round" || this.state.buttonOptions === "Promote To National Round") &&
                    (this.state.roleName !== 'ADMIN'))
                  ||
                  (this.state.buttonOptions === 'Activate Test' && this.state.examGroupId === 5)
                  // ||
                  // (this.state.buttonOptions === 'Activate Test' && this.state.examGroupId === 6)
                  ||
                  (this.state.buttonOptions === 'Activate Test' && this.state.roleName !== 'ADMIN')
                  ||
                  (this.state.buttonOptions === "Promote To Winners")
                  // ||
                  // (this.state.isExamCompleted === "completed" && this.state.examGroupId === 6)

                }
              >
                {this.state.buttonOptions}
              </Button>
            )}

            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={this.state.openAlert}
              autoHideDuration={5000}
              onClose={this.handleAlertClose}
              key="top center"
            >
              <Alert
                onClose={this.handleAlertClose}
                severity={this.state.alertSeverity}
              >
                {this.state.alertMessage}
              </Alert>
            </Snackbar>
            {/* <EditStudent
              onClose={this.closeDialog}
              studentObject={this.state.studentObj}
              open={this.state.openEdit}
              studentEditPermissionFlag={this.state.studentEditPermissionFlag}
            /> */}
            <ConfirmationDialog
              onClose={this.closeConfirmationDialog}
              message={this.state.message}
              open={this.state.openConfirmationDialog}
            ></ConfirmationDialog>

            <PracticeExamHistory onClose={this.closeViewHistory}
              studentId={this.state.transactionObj.studentId}
              groupId={this.state.examGroupId}
              studentName={this.state.studentName}
              open={this.state.openViewHistory} >
            </PracticeExamHistory>

            <PasswordRest
              onClose={this.closePasswordRestDialog}
              studentAccountId={this.state.studentAccountId}
              studentName={this.state.studentName}
              transactionObj={this.state.transactionObj}
              open={this.state.openPasswordResetDialog}
            ></PasswordRest>

            <PromotionList
              open={this.state.openPromotion}
              onClose={this.closePromotion}
              selectedStudentFromTable={this.state.selectedStudentFromTable}
              currentDateAndTimeFromServer={
                this.state.currentDateAndTimeFromServer
              }
              nextExamGroupId={this.state.examGroupId + 1}
              examinationId={this.state.examinationId}
            />

            <ActivateTestDialog
              open={this.state.openActiveTest}
              onClose={this.closeActiveTest}
              selectedStudentFromTable={this.state.selectedStudentFromTable}
              currentDateAndTimeFromServer={
                this.state.currentDateAndTimeFromServer
              }
              examGroupId={this.state.examGroupId}
              examinationId={this.state.examinationId}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12} sm={12} xl={12}>
            <MUIDataTable
              showsHorizontalScrollIndicator={false}
              style={{ overflowX: "hidden", width: "auto" }}
              title={
                <Typography variant="h6">
                  Students
                  {this.state.isLoading && (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 15, position: "relative", top: 4 }}
                    />
                  )}
                </Typography>
              }
              data={this.state.data}
              columns={this.state.columns}
              options={options}
            />
          </Grid>
        </Grid>
      </div>
    );

  }
}

export default StudentListProdigy;
