import React from "react";
import {
  makeStyles,
  Paper,
  useTheme,
  Grid,
  useMediaQuery,
  GridList,
  GridListTile,
  AppBar,
  Button,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import first from "./images/glimpseAtAGC/Image1.jpeg";
import second from "./images/glimpseAtAGC/Image2.jpeg";
import third from "./images/glimpseAtAGC/Image3.jpeg" ;
import fourth from "./images/glimpseAtAGC/Image4.jpeg";
import GlimpseAtAGCMobile from "./GlimpseAtAGCMobile";
import agcLogo from "./images/AGCLogo.svg";
import sipAbacusLogo from "./images/SIPAcademyLogo.svg";
import plusLeft from "./images/plusSignLeft.svg";
import plusRight from "./images/plusSignRight.svg";
//import backgroudImage from "./images/BG_Page_04.svg";


const useStyle = makeStyles((theme) => ({
  root: {
    alignContent: "stretch",
    wrap: "nowrap",
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
  },
  paper: {
    width: "100%",
    maxHeight: "auto",
   // backgroundImage: `url(${backgroudImage})`,
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover"
  },
  gridList: {
    width: "100%",
    height: "100%",
    overflowY: "hidden",
  },

  icon: {
    color: "white",
    display: "inline-block",
    marginTop: theme.spacing(32),
    textAlign: "center",
  },
  registrationPageLink: {
    textDecoration: "none",
  },
  button: {
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
    display: "inline-block",
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: "15px",
    height:"85%",
    whiteSpace: "pre-line",
    display:"inline-block",
    fontSize:theme.spacing(3),
    padding:theme.spacing(2)
  },
}));
const GlimpseAtAGC = () => {
  const classes = useStyle();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const register ="REGISTER NOW";
  const title="A GLIMPSE AT AGC 2020"

 

  const images = [
    {
      img: first,
      title: "one",
      author: "author",
      cols: 8,
      featured: true,
    },
    {
      img: second,
      title: "two",
      author: "author",
      cols: 2,
    },
    {
      img: third,
      title: 'three',
      author: 'author',
      cols: 8,
      featured: true,
    },
    {
     img: fourth,
      title: 'four',
      author: 'author',
      cols: 8,
      featured: true,
    }
    
  ];

  let [displayedImages, setDisplayedImages] = React.useState(images.slice(0, 1));

  function getIndexOfElement(searchElement) {
    let positionOfElement = 0;
    images.map((value, index) => {
      if (searchElement === value.title) {
        positionOfElement = index;
      }
    });
    return positionOfElement;
  }

  function handleClick(forwardClick) {  
    let index;
     let arraySize = displayedImages.length - 1;
 
     if (forwardClick) {
       index = getIndexOfElement(displayedImages[arraySize].title);
       if (index +1 >= images.length) {
        setDisplayedImages(images.slice(2,3));
       }else{
         index++;
         setDisplayedImages(images.slice(index,index+1))  
       }
     } else {
       index = getIndexOfElement(displayedImages[0].title);
       if(index-1 <=0){
         setDisplayedImages(images.slice(0,1))
       }else{
         setDisplayedImages(images.slice(index-1,index))
       }
     }
 
   }


  function DisplayImageBasedOnScreenSize() {
    if (smallScreen) {
      return (
      <React.Fragment>
      <GlimpseAtAGCMobile></GlimpseAtAGCMobile>
      </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <Grid container spacing={2}>
        <Paper className={classes.paper}>
          
          
        <Grid item container md={12} xs={12} lg={12} sm={12} >
               <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
                <Grid item md={3} xs={3} lg={3} sm={3}></Grid>
                 <Grid item md={4} xs={9} sm={9} lg={4} >
                  <img src={sipAbacusLogo} alt="logo" height= {smallScreen ?"80%":"50%"}  width={smallScreen ?"80%":"50%"} />
                 </Grid>
                 <Grid item md={5} xs={0} sm={0} lg={5}></Grid>
               </AppBar>
               </Grid>

               <Grid item container>
               <Grid item md={1} xs={1} sm={1} lg={1}></Grid>
             <Grid item md={2} xs={4} sm={4} lg={2} className={classes.container} justifyContent="flex-start">
             <img
                   src={agcLogo}
                   alt="Logo"
                   style={{ height: "60%", width: "60%" }}
                 />
             </Grid>
             <Grid item md={6} xs={1} sm={1} lg={6}></Grid>
             <Grid item md={3} xs={6} sm={6} lg={3}>

            <Grid item container  style={{marginTop: theme.spacing(3)}}>
            
   
              </Grid>
              </Grid>
              </Grid>

              <Grid item container md={12} xs={12} lg={12} sm={12}  style={{marginTop:smallScreen?theme.spacing(0):-theme.spacing(7)}}>
                <Grid item md={4} xs={1} lg={4} sm={1}> </Grid>
                <Grid item md={4} xs={10} lg={4} sm={10} className={classes.title} style={{marginTop :smallScreen ? theme.spacing(2):0}}>{title}</Grid>
                <Grid item md={4} xs={1} lg={4} sm={1}></Grid>  
               </Grid>

          <br />
          <br />

          <Grid item container>

            <Grid item md={2} xs={1} style={{textAlign:"right"}}>
           
           <ArrowBackIosIcon   className={classes.icon}  fontSize="large"  onClick={()=>handleClick(0)} />
           </Grid>
           <Grid item md={8} xs={10} style={{marginTop:"20px"}}>
             <Grid item container>
               <Grid item md={1} xs={false}>

               </Grid>
               <Grid item md={10} xs={12}>
               
               <GridList cellHeight={250}  cols={8} className={classes.gridList} spacing={1}>
              {displayedImages.map((tile) => (
                <GridListTile key={tile.img} cols={8} style={{ height:"470px"}}>
                  <img src={tile.img} alt={tile.title}  style={{height:"100%" , width:"100%"}}/>
                </GridListTile>
              ))}
            </GridList> 
               </Grid>
               <Grid item md={1} xs={false}>

               </Grid>

             </Grid>
             {/* </Grid> */}
             
           </Grid>
           <Grid item md={2} xs={1} style={{textAlign:"left"}}>
           <ArrowForwardIosIcon   className={classes.icon}  fontSize="large"  onClick={()=>handleClick(1)} />

           </Grid>
          </Grid>

          <br />
          <br />
        </Paper>
      </Grid>
        </React.Fragment>
      );
    }
  }


  return (
    <div className={classes.root}>
      <DisplayImageBasedOnScreenSize></DisplayImageBasedOnScreenSize> 
    </div>
  );
};

export default GlimpseAtAGC;
