import React from "react";
import {
  useTheme,
  makeStyles,
  Grid,
  Paper,
  useMediaQuery,
  Button,
  List,
  ListItem,
  Typography,
  ListItemIcon,
  AppBar,
  CssBaseline,
} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
//import backgroundImage from "./images/BG_Page_04.svg";
import agcLogo from "./images/AGCLogo.svg";
import sipAbacusLogo from "./images/SIPAcademyLogo.svg";
import plusLeft from "./images/plusSignLeft.svg";
import plusRight from "./images/plusSignRight.svg";

//import agcDoll from "./images/DOLL_P5.svg";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import mobileBG from "./images/mobileBG.svg";
import backgroundImage2022 from "./images/BG_Page_2022.png";
const useStyle = makeStyles((theme) => ({
  purposeRoot: {
    alignContent: "stretch",
    width: "100%",
    height:"100%",
    // wrap: "nowrap",
    flexWrap:"wrap"

  },
  purposePaper: {
    width: "180%",
   // backgroundImage: `url(${backgroundImage2022})`,
    maxHeight: "auto",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: "bold",
    color: "white",
    whiteSpace: "pre-line",
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  content: {
    fontSize: theme.spacing(2),
    //color: "white",
    display: "inline-block",
    whiteSpace: "pre-line",
    textAlign: "left",
  },
  button: {
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
    display: "inline-block",
    textAlign: "center",
  },
  mobilePurposePaper: {
    width: "100%",
    height: "auto",
    // backgroundColor:"#02A550"
    backgroundImage: `url(${mobileBG})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  registrationPageLink: {
    textDecoration: "none",
  },
  highlightedText: {
    fontSize: "26px",
    fontWeight: "bold",
    color: "white",
  },
  purposeTitle: {
    textAlign: "center",
    color: "white",
    backgroundColor: "#E62127",
    fontWeight: "bold",
    borderRadius: "25px",
    height: "75%",
    fontSize: "20px",
    padding: theme.spacing(3),
  },
  icon: {
    color: "yellow",
    fontSize: "75%",
  },
  text: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#43413e",
  },
}));

const PurposeOfAGC = () => {
  const classes = useStyle();
  const theme = useTheme(useTheme);
  const title = "WHY SHOULD MY CHILD PARTICIPATE IN AGC?";
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const register = "REGISTER NOW";

  function Content() {
    if (smallScreen) {
      return (
        <React.Fragment>
          <Grid container justifyContent="center" alignItems="center">
            <List style={{ textAlign: "left" }}>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.content}>
                  <Typography className={classes.text}>
                    A chance for your child to participate in the biggest All
                    India Online Arithmetic contest.​​
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.content}>
                  <Typography className={classes.text}>
                    Cash rewards over 15 Lakhs to be won!​
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.content}>
                  <Typography className={classes.text}>
                    Participation certificates for all; Winner medals, trophies
                    and cash awards too to be won.
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  
                  <FiberManualRecordIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.content}>
                  <Typography className={classes.text}>
                    Get assessment scores of your child in each round.
                  </Typography>
                </ListItemText>
              </ListItem>
             
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.content}>
                  <Typography className={classes.text}>
                    If your child can do a simple arithmetic sums just like 46 +
                    5 or 26 - 3 or 81 +21 , then they can very well participate
                    and bring laurels!
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.content}>
                  <Typography className={classes.text}>
                   Weekly Prizes for 12 Weekly Champions! In case of tie in marks, Champions will be selected by a lucky draw
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.content}>
                  <Typography className={classes.text}>
                    Access to an exclusive practice portal.
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText className={classes.content}>
                  <Typography className={classes.text}>
                    
                    Free ‘Math is Fun’ Workshop for all participants.
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid container sm={12} xs={12}>
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Grid item container style={{marginBottom:theme.spacing(8)}}>
            <Grid item md={3} lg={3} xl={3}>
              {/* <img src={agcDoll} alt="doll"  style={{ marginTop:theme.spacing(27)}}/> */}
            </Grid>
            <Grid item md={6} lg={6} xl={6}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  item
                  
                  container
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                >
                  <List style={{ textAlign: "left" }}>
                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText className={classes.content}>
                        <Typography className={classes.text}>
                          A chance for your child to participate in the biggest
                          All India Online Arithmetic contest.​​
                        </Typography>
                      </ListItemText>
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText className={classes.content}>
                        <Typography className={classes.text}>
                          Cash rewards over 15 Lakhs to be won!​
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        
                        <FiberManualRecordIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText className={classes.content}>
                        <Typography className={classes.text}>
                          Participation certificates for all; Winner medals,
                          trophies and cash awards too to be won.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        
                        <FiberManualRecordIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText className={classes.content}>
                        <Typography className={classes.text}>
                          Get assessment scores of your child in each round.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                   

                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText className={classes.content}>
                        <Typography className={classes.text}>
                          If your child can do a simple arithmetic sums just
                          like 46 + 5 or 26 - 3 or 81 +21 , then they can very
                          well participate and bring laurels!
                        </Typography>
                      </ListItemText>
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText className={classes.content}>
                        <Typography className={classes.text}>
                         Weekly Prizes for 12 Weekly Champions! In case of tie in marks, Champions will be selected by a lucky draw
                        </Typography>
                      </ListItemText>
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText className={classes.content}>
                        <Typography className={classes.text}>
                          Access to an exclusive practice portal.
                        </Typography>
                      </ListItemText>
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <FiberManualRecordIcon className={classes.icon} />
                      </ListItemIcon>
                      <ListItemText className={classes.content}>
                        <Typography className={classes.text}>
                          
                          Free ‘Math is Fun’ Workshop for all participants.
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3} lg={3}></Grid>
          </Grid>

        </React.Fragment>
      );
    }
  }

  return (
    // <div className={classes.purposeRoot}>
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Paper
          className={
            smallScreen ? classes.mobilePurposePaper : classes.purposePaper
          }
        >
          <Grid item container md={12} xs={12} lg={12} sm={12} xl={12}>
            <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
              <Grid item md={3} xs={3} lg={3} xl={3} sm={3}></Grid>
              <Grid item md={4} xs={9} sm={9} lg={4} xl={4}>
                <img
                  src={sipAbacusLogo}
                  alt="logo"
                  height={smallScreen ? "60%" : "50%"}
                  width={smallScreen ? "60%" : "50%"}
                />
              </Grid>
              <Grid item md={5} xs={0} sm={0} lg={5} xl={5}></Grid>
            </AppBar>
          </Grid>

          <Grid item container>
            <Grid item md={1} xs={1} sm={1} lg={1} xl={1}></Grid>
            <Grid
              item
              md={2}
              xs={4}
              sm={4}
              lg={2}
              xl={2}
              className={classes.container}
              justifyContent="flex-start"
            >
              <img
                src={agcLogo}
                alt="Logo"
                style={{ height: smallScreen ? "80%" : "60%", width: "60%" }}
              />
            </Grid>
            <Grid item md={6} xs={1} sm={1} lg={6} xl={6}></Grid>
            <Grid item md={3} xs={6} sm={6} lg={3} xl={3}>
              <Grid item container style={{ marginTop: theme.spacing(3) }}>
           
              <Grid item container style={{ marginTop: theme.spacing(3) }}>
                <Grid item md={3} xs={2} sm={2} lg={3} xl={3} alignContent="flex-end">
                  <img
                    src={plusLeft}
                    alt="plus"
                    height="50%"
                    width="50%"
                    style={{
                      marginLeft: smallScreen
                        ? theme.spacing(1)
                        : theme.spacing(5),
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={8} sm={8} lg={6} xl={6}>
                  <a
                    href="#registration"
                    className={classes.registrationPageLink}
                  >
                    <Button
                      style={{
                        width: "100%",
                        fontSize: smallScreen ? "10px" : "14px",
                      }}
                      variant="contained"
                      className={classes.button}
                      disabled
                    >
                      {register}
                    </Button>
                  </a>
                </Grid>
                <Grid item md={3} sm={2} lg={3} xs={2} xl={3}>
                  <img
                    src={plusRight}
                    alt="plus"
                    height="50%"
                    width="50%"
                    style={{}}
                  />
                </Grid>
              </Grid>
              
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            md={12}
            xs={12}
            lg={12}
            sm={12}
            xl={12}
            style={{
              marginTop: smallScreen ? theme.spacing(0) : -theme.spacing(7),
            }}
          >
            <Grid item md={4} xs={1} lg={4} sm={1} xl={4}>
            </Grid>
            <Grid
              item
              md={4}
              xs={10}
              lg={4}
              sm={10}
              className={classes.purposeTitle}
              style={{ marginTop: smallScreen ? theme.spacing(3) : 0 }}
            >
              {title}
            </Grid>
            <Grid item md={4} xs={1} lg={4} sm={1} xl={4}></Grid>
          </Grid>

          <br />
          <Grid container justifyContent="center" alignContent="center">
          <Content></Content>
          </Grid>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default PurposeOfAGC;
