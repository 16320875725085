import React, { useState, useContext } from "react";
import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import OfflinePinOutlinedIcon from "@material-ui/icons/OfflinePinOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { green } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ThankYouAttempt1 from "../../Students/Dashboard/ThankYouAttempt1.png";
import ThankYouAttempt2 from "../../Students/Dashboard/ThankYouAttempt2.png";
import { LoggedInUserDetails } from "../../Students/LoggedInUserDetails/LoggedInUserDetails";
import ThankYouAttempt1Mobile from "../../Students/Dashboard/ThankYouAttemptMobile1.png";
import ThankYouAttempt2Mobile from "../../Students/Dashboard/ThankYouAttemptMobile2.png";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 100,
    height: 40,
    backgroundColor: green,
  },
  image: {
    height: "95vh",
    backgroundImage: `url(${ThankYouAttempt1})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  },
  image1: {
    height: "95vh",
    backgroundImage: `url(${ThankYouAttempt2})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  },mbimage: {
    height: "95vh",
    backgroundImage: `url(${ThankYouAttempt1Mobile})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  },
  mbimage1: {
    height: "95vh",
    backgroundImage: `url(${ThankYouAttempt2Mobile})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  },
  text: {
    marginLeft: "30%",
    alignItems: "center",
  }, mbtext: {
    marginLeft: "7%",
    alignItems: "center",
  },
}));

export default function ThankYouPage(props) {
  const classes = useStyles();
  const history = useHistory();
  // const {message} = props;
  const [timeLeft, setTimeLeft] = useState(4);
  const { loggedInUserInfo } = useContext(LoggedInUserDetails);
  let theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let attemptCount=props.location.state.attemptCount;
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  function calculateTimeLeft() {
    if (timeLeft === 0) {
      history.push("/student/portal/dashboard", {
        examination: props.location.state.examination,
      });
    }
    return timeLeft - 1;
  }
console.log(loggedInUserInfo.noOfAttempts);
  return (
    <React.Fragment>
      {smallScreen ? (
        <>
          <Grid
            container
            direction="column"
            alignItems="center"
            className={
              (attemptCount == 0 )   ? classes.mbimage : classes.mbimage1
            }
          ></Grid>
          <div className={classes.mbtext}>
            Redirecting to Dashboard in {timeLeft}sec. , If Redirection does not
            happen{" "}
            <Link
              component="button"
              variant="body2"
              color="secondary"
              onClick={() => {
                history.push("/student/portal/dashboard",{ examination: props.location.state.examination});
              }}
            >
              Click Here
            </Link>
          </div>
        </>
      ) : (
        <>
          <Grid
            container
            direction="column"
            alignItems="center"
            className={
              (attemptCount == 0 ) ? classes.image : classes.image1
            }
          ></Grid>
          <div className={classes.text}>
            Redirecting to Dashboard in {timeLeft}sec. , If Redirection does not
            happen{" "}
            <Link
              component="button"
              variant="body2"
              color="secondary"
              onClick={() => {
                history.push("/student/portal/dashboard",{ examination: props.location.state.examination});
              }}
            >
              Click Here
            </Link>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
