import React, { useEffect ,useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  makeStyles,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  withStyles,
  Grid,
  TextField,
  useTheme
} from "@material-ui/core";
import { LoggedInUserDetails } from "../LoggedInUserDetails/LoggedInUserDetails";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: "4%",
    minHeight: 275,
    maxHeight: "100vh",
  },
  paperContainer: {
    padding: theme.spacing(3),
  },
  cardHeader: {
    backgroundColor: "tomato",
    textAlign: "center",
  },
  buttonOption: {
    width: "100%",
    padding: theme.spacing(0.5),
    border: 0,
  },
  title: {
    height: "10px",
    fontSize: 22,
    backgroundColor: "#f38020",
    borderCollapse: "collapse",
    width: "100%",
    borderSpacing: 10,
    borderTop: 0,
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
  },
  questionNoText: {
    fontSize: "16px",
    height: "7px",
    fontWeight:"bold"
  },
  question: {
    fontWeight: "bold",
    color: "#000099",
    fontSize: "18px",
    padding: "5px",
  },
  cardContainer: {
    borderRadius: "30px",
    width:"300px",
    marginTop:"5px",
    marginLeft:"-70px",
    alignItems:"center"
  },mobileCardContainer:{
    borderRadius: "30px",
   
  },
  questionContainer: {
    fontSize: "18px",
    fontWeight: "500",
    alignItems: "space-between",
    textAlign: "right",
  },
  actionButton: {
    height: "50px",
    padding: theme.spacing(5),
  },
  fontColor: {
    color: "#000099",
  }, input: {
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
}));

Question2.propTypes = {};

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    width: "100%",
  },
  grouped: {
    margin: theme.spacing(0.3),
    height: "25%",
    border: "2px solid #40ff00",
    "&:not(:first-child)": {
      borderRadius: theme.spacing(2),
      borderColor: "#40ff00",
    },
    "&:first-child": {
      borderRadius: theme.spacing(2),
      borderColor: "#40ff00",
    },
  },
  groupedVertical: {
    "&:not(:first-child)": {
      borderTop: "2px solid #40ff00",
    },
  },
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles(() => ({
  root: {
    "&:hover": {
      backgroundColor: "#93DFB8",
    },
    height: "25px",
  },
  selected: {
    backgroundColor: "#00cc66 !important",
  },
}))(ToggleButton);

function Question2(props) {
  let constructTitle = "";
  const classes = useStyles();
  const {
    question,
    buttonSize,
    index,
    page,
    recordsPerPage,
    answers,
    setAnswers,
    examObject,
    setAnsweredQuestion,
    totalNoOfQueInPreSec,
    examinationType,
    setPage,
    totalNumberOfPages,
    moduleId,
    moduleName
  } = props;
  const [optionId, setOption] = React.useState("");
  const [title, setTitle] = React.useState("");
  const textRef = useRef();
  let count=0;
  const{loggedInUserInfo}=useContext(LoggedInUserDetails);
  const [studentId] = React.useState(loggedInUserInfo.studentId);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const handleChange = (event, nextOption) => {
    var existingAns = answers;

   // if (!isLetters(event.target.value)) {
    if (nextOption !== null) {
      if (existingAns.length === 0) {
        existingAns = [];
        let answerObject = initializeAnswerObject(event.target.value);
        existingAns.push(answerObject);
        setAnswers(existingAns);
        setOption(event.target.value);
      } else {
        var isAnswered = false;
        for (var i = 0; i < existingAns.length; i++) {
          if (existingAns[i].questionId === question.questionBankId) {
            existingAns[i].enteredOptionValue = event.target.value;
            isAnswered = true;
            break;
          }
        }
        if (!isAnswered) {
          let answerObject = initializeAnswerObject(event.target.value);
          existingAns.push(answerObject);
        }
        setAnswers(existingAns);
      }
    } else {
      for (let j = 0; j < existingAns.length; j++) {
        if (existingAns[j].questionId === question.questionBankId) {
          existingAns.splice(j, 1);
          break;
        }
      }
      setAnswers(existingAns);
    }
    for(let k=0;k<existingAns.length;k++){
      if(existingAns[k].enteredOptionValue != ""){
        count=count+1;
      }

    }
    setAnsweredQuestion(count);
    setOption(event.target.value);
    // if ((page < totalNumberOfPages - 1) && examinationType === 'prodigy') {

    //     setPage(prevPage => prevPage + 1)
    // }
 // }
  };

  useEffect(() => {
    var existingAns = answers;

    if (existingAns.length === 0) {
      setOption("");
    } else {
      for (var i = 0; i < existingAns.length; i++) {
        if (existingAns[i].questionId === question.questionBankId) {
          setOption(existingAns[i].enteredOptionValue);
          break;
        } else {
          setOption("");
        }
      }
    }

    constructTitle =
      "Q No - " +
      (recordsPerPage * (page + 1) +
        (index + 1) -
        recordsPerPage +
        totalNoOfQueInPreSec);
    setTitle(constructTitle);
  }, [question, title]);

  React.useEffect(() => {
   
      setTimeout(() => {
        textRef.current?.focus();
      }, 300);
    
  }, [question, title]);

  function initializeAnswerObject(selectedOption) {
    let answerObjectToBePushed = {
      createdBy: examObject.studentName,
      lastUpdatedBy: examObject.studentName,
      active: true,
      //'question': {
      questionId: question.questionBankId,
      // },
      acutalOptionValue: question.optionsList[0].option,
      // 'options': {
      enteredOptionValue: selectedOption,
      // },
      // 'student': {
      studentId: studentId,
      //},
      //'exam': {
      examId: examObject.exam.examId,
      //},
      // 'batch': {
      batchId: examObject.batchId,
      marks: question.marks,
      sectionId:moduleId,
      sectionName:moduleName,
      examParticipationId:examObject.examParticipationId
      //}
    };
    return answerObjectToBePushed;
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Card elevation={8} className={smallScreen ? classes.mobileCardContainer: classes.cardContainer}>
          <CardHeader
            className={classes.title}
            title={<span className={classes.questionNoText}> {title} </span>}
          />
          <CardContent className={classes.question}>
            <Grid container justify="center">
              <Typography className={classes.questionContainer}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: question.question,
                  }}
                />
              </Typography>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent style={{ padding: "3px", textAlign: "center" }}>
            <Typography
              className={classes.fontColor}
              variant="caption"
              color="#000099"
            >
              Enter the correct answer.
            </Typography>
          </CardContent>
          <Divider />
          <CardActions className={classes.actionButton}>
            <TextField
              id="optionId"
              type="number"
              inputProps={{
                inputMode: "decimal",
                pattern: '[0-9]*',
                style: { textAlign: "center", fontSize: "22px", cursor: 'pointer' },
              }}
              value={optionId}
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus={true}
              onChange={handleChange}
              inputRef={textRef}
              className={classes.input}
            />
          </CardActions>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default Question2;
