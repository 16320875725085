import React from "react";

import {
  makeStyles,
  useTheme,
  Grid,
  Paper,
  useMediaQuery,
  Button,
  ListItemText,
  List,
  ListItem,
  Typography,
  AppBar,
  CssBaseline,
} from "@material-ui/core";
import agcLogo from "./images/AGCLogo.svg";
import sipAbacusLogo from "./images/SIPAcademyLogo.svg";
import plusLeft from "./images/plusSignLeft.svg";
import plusRight from "./images/plusSignRight.svg";
//import agcDoll from"./images/DOLL_P3.svg";
//import backgroudImage from "./images/BG_Page_03.svg";
import mobileBG from "./images/mobileBG.svg"
//import backgroudImage2022 from "./images/BG_Page_2022.png";
import mobileBG2022 from "./images/Arithmetic_Genius_Contest_Mobile_BG.jpg"
import backgroudImage2022 from  "./images/BG_2022_AGC.jpg";


const useStyles = makeStyles((theme) => ({
  aboutAGCRoot: {
    backgroundColor: "white",
    alignContent: "stretch",
    flexWrap:"nowrap"

  },
  prizePaper: {
    width: "160%",
    maxHeight:"auto",
    backgroundImage: `url(${backgroudImage2022})`,
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover",
  },
 
  prizeMobilePaper: {
    width: "100%",
    height: "'100vh'",
    backgroundImage: `url(${mobileBG2022})`,
    backgroundColor:"white",
    backgroundRepeat:"no-repeat",
    backgroundSize: "cover"
  },
  registrationPageLink: {
    textDecoration: "none",
  },

button: {
  color: "white",
  backgroundColor: "#E62127",
  fontWeight: "bold",
  borderRadius: theme.spacing(1),
  display: "inline-block",
  textAlign: "center",
},
prizeTitle: {
  textAlign: "center",
  color: "white",
  backgroundColor: "#E62127",
  fontWeight: "bold",
  borderRadius: "10px",
  height:"85%",
  whiteSpace: "pre-line",
  display:"inline-block",
  fontSize:theme.spacing(2),
  padding: theme.spacing(3)
},
text:{
    fontSize:"16px",
    fontWeight:"bold",
    color:"#000000"
},
prizeContent: {
    textAlign: "left",
    fontSize: theme.spacing(3),
    // color: "#404040",
    whiteSpace: "pre-line",
},
prizeBox: {
    border :"4px solid #E62127",
    borderRadius: "4px",
    padding: "3px 5px",
    textAlign: "center",
    shadowColor: "#F5E740",
    shadowOffset: {
        width: 0,
        height: 12,
    },
    shadowOpacity: 0.58,
    shadowRadius: 16.00,
    whiteSpace: "pre-line",
    fontSize:"16px",
    fontWeight:"bold",
    color:"#000000"
    
},
}));

const AGCWorkFlow = () => {
    const theme = useTheme();
    const classes = useStyles();
    const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const title = "Summary of Prizes";
    const round1 = " Round 1";
    const round2 = " Round 2";
    const round3 = " Round 3 - State Finals ";
    const round4="Round 4 - National Final";
    const register ="SAMPLE QUESTION PAPER";


    function ContentToBeDisplayed() {
        if (smallScreen) {
            return (
                <React.Fragment>
                <Grid container style={{ marginTop: "30px", padding: "0 10%" }}>
                        <Grid item xs={12}>
                            <div className={classes.prizeBox}>
                                {round1}
                            </div>
                            <List className={classes.prizeContent}>
                                <ListItem>
                                    
                                    <ListItemText className={classes.prizeContent}>
                                      <Typography className={classes.text}>  Certificates for Winners.</Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                  
                                    <ListItemText className={classes.prizeContent}>
                                       <Typography className={classes.text}> Participation certificates for all.</Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: "30px", padding: "0 10%" }}>
                        <Grid item xs={12}>
                            <div className={classes.prizeBox}>
                                {round2}
                            </div>
                            <List className={classes.prizeContent}>
                                <ListItem>
                                    
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>Medals for Winners.​ </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                   
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}> Certificates for Winners.​ </Typography> 
                                </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: "30px", padding: "0 10%" }}>
                    <Grid item xs={12}>
                            <div className={classes.prizeBox}>
                                {round3}
                            </div>
                            <List className={classes.prizeContent}>
                                <ListItem>
                                    
                                    <ListItemText className={classes.prizeContent}>
                                        <Typography className={classes.text}> State Winners gets Cash prize and Trophies.</Typography> 
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                               
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>  1st prize Rs. 10,000 cash.</Typography> 
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>   2nd prize Rs. 7,000 cash. </Typography> 
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>   3rd prize Rs. 5,000 cash. </Typography> 
                                    </ListItemText>
                                </ListItem>
                                
                              
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: "30px", padding: "0 10%" }}>
                    <Grid item xs={12}>
                            <div className={classes.prizeBox}>
                                {round4}
                            </div>
                            <List className={classes.prizeContent}>
                                <ListItem>
                                    
                                    <ListItemText className={classes.prizeContent}>
                                        <Typography className={classes.text}> Champion student of each class wins the title <span style={{color:"white"}}>"Arithmetic Genius - 2022".</span></Typography> 
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                               
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>  1st prize Rs. 25,000 cash.</Typography> 
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>   2nd prize Rs. 15,000 cash. </Typography> 
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>   3rd prize Rs. 10,000 cash. </Typography> 
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>   Certificates for Winners. </Typography> 
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                    
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}> Trophies for Winners. </Typography> 
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container sm={12} xs={12} justifyContent="flex-end" alignItems="flex-end">
              
                    </Grid>
                </React.Fragment>
            )
        } else {
            return (

                <React.Fragment>

                    <Grid container direction="row" >
                        <Grid item md={3} lg={3} xl={3}>
                        {/* <img src={agcDoll} alt="doll"  style={{ marginTop:theme.spacing(22)}}/> */}
                        </Grid>
                        
                        <Grid item md={6} lg={6} xl={6}>
                        <Grid item container spacing={4}>
                        <Grid item md={4} lg={4} xs={10} xl={4} className={classes.prizeContent} style={{ textAlign: "left" }}>
                            <div className={classes.prizeBox}>{round1}</div>
                            <List className={classes.prizeContent}>
                                <ListItem>
                                   
                                    <ListItemText className={classes.prizeContent}>
                                        <Typography className={classes.text}>
                                        	Certificates for Winners
                                        </Typography> 
                                        </ListItemText>
                                </ListItem>
                                <ListItem>
                                    
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>
                                        Participation certificates for all.
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                            <div className={classes.prizeBox}>{round2}</div>
                            <List className={classes.prizeContent}>
                                <ListItem>
                                   
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>

                                 	Medals for Winners
                                </Typography></ListItemText>
                                        
                                </ListItem>
                                <ListItem>
                                   
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>

                                    Certificates for Winners.                             
                                    </Typography>
                                    </ListItemText>
                                </ListItem>
                              
                               
                                
                            </List>
                            <br></br>
                        </Grid>

                        <Grid item md={4} lg={4} xs={10} xl={4} className={classes.prizeContent} style={{ textAlign: "left" }}>
                            <div className={classes.prizeBox}>{round3}</div>
                            <List className={classes.prizeContent}>
                                <ListItem>
                                  
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>
                                    State Winners gets Cash prize and Trophies.
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                   
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>

                                        1st prize Rs. 10,000 cash. 
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                 
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>

                                        2nd prize Rs. 7,000 cash. 
                                        </Typography></ListItemText>
                                        
                                </ListItem>
                                <ListItem>
                               
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>

                                        3rd prize Rs. 5,000 cash. 
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                
                            </List>
                        </Grid>


                        <Grid item md={4} lg={4} xs={10} xl={4} className={classes.prizeContent} style={{ textAlign: "left" }}>
                            <div className={classes.prizeBox}>{round4}</div>
                            <List className={classes.prizeContent}>
                                <ListItem>
                                  
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>
                                    Champion student of each class wins the title <span style={{color:"white"}}>"Arithmetic Genius - 2022"</span>
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                   
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>

                                        1st prize Rs. 25,000 cash. 
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                                 
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>

                                        2nd prize Rs. 15,000 cash. 
                                        </Typography></ListItemText>
                                        
                                </ListItem>
                                <ListItem>
                               
                                    <ListItemText className={classes.prizeContent}>
                                    <Typography className={classes.text}>

                                        3rd prize Rs. 10,000 cash. 
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <ListItem>
                               
                               <ListItemText className={classes.prizeContent}>
                               <Typography className={classes.text}>

                               Certificates for Winners. 
                                   </Typography>
                               </ListItemText>
                           </ListItem>
                           <ListItem>
                               
                               <ListItemText className={classes.prizeContent}>
                               <Typography className={classes.text}>

                               Trophies for Winners
                           
                                   </Typography>
                               </ListItemText>
                           </ListItem>
                            </List>
                        </Grid>
                      
                    </Grid>
                
                        </Grid>

                        <Grid item md={3} lg={3} xl={3}></Grid>
                       
                    </Grid>
                 </React.Fragment>
            )
        }
    }

  
 

  return (
    <React.Fragment>
      <CssBaseline />
        <Grid container justifyContent="center" alignItems="center">
          <Paper
            className={
              smallScreen ? classes.prizeMobilePaper : classes.prizePaper
            }
          >
            <Grid item container md={12} xs={12} lg={12} sm={12} xl={12}  >
              <AppBar position="static" style={{ backgroundColor: "#E62127" }}>
                <Grid item md={3} xs={3} lg={3} sm={3} xl={3}></Grid>
                <Grid item md={4} xs={9} sm={9} lg={4} xl={4}>
                  <img
                    src={sipAbacusLogo}
                    alt="logo"
                    height={smallScreen ? "60%" : "50%"}
                    width={smallScreen ? "60%" : "50%"}
                  />
                </Grid>
                <Grid item md={5} xs={0} sm={0} lg={5} xl={5}></Grid>
              </AppBar>
            </Grid>

            <Grid item container>
              <Grid item md={1} xs={1} sm={1} lg={1} xl={1}></Grid>
              <Grid
                item
                md={2}
                xs={4}
                sm={4}
                lg={2}
                className={classes.container}
                justifyContent="flex-start"
              >
                <img
                  src={agcLogo}
                  alt="Logo"
                  style={{ height:smallScreen?"80%":"60%", width:"60%" }}
                />
              </Grid>
              <Grid item md={6} xs={1} sm={1} lg={6} xl={6}></Grid>
              <Grid item md={3} xs={6} sm={6} lg={3} xl={3}>
                <Grid item container style={{ marginTop: theme.spacing(3) }}>
                 
                <Grid
                    item
                    md={3}
                    xs={2}
                    sm={2}
                    lg={3}
                    alignContent="flex-end"
                  >
                    <img
                      src={plusLeft}
                      alt="plus"
                      height="50%"
                      width="50%"
                      style={{
                        marginLeft: smallScreen
                          ? theme.spacing(1)
                          : theme.spacing(5),
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={8} sm={8} lg={6} xl={6}>
                    <a
                      href="#sampleQuestionPaper"
                      className={classes.registrationPageLink}
                    >
                      <Button
                        style={{
                          width: "100%",
                          fontSize: smallScreen ? "10px" : "14px",
                        }}
                        variant="contained"
                        className={classes.button}
                      >
                        {register}
                      </Button>
                    </a>
                  </Grid>
                  <Grid item md={3} sm={2} lg={3} xs={2} xl={3}>
                    <img
                      src={plusRight}
                      alt="plus"
                      height="50%"
                      width="50%"
                      style={{}}
                    />
                  </Grid>
            
                </Grid>
              </Grid>
            </Grid>

            <Grid item container md={12} xs={12} lg={12} sm={12} xl={12} style={{marginTop:smallScreen?theme.spacing(0):-theme.spacing(7)}}>
              <Grid item md={3} xs={1} lg={3} sm={1} xl={3}>
               
              </Grid>
              <Grid
                item
                md={6}
                xs={10}
                lg={6}
                sm={10}
                className={classes.prizeTitle}
                style={{ marginTop: smallScreen ? theme.spacing(3) : 0 }}
              >
                {title}
              </Grid>
              <Grid item md={3} xs={1} lg={3} sm={1} xl={3}></Grid>
            </Grid>

            <br />
            <br />
    
            <ContentToBeDisplayed></ContentToBeDisplayed>
           
            <Grid container justifyContent="flex-end" alignItems="center" direction="column-reverse">
              <Typography className={classes.text}><div>*State Round Cash Awards are different for Andhra Pradesh, Odisha and Tripura. A few other states are combined as a Region.</div><br></br></Typography>
              
            </Grid>
          </Paper>
        </Grid>
    </React.Fragment>
  );
};

export default AGCWorkFlow;
