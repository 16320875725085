import React, { useEffect, useState } from 'react';
import { Bar , Chart} from 'react-chartjs-2';



function GoalBarChart(props) {
  const {isDashboardLoading ,  dashBoardList } = props;
  const barChartData = {
    labels: ['Goal', 'Actual Admissions'],
    datasets: [
      {
        label: ['Goal Chart'],
        backgroundColor: ['#11AAF3', '#00FF00'],
        order:'number',
        borderColor: 'black',
        borderWidth: 1,
        hoverBackgroundColor: ['#11AAF3', '#00FF00'],
        hoverBorderColor: 'black',
        data: [isDashboardLoading?dashBoardList.goal:0, isDashboardLoading?dashBoardList.actualAdmission:0]
      }
    ]
  };

  const barChartOptions = {
    maintainAspectRatio: false,
    legend: {
      labels: {
        boxWidth: 0,
        fontColor: 'black',
        fontStyle: "bold",
        display:false
      }
    },
    plugins: {
      datalabels: {
         display: false,
         color: 'black',
         fontStyle: "bold",
         fontSize: 14,
      }
   },
    "hover": {
      "animationDuration": 0
    },
    "animation": {
      "duration": 1,
      "onComplete": function() {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        // ctx.font = `${1}em sans-serif`;
        // ctx.font = 'black';
        // ctx.fontWeight = 'bold';
        //ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
        ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';
      
        this.data.datasets.forEach(function(dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function(bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      }
    },scales: {
      yAxes: [{
          ticks: {
              beginAtZero: true,
              fontColor: 'black',
              fontStyle: "bold",
              fontSize: 14,
              userCallback: function(label, index, labels) {
                  // when the floored value is the same as the value we have a whole number
                  if (Math.floor(label) === label) {
                      return label;
                  }

              },
          }
      }],
      xAxes: [{
        ticks: {
            fontColor: 'black',
            fontStyle: "bold",
            fontSize: 14,
        },
    }],
    
  },
  }

  return (
    <div style={{ borderRight:'6px',height: '450px',color:'black'}}>
      
      <h2 style={{ textAlign:'center' }}> Goal Chart</h2>
      <Bar
        data={barChartData}
        height={450}
        options={barChartOptions}
      />
    </div>
  )

}

export default GoalBarChart;